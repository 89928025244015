import { TypographyH1 } from "@/components/custom/text/TypographyH1";
import { TypographyH3 } from "@/components/custom/text/TypographyH3";
import { TypographyP } from "@/components/custom/text/TypographyP";
import { Card, CardContent } from "@/components/ui/card";
import Flex from "@/components/ui/flex";
import { cn } from "@/lib/utils";
import { House03Icon, Tick02Icon } from "@hugeicons/react";
import { parse } from "date-fns";
import { useGetOrderTransportStatus } from "../hooks";
import { IOrder } from "../types";

interface ITransportStatus {
    order: IOrder;
}

export const TransportStatus = ({ order }: ITransportStatus) => {
    const { isLoading, refetch } = useGetOrderTransportStatus(order.id);

    const parseDate = (dateString: string): Date => {
        return parse(dateString.replace(/\.\d{3}$/, ""), "dd-MM-yyyy HH:mm:ss", new Date());
    };

    return order.shipment?.carrier_transport_status
        ?.filter(status => status.CurrentStatus || status.CompleteStatus)
        .map(status => (
            <div>
                {isLoading && <div>Loading...</div>}
                {status.CurrentStatus && (
                    <div>
                        <TypographyH3>{status.CurrentStatus.Shipment.Status.StatusDescription}</TypographyH3>
                        <TypographyH3>
                            {status.CurrentStatus.Shipment.MainBarcode} {status.CurrentStatus.Shipment.Status.TimeStamp}
                        </TypographyH3>
                        <TypographyP>
                            {status.CurrentStatus.Shipment.Address[0].FirstName}
                            {status.CurrentStatus.Shipment.Address[0].LastName}
                            {status.CurrentStatus.Shipment.Address[0].Street}{" "}
                            {status.CurrentStatus.Shipment.Address[0].HouseNumber}{" "}
                            {status.CurrentStatus.Shipment.Address[0].HouseNumberSuffix}
                            {status.CurrentStatus.Shipment.Address[0].Zipcode}
                            {status.CurrentStatus.Shipment.Address[0].City}
                            {status.CurrentStatus.Shipment.Address[0].CountryCode}
                        </TypographyP>

                        <TypographyP>
                            {status.CurrentStatus.Shipment.Address[1].FirstName}
                            {status.CurrentStatus.Shipment.Address[1].LastName}
                            {status.CurrentStatus.Shipment.Address[1].Street}{" "}
                            {status.CurrentStatus.Shipment.Address[1].HouseNumber}{" "}
                            {status.CurrentStatus.Shipment.Address[1].HouseNumberSuffix}
                            {status.CurrentStatus.Shipment.Address[1].Zipcode}
                            {status.CurrentStatus.Shipment.Address[1].City}
                            {status.CurrentStatus.Shipment.Address[1].CountryCode}
                        </TypographyP>

                        <TypographyP>
                            {status.CurrentStatus.Shipment.Address[2].FirstName}
                            {status.CurrentStatus.Shipment.Address[2].LastName}
                            {status.CurrentStatus.Shipment.Address[2].Street}{" "}
                            {status.CurrentStatus.Shipment.Address[2].HouseNumber}{" "}
                            {status.CurrentStatus.Shipment.Address[2].HouseNumberSuffix}
                            {status.CurrentStatus.Shipment.Address[2].Zipcode}
                            {status.CurrentStatus.Shipment.Address[2].City}
                            {status.CurrentStatus.Shipment.Address[2].CountryCode}
                        </TypographyP>
                    </div>
                )}
                <Flex className="gap-x-4 mb-4" justifyContent="start" alignItems="start">
                    <img
                        onClick={() => refetch()}
                        width={40}
                        src="https://saas.test/assets/img/carriers/postnl.svg"
                        alt=""
                        className="mt-1"
                    />
                    <div>
                        <TypographyH1 className="sm:text-lg lg:text-2xl text-foreground font-semibold my-0 leading-none">
                            PostNL status
                        </TypographyH1>
                        <TypographyP className="text-muted-foreground mb-0 mt-1">
                            Here you can see the PostNL status for this order.
                        </TypographyP>
                        <time className="text-muted-foreground my-0 text-xs font-light">
                            Last refresh:{" "}
                            {order.shipment?.last_refresh_carrier_transport_status_at &&
                                Intl.DateTimeFormat("nl-NL", {
                                    dateStyle: "short",
                                    timeStyle: "short"
                                }).format(new Date(order.shipment.last_refresh_carrier_transport_status_at))}
                        </time>
                    </div>
                </Flex>
                <div className="flex flex-col">
                    {status.CompleteStatus?.Shipment.OldStatus?.slice()
                        .reverse()
                        .filter(oldStatus => oldStatus.StatusCode !== "99")
                        .map((oldStatus, index) => (
                            <div>
                                <div key={index} className="pl-4">
                                    <div className="border-l border-orange-200 pl-3 pt-4 relative">
                                        <div
                                            className={cn(
                                                "absolute top-11 left-0 size-6 -translate-x-1/2 -translate-y-1/2 z-10 rounded-full bg-orange-100 flex items-center justify-center",
                                                oldStatus.StatusCode === "11" && "size-10"
                                            )}
                                        >
                                            {oldStatus.StatusCode === "11" ? (
                                                <House03Icon className="w-6 h-6 text-orange-500" />
                                            ) : (
                                                <Tick02Icon className="w-4 h-4 text-orange-500" />
                                            )}
                                        </div>

                                        <Card className="border-none">
                                            <CardContent className="py-2 px-4">
                                                <Flex justifyContent="start" alignItems="start" className="gap-x-4">
                                                    <Flex flexDirection="col" className="text-center grow-0 w-12">
                                                        <time className="uppercase text-xs text-muted-foreground">
                                                            {Intl.DateTimeFormat("nl-NL", {
                                                                month: "short"
                                                            }).format(parseDate(oldStatus.TimeStamp))}
                                                        </time>
                                                        <p className="font-bold">
                                                            {parseDate(oldStatus.TimeStamp)
                                                                .toLocaleString("nl-NL", { day: "2-digit" })
                                                                .toUpperCase()}
                                                        </p>
                                                    </Flex>
                                                    <Flex justifyContent="start" alignItems="start" className="gap-x-8">
                                                        <Flex
                                                            flexDirection="col"
                                                            alignItems="start"
                                                            className="gap-y-2"
                                                        >
                                                            <p className="text-xs uppercase text-muted-foreground">
                                                                {oldStatus.PhaseDescription}
                                                            </p>
                                                            <Flex
                                                                flexDirection="col"
                                                                alignItems="start"
                                                                className="gap-y-1"
                                                            >
                                                                <p className="text-sm">{oldStatus.StatusDescription}</p>
                                                                <time className="relative text-xs text-muted-foreground">
                                                                    {oldStatus.TimeStamp}
                                                                </time>
                                                            </Flex>
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                            </CardContent>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        ));
};
