import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { IProduct } from "@/product/types";
import {
    ColumnDef,
    ColumnFiltersState,
    Table as ReactTable,
    TableOptions as ReactTableOptions,
    Row,
    RowSelectionState,
    VisibilityState,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    useReactTable
} from "@tanstack/react-table";
import { Fragment, JSXElementConstructor, ReactElement, Ref, forwardRef, useState } from "react";

// Redecalare forwardRef
// this is to be able to use React.forwardRef to wrap Table component but to pass TData generic...
// more https://fettblog.eu/typescript-react-generic-forward-refs/
// @todo - find better approach since return type of render function "React.ReactElement" gived problem with displayName. When changing to "React.FunctionComponent"
//         then DataTable consumers break on column type
declare module "react" {
    function forwardRef<T, P = object>(
        render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
    ): (props: P & React.RefAttributes<T>) => (React.ReactElement & { displayName?: string }) | null;
}

export type TDataTable<TData> = ReactTable<TData>;

export interface IDataTableRenderProps<TData> {
    table: TDataTable<TData>;
    TableComponent: ReactElement;
}

export interface IDataTableProps<TData> {
    columns: ColumnDef<TData>[];
    data: TData[];
    allowColumnSelection?: boolean;
    allowSearch?: boolean;
    features?: {
        columnSelection?: boolean;
        localSearch?: boolean;
        rowSelection?: RowSelectionState;
    };
    enableRowSelection?: (data: Row<TData>) => boolean;
    onTableRowClick?: (data: Row<TData>) => void;
    onRowSelectionChange?: (data: Row<TData>[]) => void;
    rowClassName?: (data: any) => string;
    children?: (
        props: IDataTableRenderProps<TData>
    ) => ReactElement<IDataTableProps<TData>, string | JSXElementConstructor<IDataTableProps<TData>>> | null;
}

export const DataTable = forwardRef(function<TData>(
    {
        columns,
        data,
        features,
        children,
        rowClassName,
        enableRowSelection,
        onTableRowClick,
        onRowSelectionChange
    }: IDataTableProps<TData>,
    ref: Ref<TDataTable<TData>>
) {
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

    let options: ReactTableOptions<TData> = {
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        state: {},
        getRowCanExpand: (row: Row<TData>) => {
            const originalData = row.original as IProduct;
            return !!originalData.children && originalData.children.length > 0;
        },
        getExpandedRowModel: getExpandedRowModel()
    };

    if (features?.columnSelection) {
        options = {
            ...options,
            onColumnVisibilityChange: setColumnVisibility,
            state: { ...options.state, columnVisibility }
        };
    }

    if (features?.localSearch) {
        options = {
            ...options,
            onColumnFiltersChange: setColumnFilters,
            getFilteredRowModel: getFilteredRowModel(),
            state: { ...options.state, columnFilters }
        };
    }

    if (onRowSelectionChange) {
        options = {
            ...options,
            onRowSelectionChange: updaterOrValue => {
                const newRowSelection =
                    typeof updaterOrValue === "function" ? updaterOrValue(rowSelection) : updaterOrValue;
                setRowSelection(newRowSelection);
                if (onRowSelectionChange) {
                    const selectedRows = Object.keys(newRowSelection).map(id => table.getRow(id));
                    onRowSelectionChange(selectedRows);
                }
            },
            state: { ...options.state, rowSelection }
        };
    }

    if (enableRowSelection) {
        options = {
            ...options,
            enableRowSelection
        };
    }

    const table = useReactTable(options);

    const handleTableRowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        if (onTableRowClick) {
            const rowId = event.currentTarget.getAttribute("data-id");

            if (rowId) {
                const row = table.getRow(rowId);

                onTableRowClick(row);
            }
        }
    };

    const tableComponent = (
        <Table className="border border-muted/80 bg-white">
            <TableHeader className="border-b border-t rounded-xl">
                {table.getHeaderGroups().map(headerGroup => (
                    <TableRow key={headerGroup.id} className="border-none">
                        {headerGroup.headers.map(header => {
                            return (
                                <TableHead
                                    key={header.id}
                                    className="text-xs uppercase font-extralight text-muted-foreground whitespace-nowrap py-3"
                                >
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(header.column.columnDef.header, header.getContext())}
                                </TableHead>
                            );
                        })}
                    </TableRow>
                ))}
            </TableHeader>
            <TableBody>
                {table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map(row => (
                        <Fragment key={row.id}>
                            <TableRow
                                className="border-muted"
                                onClick={handleTableRowClick}
                                key={row.id}
                                data-id={row.id}
                                data-state={row.getIsSelected() && "selected"}
                            >
                                {row.getVisibleCells().map(cell => (
                                    <TableCell key={cell.id} className="text-muted-foreground py-3.5">
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </TableCell>
                                ))}
                            </TableRow>
                            {/* If the row is expanded, render the expanded UI as a separate row with a single cell that
                            spans the width of the table */}
                            {row.getIsExpanded() && (
                                <TableRow className="bg-muted border-none">
                                    <TableCell colSpan={columns.length} className="">
                                        <div className="relative ml-16">
                                            <div className="absolute -left-10 top-0 w-10 h-full border-l border-slate-300" />
                                            {/* @ts-ignore */}
                                            {row?.original?.children.map(child => (
                                                <div key={child.id} className="relative px-0">
                                                    <div className="absolute -left-10 -translate-x-1/2 top-2.5 w-3 h-3 bg-slate-300 rounded-full" />
                                                    <div className="py-0 my-0">
                                                        <div>
                                                            {child.id} {child.name}
                                                        </div>
                                                        <div></div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )}
                        </Fragment>
                    ))
                ) : (
                    <TableRow>
                        <TableCell colSpan={columns.length} className="text-center">
                            No results.
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );

    return children ? children({ table, TableComponent: tableComponent }) : tableComponent;
});
