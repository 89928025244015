import { authHooks } from "@/auth";
import { brandHooks } from "@/brand";
import { MoneyFormatter } from "@/common";
import { Pagination } from "@/components/custom/pagination";
import { SearchField } from "@/components/custom/search/search";
import { DataTableFacetedFilter } from "@/components/custom/table/DataTableFacetedFilter";
import { DataTableSkeleton } from "@/components/custom/table/DataTableSkeleton";
import { TypographyH1 } from "@/components/custom/text/TypographyH1";
import { TypographyP } from "@/components/custom/text/TypographyP";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import Flex from "@/components/ui/flex";
import { ScrollArea } from "@/components/ui/scroll-area";
import { identityHooks } from "@/identity";
import OrderSheet from "@/order/pages/OrderSheet";
import { useQueryBuilder } from "@cgarciagarcia/react-query-builder";
import {
    ArrowReloadHorizontalIcon,
    Download04Icon,
    SecurityBlockIcon,
    SecurityCheckIcon,
    Tv01Icon
} from "@hugeicons/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import { ProductTable } from "../components/ProductTable";
import { useGetDownloadCsv, useGetProducts, useGetTotalStockValue, useResyncShopify } from "../hooks";
import { ProductSheet } from "./ProductSheet";

export const ProductOverviewPage = () => {
    const [status, setStatus] = useState<string[]>(["1"]);
    const [typeOfProduct, setTypeOfProduct] = useState<string[]>(["1"]);
    const [page, setPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState<string | null>(null);
    const queryBuilder = useQueryBuilder({
        filters: [
            {
                attribute: "status",
                value: status
            },
            {
                attribute: "type_of_product",
                value: typeOfProduct
            }
        ],
        params: {
            page: [page],
            per_page: [100]
        }
    });
    const { productId: paramProductId } = useParams();
    const [productId, setProductId] = useState<number | null>(null);
    const [showProductSheet, setShowProductSheet] = useState(false);
    const [orderId, setOrderId] = useState<number | null>(null);
    const [showOrderSheet, setShowOrderSheet] = useState(false);
    const auth = authHooks.useAuth();

    const { data, isLoading } = useGetProducts(auth.activeDomain, queryBuilder);
    const totalPages = data?.meta.per_page ? Math.ceil(data.meta.total / data.meta.per_page) : 0;

    const { data: totalStockValue, isLoading: isLoadingTotalStockValue } = useGetTotalStockValue(auth.activeDomain);
    const { mutate: downloadCsv } = useGetDownloadCsv(auth.activeDomain.id);
    const { data: identities } = identityHooks.useGetDomainIdentities(auth.activeDomain.id);
    const { data: brands } = brandHooks.useDomainBrands(auth.activeDomain.id);
    const { mutate: resyncShopify } = useResyncShopify(auth.activeDomain.id);
    const handleEditProduct = (productId: number) => {
        setProductId(productId);
        setShowProductSheet(true);
    };

    const handleProductSheetClose = () => {
        setProductId(null);
        setShowProductSheet(false);
    };

    const handleShowOrder = (orderId: number) => {
        setOrderId(orderId);
        setShowOrderSheet(true);
    };

    const handleResyncShopify = () => {
        resyncShopify();
    };

    useEffect(() => {
        if (paramProductId) {
            setProductId(parseInt(paramProductId));
            setShowProductSheet(true);
        }
    }, [paramProductId]);

    return (
        <div>
            <TypographyH1
                onClick={() => {
                    toast.success("Products", {
                        position: "top-right"
                    });
                }}
            >
                Products
            </TypographyH1>
            <TypographyP>Here you can see all products for this domain.</TypographyP>

            <div className="grid grid-cols-4 gap-4">
                {!isLoadingTotalStockValue && (
                    <Card className="col-span-1 my-4">
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 p-4">
                            <CardTitle className="text-sm font-medium">Stock value</CardTitle>
                        </CardHeader>
                        <CardContent className="px-4">
                            <div className="text-2xl font-bold text-primary">
                                {MoneyFormatter(totalStockValue?.total_in_stock_in_euro || 0)}
                            </div>
                            <p className="text-xs text-muted-foreground">Includes sold products, not in stock.</p>
                        </CardContent>
                    </Card>
                )}
                <Button variant="outline" onClick={() => setShowProductSheet(true)}>
                    <Tv01Icon className="w-5 h-5 mr-2" /> Add product
                </Button>
                <Button variant="outline" onClick={() => downloadCsv(queryBuilder)}>
                    <Download04Icon className="w-5 h-5 mr-2" /> Download
                </Button>
                <Button variant="outline" onClick={() => handleResyncShopify()}>
                    <ArrowReloadHorizontalIcon className="w-5 h-5 mr-2" /> Resync shopify
                </Button>
            </div>

            <Card>
                <CardHeader>
                    <Flex>
                        <div className="w-96">
                            <SearchField
                                className="w-96"
                                defaultValue={searchQuery ?? undefined}
                                onChange={query => {
                                    queryBuilder
                                        .removeParam("search")
                                        .setParam("search", query)
                                        .build();
                                    setPage(1);
                                }}
                            />
                        </div>

                        <ScrollArea className="w-full whitespace-nowrap">
                            <Flex justifyContent="end" className="gap-2">
                                <DataTableFacetedFilter
                                    title="Identity"
                                    options={
                                        identities?.map(identity => ({
                                            label: identity.name,
                                            value: identity.id.toString()
                                        })) || []
                                    }
                                    onChange={values => {
                                        queryBuilder
                                            .setParam("page", [1])
                                            .removeFilter("identity_id")
                                            .filter("identity_id", values);
                                    }}
                                />

                                <DataTableFacetedFilter
                                    title="Brands"
                                    options={
                                        brands?.data.map(identity => ({
                                            label: identity.name,
                                            value: identity.id.toString()
                                        })) || []
                                    }
                                    onChange={values => {
                                        queryBuilder
                                            .setParam("page", [1])
                                            .removeFilter("brand_id")
                                            .filter("brand_id", values);
                                    }}
                                />

                                <DataTableFacetedFilter
                                    title="status"
                                    options={[
                                        { label: "Active", value: "1" },
                                        { label: "Inactive", value: "0" }
                                    ]}
                                    defaultSelected={status}
                                    onChange={values => {
                                        queryBuilder
                                            .setParam("page", [1])
                                            .removeFilter("status")
                                            .filter("status", values);
                                    }}
                                />

                                <DataTableFacetedFilter
                                    title="type"
                                    options={[
                                        { label: "Parent products", value: "1" },
                                        { label: "Child products", value: "0" }
                                    ]}
                                    defaultSelected={status}
                                    onChange={values => {
                                        queryBuilder
                                            .setParam("page", [1])
                                            .removeFilter("type_of_product")
                                            .filter("type_of_product", values);
                                    }}
                                />

                                <DataTableFacetedFilter
                                    title="Verified"
                                    options={[
                                        {
                                            label: "Verified",
                                            value: "1",
                                            icon: <SecurityCheckIcon className="w-3.5 h-3.5 text-green-600 mr-1" />
                                        },
                                        {
                                            label: "Not verified",
                                            value: "0",
                                            icon: <SecurityBlockIcon className="w-3.5 h-3.5 text-red-600 mr-1" />
                                        }
                                    ]}
                                    onChange={values => {
                                        queryBuilder
                                            .setParam("page", [1])
                                            .removeFilter("verified_at")
                                            .filter("verified_at", values);
                                    }}
                                />
                            </Flex>
                        </ScrollArea>
                    </Flex>
                </CardHeader>
                <CardContent className="p-0">
                    {isLoading ? (
                        <DataTableSkeleton showSearch={false} />
                    ) : (
                        <ProductTable onEditProduct={handleEditProduct} data={data?.data || []} />
                    )}
                </CardContent>
            </Card>

            {data?.data && totalPages > 1 && (
                <Pagination
                    className="mt-4"
                    meta={data.meta}
                    onPageChange={(pageNumber: number) => queryBuilder.removeParam("page").setParam("page", pageNumber)}
                />
            )}

            <ProductSheet
                handleShowOrder={handleShowOrder}
                productId={productId}
                open={showProductSheet}
                handleClose={handleProductSheetClose}
                orderSheetOpen={showOrderSheet}
            />

            {orderId && (
                <OrderSheet
                    orderId={orderId}
                    open={showOrderSheet}
                    onClose={() => {
                        setShowOrderSheet(false);
                        setOrderId(null);
                    }}
                />
            )}
        </div>
    );
};
