import { queryClient } from "@/app";
import { commonTypes } from "@/common";
import { useMutation, useQuery } from "react-query";
import QueryKeys from "./constans/QueryKeys";
import { createDomainSupplier, getDomainSuppliers } from "./queries";
import { ISupplier } from "./types";

export const useGetDomainSuppliers = (domainId: number, options?: commonTypes.IUseGetOptions) =>
    useQuery<ISupplier[], Error>(QueryKeys.supplierItems, () => getDomainSuppliers(domainId), options);

export const useCreateDomainSupplier = (domainId: number) =>
    useMutation((data: ISupplier) =>
        createDomainSupplier(domainId, data).then(res => {
            queryClient.invalidateQueries(QueryKeys.supplierItems);

            return res;
        })
    );
