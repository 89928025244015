import { carrierTypes } from "@/carrier";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogTitle } from "@/components/ui/dialog";
import { IOrder } from "../types";
import { ExactDeliveryForm } from "./ExactDeliveryForm";

interface IExactDeliveryDialogProps {
    carriers?: carrierTypes.ICarrier[];
    onSubmit: (formValues: any) => void;
    onClickClose: () => void;
    order?: IOrder;
    isOpen?: boolean;
}

export const ExactDeliveryDialog = ({ order, onSubmit, isOpen = false, onClickClose }: IExactDeliveryDialogProps) => (
    <Dialog open={isOpen} onOpenChange={onClickClose}>
        <DialogContent>
            <DialogTitle>Change delivery date:</DialogTitle>
            <ExactDeliveryForm order={order} onSubmit={onSubmit} />
            <DialogFooter>
                <Button type="submit" form="exact-delivery-form">
                    Submit
                </Button>
            </DialogFooter>
        </DialogContent>
    </Dialog>
);
