import { axios } from "@/app";
import { QueryBuilder } from "@cgarciagarcia/react-query-builder";
import { IPurchaseOrder, IPurchaseOrderResponse } from "./types";

export const getDomainPurchaseOrders = async (
    domainId: number,
    queryBuilder: QueryBuilder
): Promise<IPurchaseOrderResponse> => {
    const response = await axios.get<IPurchaseOrderResponse>(
        `domains/${domainId}/purchase-orders${queryBuilder.build()}`
    );

    return response.data;
};

export const getPurchaseOrder = async (purchaseOrderId: number): Promise<IPurchaseOrder> => {
    const response = await axios.get<{ data: IPurchaseOrder }>(`purchase-orders/${purchaseOrderId}`);

    return response.data.data;
};

export const createPurchaseOrder = async (domainId: number, data: IPurchaseOrder): Promise<IPurchaseOrder> => {
    const response = await axios.post<{ data: IPurchaseOrder }>(`domains/${domainId}/purchase-orders`, data);

    return response.data.data;
};

export const updatePurchaseOrder = async (purchaseOrderId: number, data: IPurchaseOrder): Promise<number> => {
    await axios.put<null>(`purchase-orders/${purchaseOrderId}`, data);

    return purchaseOrderId;
};

export const setPurchaseOrderStatusCompleted = async (purchaseOrderId: number): Promise<number> => {
    await axios.put<null>(`purchase-orders/${purchaseOrderId}/completed`);

    return purchaseOrderId;
};

export const deletePurchaseOrder = async (purchaseOrderId: number): Promise<number> => {
    await axios.delete<null>(`purchase-orders/${purchaseOrderId}`);

    return purchaseOrderId;
};
