"use client";

import { TrendingUp } from "lucide-react";
import { Label, Pie, PieChart } from "recharts";

import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { ChartContainer, ChartTooltip, ChartTooltipContent } from "@/components/ui/chart";
import { orderTypes } from "@/order";
import { IProduct } from "../types";

interface IProductPieChartProps {
    orders: orderTypes.IOrder[];
    product: IProduct;
}

interface SalesChannelConfig {
    label: string;
    fill: string;
}

export const ProductPieChart = ({ orders, product }: IProductPieChartProps) => {
    const salesPerChannel = orders
        .filter(order => order.channel?.company_name)
        .flatMap(order =>
            order.products
                .filter(orderProduct => orderProduct.product_id === product.id)
                .map(orderProduct => {
                    return {
                        companyName: order.channel.company_name,
                        quantity: orderProduct.quantity
                    };
                })
                .filter(Boolean)
        )
        .reduce((acc: Record<string, number>, { companyName, quantity }) => {
            if (companyName) {
                acc[companyName] = (acc[companyName] || 0) + quantity;
            }

            return acc;
        }, {} as Record<string, number>);

    const salesPerChannelArray = Object.entries(salesPerChannel).map(([companyName, quantity], index) => ({
        label: companyName,
        quantity,
        fill: `hsl(var(--chart-${index + 1}))`
    }));

    // Update the salesPerChannelConfig with the correct type
    const salesPerChannelConfig: Record<string, SalesChannelConfig> = salesPerChannelArray.reduce(
        (acc: Record<string, SalesChannelConfig>, channel: SalesChannelConfig) => {
            acc[channel.label] = {
                label: channel.label,
                fill: channel.fill
            };
            return acc;
        },
        {} as Record<string, SalesChannelConfig>
    );

    return (
        <Card className="flex flex-col">
            <CardHeader className="items-center pb-0">
                <CardTitle>Sales by channel</CardTitle>
                <CardDescription>Last 12 months</CardDescription>
            </CardHeader>
            <CardContent className="flex-1 pb-0">
                <ChartContainer config={salesPerChannelConfig} className="mx-auto aspect-square max-h-[250px]">
                    <PieChart>
                        <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
                        <Pie
                            data={salesPerChannelArray}
                            dataKey="quantity"
                            nameKey="label"
                            innerRadius={60}
                            strokeWidth={5}
                        >
                            <Label
                                content={({ viewBox }) => {
                                    if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                                        return (
                                            <text
                                                x={viewBox.cx}
                                                y={viewBox.cy}
                                                textAnchor="middle"
                                                dominantBaseline="middle"
                                            >
                                                <tspan
                                                    x={viewBox.cx}
                                                    y={viewBox.cy}
                                                    className="fill-foreground text-3xl font-bold"
                                                >
                                                    {salesPerChannelArray.map(item => item.label).length}
                                                </tspan>
                                                <tspan
                                                    x={viewBox.cx}
                                                    y={(viewBox.cy || 0) + 24}
                                                    className="fill-muted-foreground"
                                                >
                                                    Channels
                                                </tspan>
                                            </text>
                                        );
                                    }
                                }}
                            />
                        </Pie>
                    </PieChart>
                </ChartContainer>
            </CardContent>
            <CardFooter className="flex-col gap-2 text-sm">
                <div className="flex items-center gap-2 font-medium leading-none">
                    Trending up by 1000% this month <TrendingUp className="h-4 w-4" />
                </div>
                {/* <div className="leading-none text-muted-foreground">Showing total visitors for the last 6 months</div> */}
            </CardFooter>
        </Card>
    );
};
