import { I18nextProvider } from "react-i18next";
import { QueryClientProvider } from "react-query";

import { AuthLoaderProvider } from "@/auth/components/AuthLoaderProvider";
import { Toaster } from "sonner";
import { AppStateContextProvider } from "../context/AppStateContextProvider";
import { i18next } from "../services";
import { queryClient } from "../services/queryClient";
import { Routes } from "./Routes";

export const App = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <AppStateContextProvider>
                <I18nextProvider i18n={i18next}>
                    <Toaster position="top-right" />
                    <AuthLoaderProvider>
                        <Routes />
                    </AuthLoaderProvider>
                </I18nextProvider>
            </AppStateContextProvider>
        </QueryClientProvider>
    );
};
