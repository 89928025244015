import { config } from "@/app";
import { authHooks } from "@/auth";
import { MoneyFormatter } from "@/common";
import { DataTable } from "@/components/custom/table";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import Flex from "@/components/ui/flex";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { QueueStatus } from "@/queue";
import { rbacUtils, Roles } from "@/rbac";
import {
    Building05Icon,
    DeliveryDelay02Icon,
    DeliverySent02Icon,
    Home01Icon,
    House04Icon,
    Tick01Icon,
    Tick02Icon
} from "@hugeicons/react";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { ColumnDef } from "@tanstack/react-table";
import { Paperclip } from "lucide-react";
import { forwardRef } from "react";
import { OrderStatus } from "../constans/OrderStatus";
import { IOrder, IOrderResponse, IQueue } from "../types";

interface IOrderTableProps {
    orders?: IOrderResponse | undefined;
    activeStatus: OrderStatus;
    onClickQueueStatus: (queue: IQueue) => void;
    onEditOrder: (order: IOrder) => void;
    onClickCancelOrder: (order: IOrder) => void;
    onRowSelectionChange?: (selectedRows: IOrder[]) => void;
}

export const OrderTable = forwardRef(
    (
        {
            orders,
            activeStatus,
            onClickQueueStatus,
            onEditOrder,
            onClickCancelOrder,
            onRowSelectionChange
        }: IOrderTableProps,
        ref
    ) => {
        const { user } = authHooks.useAuth();

        const isEveryQueueStatusSame = (order: IOrder) =>
            order.queues?.every((queue, _index, array) => queue.status === array[0].status);

        const productReservedStatus = (order: IOrder) => {
            if (order.has_all_products_reserved) {
                return <DeliverySent02Icon className="text-green-500 w-5">Reserved</DeliverySent02Icon>;
            }

            return <DeliveryDelay02Icon className="text-red-500 w-5">Not reserved</DeliveryDelay02Icon>;
        };

        const getOrderStatus = (order: IOrder) => (
            <TooltipProvider>
                <div className="isolate flex -space-x-1 overflow-hidden">
                    {order.queues?.map(queue => {
                        if (queue.status === QueueStatus.Success) {
                            return (
                                <Tooltip key={queue.id}>
                                    <TooltipTrigger>
                                        <div className="bg-green-200 relative z-30 inline-block size-4 rounded-full ring-2 ring-white flex items-center justify-center">
                                            <Tick01Icon className="w-3 h-3 text-green-600" variant="solid" />
                                        </div>
                                    </TooltipTrigger>
                                    <TooltipContent className="bg-green-50 text-green-500 shadow-xl">
                                        {queue.order_product_id ? `o_p: ${queue.order_product_id}` : ""} Success
                                    </TooltipContent>
                                </Tooltip>
                            );
                        } else if (queue.status === QueueStatus.Timeout) {
                            return (
                                <Tooltip key={queue.id}>
                                    <TooltipTrigger>
                                        <div
                                            className={cn(
                                                "bg-red-300 relative z-30 inline-block size-4 rounded-full ring-2 ring-white flex items-center justify-center",
                                                rbacUtils.hasOneOfRoles(user.roles, Roles.SuperAdmin)
                                                    ? "cursor-pointer"
                                                    : ""
                                            )}
                                            onClick={() => onClickQueueStatus(queue)}
                                        />
                                    </TooltipTrigger>
                                    <TooltipContent className="bg-red-50 text-red-500 shadow-xl">
                                        {queue.order_product_id ? `o_p: ${queue.order_product_id}` : ""} Timeout
                                    </TooltipContent>
                                </Tooltip>
                            );
                        } else if (queue.status === QueueStatus.Pending) {
                            return (
                                <Tooltip key={queue.id}>
                                    <TooltipTrigger>
                                        <div
                                            className={cn(
                                                "bg-yellow-400 relative z-30 inline-block size-4 rounded-full ring-2 ring-white",
                                                rbacUtils.hasOneOfRoles(user.roles, Roles.SuperAdmin)
                                                    ? "cursor-pointer"
                                                    : ""
                                            )}
                                            onClick={() => onClickQueueStatus(queue)}
                                        />
                                    </TooltipTrigger>
                                    <TooltipContent className="bg-yelllow-50 text-yellow-500 shadow-xl">
                                        {queue.order_product_id ? `o_p: ${queue.order_product_id}` : ""} Pending
                                    </TooltipContent>
                                </Tooltip>
                            );
                        }

                        return (
                            <Tooltip key={queue.id}>
                                <TooltipTrigger>
                                    <div
                                        className={cn(
                                            "bg-red-400 relative z-30 inline-block size-4 rounded-full ring-2 ring-white",
                                            rbacUtils.hasOneOfRoles(user.roles, Roles.SuperAdmin)
                                                ? "cursor-pointer"
                                                : ""
                                        )}
                                        onClick={() => onClickQueueStatus(queue)}
                                    />
                                </TooltipTrigger>
                                <TooltipContent className="bg-red-50 text-red-500 shadow-xl">
                                    {queue.order_product_id ? `o_p: ${queue.order_product_id}` : ""} Error
                                </TooltipContent>
                            </Tooltip>
                        );
                    })}
                </div>
            </TooltipProvider>
        );
        const columns: ColumnDef<IOrder>[] = [
            {
                id: "select-col",
                header: ({ table }) => (
                    <Checkbox
                        className="border-foreground/20 shadow-none mr-4"
                        checked={
                            table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && "indeterminate")
                        }
                        onCheckedChange={value => table.toggleAllPageRowsSelected(!!value)}
                        aria-label="Select all"
                    />
                ),
                cell: ({ row }) =>
                    row.getCanSelect() && (
                        <Checkbox
                            checked={row.getIsSelected()}
                            disabled={!row.getCanSelect()}
                            onCheckedChange={value => row.toggleSelected(!!value)}
                            aria-label="Select row"
                            className="border-foreground/20 shadow-none mr-4"
                        />
                    )
            },
            {
                id: "id",
                header: "Platform & Channel",
                cell: ({ row }) => (
                    <div className="flex gap-4 items-center">
                        <div className="flex -space-x-1 relative" onClick={() => onEditOrder(row.original)}>
                            <div className="bg-white rounded-full w-6 h-6 ring-2 ring-white">
                                <img
                                    className="rounded-md w-6 h-6"
                                    src={`${config.appUrl}/assets/img/channels/${row.original.channel.icon}`}
                                    alt=""
                                    width="28"
                                    height="28"
                                />
                            </div>
                            {!!row.original.integrator_marketplace && (
                                <div className="bg-white rounded-full w-7 h-7 ring-2 ring-white absolute">
                                    {row.original.integrator_marketplace === "amazon" ? (
                                        <img
                                            className="rounded-md w-7 h-7"
                                            src={`${config.appUrl}/assets/img/channels/amazon-icon.svg`}
                                            alt=""
                                            width="24"
                                            height="20"
                                        />
                                    ) : row.original.integrator_marketplace === "mirakl_mediamarkt" ? (
                                        <img
                                            className="rounded-md w-6 h-6"
                                            src={`${config.appUrl}/assets/img/channels/media-markt-logo.svg`}
                                            alt=""
                                            width="20"
                                            height="20"
                                        />
                                    ) : (
                                        <img
                                            className="rounded-md w-7 h-7"
                                            src={`${config.appUrl}/assets/img/channels/blokker.png`}
                                            alt=""
                                            width="20"
                                            height="20"
                                        />
                                    )}
                                </div>
                            )}
                        </div>

                        <div className="flex flex-col">
                            <div className="text-sm">
                                <Flex className="w-full font-medium">
                                    <span className="text-xs font-extralight text-muted-foreground">
                                        # {row.original.id}
                                    </span>
                                    {(row.original.customer?.shipmentAddress?.is_alarm ||
                                        row.original.customer?.billingAddress?.is_alarm) && (
                                        <span className="relative flex h-3 w-3 -left-1">
                                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                            <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                                        </span>
                                    )}
                                </Flex>
                            </div>

                            <div className="text-xs whitespace-nowrap text-foreground">
                                {row.original.channel.company_name}
                            </div>
                        </div>
                    </div>
                )
            },
            {
                id: "status",
                header: "Statusses",
                cell: ({ row }) => {
                    const isQueueStatusSuccess = row.original.queues?.every(
                        queue => queue.status === QueueStatus.Success
                    );
                    return (
                        <div className="flex flex-col gap-y-0.5">
                            {isQueueStatusSuccess}
                            {!!row.original.queues?.length && getOrderStatus(row.original)}
                            {!row.original.customer?.shipmentAddress?.house_number && (
                                <Badge color="red" className="rounded-full">
                                    Missing housenumber
                                </Badge>
                            )}
                        </div>
                    );
                }
            },
            {
                id: "external_order_id",
                header: "Ex Order ID",
                accessorKey: "external_order_id"
            },
            {
                id: "carrier",
                header: "Delivery",
                cell: ({ row }) => {
                    const order = row.original;
                    const lastOrderSlipAttachment = order.attachments && order.attachments[0];

                    return (
                        <div className="flex items-center justify-start space-x-2.5">
                            {order.shipment?.is_delivered && (
                                <div className="size-5 bg-green-100 rounded-full text-white flex items-center justify-center">
                                    <Tick02Icon variant="solid" className="w-4 h-4 text-green-500" />
                                </div>
                            )}
                            {!order.pick_up && !order.shipment?.is_delivered
                                ? productReservedStatus(order)
                                : !order.shipment?.is_delivered && <Home01Icon className="w-5 h-5 text-red-700" />}
                            <div className=" text-xs">
                                <div className="flex items-center justify-start">
                                    {order.carrier}
                                    {lastOrderSlipAttachment && (
                                        <Paperclip
                                            className={cn("ml-1 w-2.5 cursor-pointer", {
                                                "text-slate-300": lastOrderSlipAttachment.is_downloaded,
                                                "text-slate-800": !lastOrderSlipAttachment.is_downloaded
                                            })}
                                        />
                                    )}
                                </div>
                                <p>
                                    {order.shipment?.shipment_order_products?.map(product =>
                                        product.tracking_numbers?.join(", ")
                                    )}
                                    {order.shipment?.carrier_transport_ids?.join(", ")}
                                </p>
                            </div>
                        </div>
                    );
                }
            },
            {
                id: "delivery_date_at",
                header: "Delivery date",

                cell: ({ row }) => {
                    const order = row.original;

                    return order.exact_delivery_date_at ? (
                        <div>
                            {order.exact_delivery_date_at}
                            <br />
                            {order.delivery_between_hours && (
                                <span>
                                    <span className="font-normal text-xs">Between:</span>{" "}
                                    {order.delivery_between_hours.from}:00 - {order.delivery_between_hours.to}:00
                                </span>
                            )}
                        </div>
                    ) : (
                        <div>
                            {order.latest_delivery_date_at}
                            <br />
                            {order.delivery_between_hours && (
                                <span>
                                    <span className="font-normal text-xs">Between:</span>{" "}
                                    {order.delivery_between_hours.from}:00 - {order.delivery_between_hours.to}:00
                                </span>
                            )}
                        </div>
                    );
                }
            },

            {
                id: "customer.shipmentAddress.zip_code",
                header: () => <div className="ml-9">Address</div>,
                cell: ({ row }) => {
                    const order = row.original;

                    return (
                        <Flex className="text-xs gap-4" justifyContent="start" alignItems="center">
                            <div className="relative">
                                <img
                                    src={`${config.appUrl}/assets/img/flags/${order.customer?.shipmentAddress?.country_iso_3166_2}.svg`}
                                    alt=""
                                    className="w-3 h-3 rounded-full object-cover absolute -right-2 -top-2"
                                />
                                {!order.customer?.shipmentAddress?.company ? (
                                    <House04Icon className="h-5 w-5 text-muted-foreground" />
                                ) : (
                                    <Building05Icon className="h-5 w-5 text-muted-foreground" />
                                )}
                            </div>

                            <div>
                                {order.customer?.shipmentAddress?.fullStreetDetails}

                                <br />
                                <span className="flex text-xs">
                                    {order.customer?.shipmentAddress?.full_name || "Unknown"}
                                    <br />
                                    {order.customer?.shipmentAddress?.company || "-"}
                                    {order.customer?.shipmentAddress?.zip_code} {order.customer?.shipmentAddress?.city}
                                </span>
                            </div>
                        </Flex>
                    );
                }
            },
            {
                id: "products",
                header: "Products",
                cell: ({ row }) => {
                    const order = row.original;

                    return (
                        <ul className="text-xs justify-start items-start h-auto p-0">
                            {order.products?.map(product => (
                                <li title={product.name} key={product.id} className="w-64 truncate">
                                    {product.id} {product.quantity}x ({MoneyFormatter(product.price)}) {product.name}
                                </li>
                            ))}
                        </ul>
                    );
                }
            },
            {
                id: "order_placed_at",
                header: "Placed at",
                cell: ({ row }) => {
                    const order = row.original;

                    return (
                        <div>
                            {Intl.DateTimeFormat("nl-NL", { dateStyle: "medium", timeStyle: "short" }).format(
                                new Date(row.original.order_placed_at)
                            )}
                        </div>
                    );
                }
            },
            {
                id: "view",
                // header: "",
                cell: ({ row }) => {
                    const order = row.original;

                    return (
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button variant="ghost" className="h-8 w-8 p-0">
                                    <span className="sr-only">Open menu</span>
                                    <DotsHorizontalIcon className="h-4 w-4" />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                                {/* <DropdownMenuSeparator /> */}
                                <DropdownMenuItem onClick={() => onEditOrder(order)}>View order</DropdownMenuItem>

                                {[
                                    OrderStatus.Warehouse,
                                    OrderStatus.FutureShipping,
                                    OrderStatus.New,
                                    OrderStatus.Shipping
                                ].includes(order.status) && (
                                    <DropdownMenuItem onClick={() => onClickCancelOrder(order)}>
                                        Cancel Order
                                    </DropdownMenuItem>
                                )}
                            </DropdownMenuContent>
                        </DropdownMenu>
                    );
                }
            }
        ];

        const isRowSelectableResolver = (order: IOrder): boolean => {
            if (
                (!order.pick_up &&
                    !order.queues?.length &&
                    order.channel.key === "bol" &&
                    activeStatus !== OrderStatus.New) ||
                (!order.pick_up &&
                    (!order.shipment?.external_shipment_id ||
                        !order.shipment?.carrier_transport_ids ||
                        order.shipment?.carrier_transport_ids?.length < 1) &&
                    activeStatus > OrderStatus.New) ||
                (!order.pick_up &&
                    order.queues?.length &&
                    (!isEveryQueueStatusSame(order) || order.queues[0].status !== QueueStatus.Success)) ||
                (order.pick_up && activeStatus === OrderStatus.Warehouse) ||
                (order.status === OrderStatus.FutureShipping && !order.has_all_products_reserved)
            ) {
                return false;
            }

            return true;
        };

        const rowClassNameResolver = (order: IOrder) => {
            return cn(
                order.is_highlighted &&
                    (order.status === OrderStatus.Shipping || order.status === OrderStatus.Warehouse) &&
                    "bg-orange-50",
                order.added && "bg-green-50",
                order.attachments && order.attachments[0]?.is_downloaded && "bg-amber-50"
            );
        };

        return (
            <DataTable
                columns={columns}
                data={orders?.data || []}
                enableRowSelection={data => isRowSelectableResolver(data.original)}
                onRowSelectionChange={selectedRows => {
                    if (onRowSelectionChange) {
                        const orders = selectedRows.map(row => row.original);
                        onRowSelectionChange(orders);
                    }
                }}
                rowClassName={(row: any) => rowClassNameResolver(row.original)}
            />
        );
    }
);
