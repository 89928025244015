import { queryClient } from "@/app";
import { commonTypes } from "@/common";
import { domainTypes } from "@/domain";
import { QueryBuilder } from "@cgarciagarcia/react-query-builder";
import { useMutation, useQuery } from "react-query";
import { QueryKeys } from "./constans/QueryKeys";
import {
    addCustomerAlert,
    confirmPickUpOrder,
    createOrder,
    createOrderNotification,
    deleteFailedOrder,
    forceQueueStatusToSuccess,
    getDomainOrders,
    getDownloadCsv,
    getFailedOrders,
    getOrder,
    getOrderCountPerStatus,
    getOrderTransportStatus,
    getProductOrders,
    getShipmentLabels,
    OrderResendInvoice,
    removeCustomerAlert,
    resetOrderAttachmentFlag,
    updateCarrier,
    updateExactDeliveryDate,
    updateOrder,
    updateOrderStatus,
    updateOrderToWarehouse,
    updateQueueStatus,
    updateResetOrder
} from "./queries";
import { IFailedOrder, IOrder, IOrderCount, IOrderResponse } from "./types";

export const useGetDomainOrders = (
    domain: domainTypes.IDomain,
    queryBuilder?: QueryBuilder,
    options?: commonTypes.IUseGetOptions
) =>
    useQuery<IOrderResponse>(
        [QueryKeys.OrderItems, { domainId: domain.id, ...queryBuilder?.toArray() }],
        () => getDomainOrders(domain, queryBuilder),
        options
    );

export const useGetOrder = (id: number, options?: commonTypes.IUseGetOptions) =>
    useQuery<IOrder, Error>([QueryKeys.OrderItem, { id }], () => getOrder(id), options);

export const useGetOrderCountPerStatus = (domain: domainTypes.IDomain) =>
    useQuery<IOrderCount[]>([QueryKeys.OrderCount, { domain_id: domain.id }], () => getOrderCountPerStatus(domain));

export const useUpdateOrdersToWarehouse = () => useMutation(updateOrderToWarehouse);

export const useGetDownloadCsv = () => useMutation(getDownloadCsv);

export const useGetShipmentLabels = () => useMutation(getShipmentLabels);

export const useResetOrderAttachmentFlag = () => useMutation(resetOrderAttachmentFlag);

export const useUpdateOrder = () =>
    useMutation(updateOrder, {
        onSuccess: (orderId: number) => {
            queryClient.invalidateQueries([QueryKeys.OrderItem, { id: orderId }]);
        }
    });

export const useUpdateOrderStatus = () =>
    useMutation(updateOrderStatus, {
        onSuccess: (order: IOrder) => {
            queryClient.invalidateQueries([QueryKeys.OrderItems, { id: order.id }]);
            queryClient.invalidateQueries(QueryKeys.OrderCount);
        }
    });

export const useCreateOrderNotification = () =>
    useMutation(createOrderNotification, {
        onSuccess: (order: IOrder) => {
            queryClient.invalidateQueries([QueryKeys.OrderItem, { id: order.id }]);
        }
    });

export const useUpdateExactDeliveryDate = () =>
    useMutation(updateExactDeliveryDate, {
        onSuccess: () => queryClient.invalidateQueries(QueryKeys.OrderItems)
    });

export const useUpdateResetOrder = () =>
    useMutation(updateResetOrder, {
        onSuccess: (order: IOrder) => {
            queryClient.invalidateQueries([QueryKeys.OrderItem, { id: order.id }]);
        }
    });

export const useUpdateQueueStatus = () =>
    useMutation(updateQueueStatus, {
        onSuccess: () => {
            queryClient.invalidateQueries(QueryKeys.OrderItems);
        }
    });

export const useForceQueueStatusToSuccess = () =>
    useMutation(forceQueueStatusToSuccess, {
        onSuccess: () => {
            queryClient.invalidateQueries(QueryKeys.OrderItems);
        }
    });

export const useCreateOrder = () =>
    useMutation(createOrder, {
        onSuccess: () => {
            console.log("added");
        }
    });

export const useGetProductOrders = (productId: number, options?: commonTypes.IUseGetOptions) =>
    useQuery([QueryKeys.OrderProductItems, { id: productId }], () => getProductOrders(productId), options);

export const useAddCustomerAlert = () =>
    useMutation(addCustomerAlert, {
        onSuccess: (order: IOrder) => {
            queryClient.invalidateQueries([QueryKeys.OrderItem, { id: order.id }]);
        }
    });

export const useRemoveCustomerAlert = () =>
    useMutation(removeCustomerAlert, {
        onSuccess: (order: IOrder) => {
            queryClient.invalidateQueries([QueryKeys.OrderItem, { id: order.id }]);
        }
    });

export const useUpdateCarrier = (orderId: number) =>
    useMutation((carrierId: number) => updateCarrier(orderId, carrierId));

export const useConfirmPickUpOrder = () =>
    useMutation(confirmPickUpOrder, {
        onSuccess: () => {
            // return order;
        }
    });

export const useGetFailedOrders = (domainId: number) =>
    useQuery<IFailedOrder[], Error>(QueryKeys.FailedOrders, () => getFailedOrders(domainId));

export const useDeleteFailedOrder = () =>
    useMutation(deleteFailedOrder, { onSuccess: () => queryClient.invalidateQueries(QueryKeys.FailedOrders) });

export const useReSendOrderConfirmation = () => useMutation(OrderResendInvoice);

export const useGetOrderTransportStatus = (orderId: number) =>
    useQuery<number>([QueryKeys.OrderTransportStatus, { id: orderId }], () => getOrderTransportStatus(orderId), {
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKeys.OrderItem, { id: orderId }]);
        }
    });
