import { TypographyH3 } from "@/components/custom/text/TypographyH3";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { ChartContainer, ChartTooltip, ChartTooltipContent } from "@/components/ui/chart";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { orderTypes } from "@/order";
import { OrderStatus } from "@/order/constans/OrderStatus";
import { Fragment } from "react";
import CurrencyFormat from "react-currency-format";
import { Bar, BarChart, CartesianGrid, LabelList, XAxis } from "recharts";
import { IProduct, IProductStockInformation } from "../types";
import { ProductPieChart } from "./ProductPieChart";

interface IProductDashboardProps {
    product: IProduct;
    orders: orderTypes.IOrder[];
    stockInformation: IProductStockInformation;
}

// Utility function to format order status
const formatOrderStatus = (status: any) => {
    return status.replace(/([a-z])([A-Z])/g, "$1 $2");
};

export const ProductDashboard = ({ product, orders, stockInformation }: IProductDashboardProps) => {
    const orderProductCount = orders.reduce((acc: number, order: orderTypes.IOrder) => {
        const orderProduct = order.products.find(op => op.product_id === product.id);
        if (orderProduct) {
            return acc + orderProduct.quantity;
        }

        return acc;
    }, 0);

    const getLast12Months = () => {
        const months = [];
        const date = new Date();
        for (let i = 0; i < 12; i++) {
            months.push(Intl.DateTimeFormat("nl-NL", { month: "short", year: "numeric" }).format(date));
            date.setMonth(date.getMonth() - 1);
        }
        return months.reverse();
    };

    const allMonths = getLast12Months();

    const salesPerMonth = orders
        .flatMap(order =>
            order.products
                .filter(orderProduct => orderProduct.product_id === product.id)
                .map(orderProduct => ({
                    month: Intl.DateTimeFormat("nl-NL", {
                        month: "short",
                        year: "numeric"
                    }).format(new Date(order.created_at)),
                    quantity: orderProduct.quantity
                }))
        )
        .reduce((acc: Record<string, number>, { month, quantity }) => {
            acc[month] = (acc[month] || 0) + quantity;
            return acc;
        }, {} as Record<string, number>);

    const salesPerMonthArray = allMonths.map(month => ({
        month,
        quantity: salesPerMonth[month] || 0
    }));

    const chartConfig = {
        quantity: {
            label: "Quantity",
            color: "hsl(var(--chart-1))"
        }
    };

    return (
        <ScrollArea>
            <div className="grid grid-cols-4 gap-x-4">
                {product?.media[0]?.preview_url && (
                    <Fragment>
                        <img
                            src={product?.media[0]?.preview_url || ""}
                            alt="product"
                            className="w-32 h-32 object-cover rounded-xl border p-2"
                        />

                        <div className="col-span-3">
                            <TypographyH3 className="text-xl">{product?.name}</TypographyH3>
                            Purchase price:{" "}
                            <CurrencyFormat
                                value={product?.default_price / 100}
                                displayType="text"
                                decimalScale={2}
                                prefix="€"
                                decimalSeparator=","
                                fixedDecimalScale
                            />
                        </div>
                    </Fragment>
                )}
            </div>
            <Card className="mt-8">
                <CardHeader className="border-b p-2 text-muted-foreground">{product?.name}</CardHeader>
                <CardContent className="grid grid-cols-5 divide-x p-0 text-foreground">
                    <div className="p-3 py-3">
                        <TypographyH3 className="uppercase text-xs">Stock</TypographyH3>
                        <div>
                            <Badge className="text-xs font-light">{product.in_stock}</Badge>
                        </div>
                    </div>
                    <div className="p-3 py-3">
                        <TypographyH3 className="uppercase text-xs">Back log</TypographyH3>
                        <Popover>
                            <PopoverTrigger className="underline underline-offset-4 text-sm ">
                                {Object.values(stockInformation.back_log).reduce((acc, value) => acc + value, 0)}
                            </PopoverTrigger>
                            <PopoverContent className="py-2">
                                <Table>
                                    <TableHeader>
                                        <TableRow className="text-xs">
                                            <TableHead className="text-muted-foreground">Order status</TableHead>
                                            <TableHead className="text-muted-foreground">Qty</TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {Object.entries(stockInformation.back_log).map(([status, quantity]) => (
                                            <TableRow key={status}>
                                                <TableCell>
                                                    {formatOrderStatus(OrderStatus[status as keyof typeof OrderStatus])}
                                                </TableCell>
                                                <TableCell>{quantity}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </PopoverContent>
                        </Popover>
                    </div>
                    <div className="p-3 py-3">
                        <TypographyH3 className="uppercase text-xs">Reserved</TypographyH3>
                        <Popover>
                            <PopoverTrigger className="underline underline-offset-4 text-sm ">
                                {Object.values(stockInformation.reserved_in_stock).reduce(
                                    (acc, value) => acc + value,
                                    0
                                )}
                            </PopoverTrigger>
                            <PopoverContent className="py-2">
                                <Table>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHead className="text-muted-foreground">Order status</TableHead>
                                            <TableHead className="text-muted-foreground">Qty</TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {Object.entries(stockInformation.reserved_in_stock).map(
                                            ([status, quantity]) => (
                                                <TableRow key={status}>
                                                    <TableCell>
                                                        {formatOrderStatus(
                                                            OrderStatus[status as keyof typeof OrderStatus]
                                                        )}
                                                    </TableCell>
                                                    <TableCell>{quantity}</TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </PopoverContent>
                        </Popover>
                    </div>
                    <div className="p-3 py-3">
                        <TypographyH3 className="uppercase text-xs">Sold</TypographyH3>
                        <div>{orderProductCount}</div>
                    </div>

                    <div className="p-3 py-3">
                        <TypographyH3 className="uppercase text-xs">Identity</TypographyH3>
                        <div className="">E-win trading</div>
                    </div>
                </CardContent>
            </Card>

            <div className="flex flex-col gap-8">
                <Card className="flex flex-col mt-8" x-chunk="charts-01-chunk-1">
                    <CardHeader className="flex flex-row items-center gap-4 space-y-0 pb-8 [&>div]:flex-1">
                        <div>
                            <CardDescription>Total sales last year</CardDescription>
                            <CardTitle className="flex items-baseline gap-1 text-4xl tabular-nums">
                                31
                                <span className="text-sm font-normal tracking-normal text-muted-foreground">qty</span>
                            </CardTitle>
                        </div>
                    </CardHeader>
                    <CardContent className="flex flex-1 items-center">
                        <ChartContainer config={chartConfig} className="w-full h-48">
                            <BarChart
                                accessibilityLayer
                                data={salesPerMonthArray}
                                barSize={12}
                                margin={{
                                    top: 20
                                }}
                            >
                                <CartesianGrid vertical={false} />
                                <XAxis
                                    dataKey="month"
                                    tickLine={false}
                                    tickMargin={10}
                                    axisLine={false}
                                    tickFormatter={value => value.slice(0, 3)}
                                />
                                <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
                                <Bar dataKey="quantity" radius={8} fill="var(--color-quantity)">
                                    <LabelList position="top" offset={12} className="fill-foreground" fontSize={12} />
                                </Bar>
                            </BarChart>
                        </ChartContainer>
                    </CardContent>
                </Card>
                <div className="grid grid-cols-2 gap-4">
                    <ProductPieChart orders={orders} product={product} />
                </div>
            </div>
        </ScrollArea>
    );
};
