import { config } from "@/app/config";
import { DataTable } from "@/components/custom/table";
import { Button } from "@/components/ui/button";
import { ColumnDef } from "@tanstack/react-table";
import { IFailedOrder } from "../types";

interface IPurchaseOrderTableProps {
    failedOrders: IFailedOrder[];
    onClickFixedOrder: (id: number) => void;
}

export const FailedOrderTable = ({ failedOrders, onClickFixedOrder }: IPurchaseOrderTableProps) => {
    const columns: ColumnDef<IFailedOrder>[] = [
        {
            header: "ID",
            accessorKey: "id"
        },

        {
            header: "Channel",
            cell: ({ row }) => (
                <div className="flex gap-4 items-center">
                    <div className="flex -space-x-1">
                        <div className="bg-white rounded-full w-6 h-6 ring-2 ring-white">
                            <img
                                className="rounded-md w-6 h-6"
                                src={`${config.appUrl}/assets/img/channels/${row.original.channel.icon}`}
                                alt=""
                                width="28"
                                height="28"
                            />
                        </div>
                    </div>

                    <div className="flex flex-col">
                        <div className="text-xs whitespace-nowrap text-foreground">
                            {row.original.channel.company_name}
                        </div>
                    </div>
                </div>
            )
        },
        {
            header: "External order ID",
            cell: ({ row }) => <div>{row.original.external_order_id}</div>
        },
        {
            id: "actions",
            cell: ({ row }) => (
                <Button variant="outline" size="sm" onClick={() => onClickFixedOrder(row.original.id)}>
                    Fixed
                </Button>
            )
        }
    ];

    return <DataTable data={failedOrders} columns={columns} />;
};
