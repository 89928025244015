import { authHooks } from "@/auth";
import { Pagination } from "@/components/custom/pagination";
import { SearchField } from "@/components/custom/search/search";
import { TypographyH1 } from "@/components/custom/text/TypographyH1";
import { TypographyP } from "@/components/custom/text/TypographyP";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import Flex from "@/components/ui/flex";
import { identityHooks } from "@/identity";
import { cn } from "@/lib/utils";
import { supplierHooks } from "@/supplier";
import { useQueryBuilder } from "@cgarciagarcia/react-query-builder";
import { Invoice04Icon } from "@hugeicons/react";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DataTableFacetedFilter } from "../components/DataTableFacetedFilter";
import { PurchaseOrderTable } from "../components/PurchaseOrderTable";
import { PurchaseOrderStatus } from "../constans/PurchaseOrderStatus";
import { useDeletePurchaseOrder, useGetDomainPurchaseOrders } from "../hooks";
import { PurchaseOrderCreateSheet } from "./PurchaseOrderCreateSheet";
import { PurchaseOrderUpdateSheet } from "./PurchaseOrderUpdateSheet";

export const PurchaseOrderOverviewPage = () => {
    const { activeDomain } = authHooks.useAuth();
    const navigate = useNavigate();
    const { purchaseOrderStatus = PurchaseOrderStatus.Open, purchaseOrderId } = useParams();
    const [isPurchaseCreateSheetOpen, setIsPurchaseCreateSheetOpen] = useState<boolean>(false);

    const { mutate: deletePurchaseOrder } = useDeletePurchaseOrder();
    const builder = useQueryBuilder({
        filters: [
            {
                attribute: "status",
                value: [purchaseOrderStatus]
            }
        ],
        params: {
            page: [1],
            per_page: [100]
        }
    });
    const { data: purchaseOrder } = useGetDomainPurchaseOrders(activeDomain.id, builder);
    const { data: identities = [] } = identityHooks.useGetDomainIdentities(activeDomain.id);
    const { data: suppliers = [] } = supplierHooks.useGetDomainSuppliers(activeDomain.id, {
        enabled: !!activeDomain.id
    });
    const tabItems: Array<{ name: string; purchaseOrderStatus: PurchaseOrderStatus }> = [
        {
            name: "Open",
            purchaseOrderStatus: PurchaseOrderStatus.Open
        },
        {
            name: "Pending",
            purchaseOrderStatus: PurchaseOrderStatus.Pending
        },
        {
            name: "Completed",
            purchaseOrderStatus: PurchaseOrderStatus.Completed
        }
    ];

    const handleTabChange = (newPurchaseStatus: PurchaseOrderStatus) => {
        builder
            .removeFilter("status")
            .filter("status", newPurchaseStatus)
            .setParam("page", [1])
            .build();

        navigate(`/purchase-orders/${newPurchaseStatus}`);
    };

    const handleViewPurchaseOrder = (id: number) => {
        navigate(`/purchase-orders/${purchaseOrderStatus}/${id}`);
    };

    const handleCloseViewPurchaseOrder = () => {
        navigate(`/purchase-orders/${purchaseOrderStatus}`);
    };

    const handleDeletePurchaseOrder = () => {
        if (purchaseOrderId) {
            deletePurchaseOrder(Number(purchaseOrderId));
            handleCloseViewPurchaseOrder();
        }
    };

    return (
        <Fragment>
            <TypographyH1>Purchase Orders</TypographyH1>
            <TypographyP>Here you can see all purchase orders for this domain.</TypographyP>

            <Flex
                alignItems="end"
                justifyContent="start"
                className={cn("mb-0 text-foreground/80 my-8 w-full border-b h-7")}
            >
                {tabItems.map((tabItem, index) => (
                    <div
                        key={index}
                        onClick={() => handleTabChange(tabItem.purchaseOrderStatus)}
                        className={cn(
                            "flex h-7 items-center justify-center px-4 text-center text-sm transition-colors hover:text-primary cursor-pointer",
                            tabItem.purchaseOrderStatus === purchaseOrderStatus
                                ? "font-medium text-primary border-b border-primary"
                                : "text-muted-foreground"
                        )}
                    >
                        <span className="mr-3">{tabItem.name}</span>
                    </div>
                ))}
            </Flex>

            <Card className="overflow-hidden rounded-md p-0 shadow-3xl">
                <CardHeader>
                    <Flex justifyContent="end">
                        <div className="flex gap-x-4">
                            <DataTableFacetedFilter
                                title="Idenity"
                                options={identities.map(identity => ({
                                    label: identity.name,
                                    value: identity.id.toString()
                                }))}
                                onChange={values => builder.removeFilter("identity_id").filter("identity_id", values)}
                            />
                            <DataTableFacetedFilter
                                title="Supplier"
                                options={suppliers.map(supplier => ({
                                    label: supplier.company,
                                    value: supplier.id.toString()
                                }))}
                                onChange={values => builder.removeFilter("supplier_id").filter("supplier_id", values)}
                            />
                            <Button
                                icon={Invoice04Icon}
                                variant="outline"
                                onClick={() => setIsPurchaseCreateSheetOpen(true)}
                            >
                                New PO
                            </Button>
                            <SearchField onChange={sting => builder.setParam("search", sting).build()} />
                        </div>
                    </Flex>
                </CardHeader>
                <CardContent className="p-0">
                    <PurchaseOrderTable
                        orders={purchaseOrder?.data || []}
                        onViewPurchaseOrderClick={handleViewPurchaseOrder}
                    />
                </CardContent>
            </Card>

            {purchaseOrder?.meta && (
                <Pagination
                    className="mt-4"
                    meta={purchaseOrder.meta}
                    onPageChange={page => builder.removeParam("page").setParam("page", page)}
                />
            )}

            <PurchaseOrderCreateSheet
                open={isPurchaseCreateSheetOpen}
                onClose={() => setIsPurchaseCreateSheetOpen(false)}
            />
            <PurchaseOrderUpdateSheet
                onClose={handleCloseViewPurchaseOrder}
                onDelete={handleDeletePurchaseOrder}
                open={!!purchaseOrderId}
                purchaseOrderId={purchaseOrderId ? Number(purchaseOrderId) : 0}
            />
        </Fragment>
    );
};
