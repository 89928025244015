import { useAuth } from "@/auth/hooks";
import { carrierHooks, carrierTypes } from "@/carrier";
import { MoneyInput } from "@/components/custom/moneyInput/MoneyInput";
import { Button } from "@/components/ui/button";
import Flex from "@/components/ui/flex";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { Input } from "@/components/ui/input";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue
} from "@/components/ui/select";
import { useGetDomainIdentities } from "@/identity/hooks";
import { cn } from "@/lib/utils";
import { InformationCircleIcon } from "@hugeicons/react";
import { useForm } from "react-hook-form";
import { ProductType } from "../constans/ProductType";
import { useUpdateProduct } from "../hooks";
import { IProduct } from "../types";

export interface IProductFormProps {
    item: IProduct | undefined;
}

export function ProductForm({ item }: IProductFormProps) {
    const { activeDomain } = useAuth();
    const { mutateAsync: updateProduct } = useUpdateProduct();
    const { data: identities } = useGetDomainIdentities(activeDomain.id);
    const { data: carriers } = carrierHooks.useGetDomainCarriers(activeDomain.id, 1);
    const form = useForm<IProduct>({
        defaultValues: item
    });

    const productTypes = [
        { value: ProductType.PHYSICAL, label: ProductType.PHYSICAL },
        { value: ProductType.DIGITAL, label: ProductType.DIGITAL }
    ];

    const handleSubmit = async (data: IProduct) => {
        try {
            await updateProduct(data);
        } catch (error) {
            const err = error as { response: { data: { errors: Record<string, string[]> } } };

            if (err.response && err.response.data && err.response.data.errors) {
                const serverErrors = err.response.data.errors;
                Object.keys(serverErrors).forEach(field => {
                    form.setError(field as keyof IProduct, {
                        type: "server",
                        message: serverErrors[field][0]
                    });
                });
            }
        }
    };

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} className="mb-8">
                <div className="grid grid-cols-2 gap-4">
                    <FormField
                        control={form.control}
                        name="name"
                        render={({ field }) => (
                            <FormItem className="col-span-2">
                                <FormLabel>Name</FormLabel>
                                <FormControl>
                                    <Input placeholder="Name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="default_price"
                        render={({ field }) => (
                            <FormItem className="col-span-1">
                                <FormLabel>Default price</FormLabel>
                                <FormControl>
                                    <MoneyInput placeholder="1000.00" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="purchase_price"
                        render={({ field }) => (
                            <FormItem className="col-span-1">
                                <FormLabel>Purchase price (value in cents)</FormLabel>
                                <FormControl>
                                    <MoneyInput placeholder="100000" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="default_carrier_id"
                        render={({ field }) => (
                            <FormItem className="col-span-1">
                                <FormLabel>Default carrier</FormLabel>
                                <FormControl>
                                    {/* @ts-ignore */}
                                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                                        <FormControl>
                                            <SelectTrigger className={cn("shadow-sm", !field.value && "")}>
                                                <SelectValue placeholder="Select default carrier" />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            <SelectGroup>
                                                <SelectLabel>Default carrier</SelectLabel>
                                                {(carriers?.data || []).map((carrier: carrierTypes.ICarrier) => (
                                                    <SelectItem value={carrier.id.toString()} key={carrier.id}>
                                                        {carrier.name}
                                                    </SelectItem>
                                                ))}
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="type"
                        render={({ field }) => (
                            <FormItem className="col-span-1">
                                <FormLabel>
                                    <HoverCard>
                                        <HoverCardTrigger className="inline-flex items-center gap-x-1">
                                            Type of product <InformationCircleIcon className="w-4 h-4" />
                                        </HoverCardTrigger>
                                        <HoverCardContent>Digital product will not be shipped.</HoverCardContent>
                                    </HoverCard>{" "}
                                </FormLabel>
                                <FormControl>
                                    {/* @ts-ignore */}
                                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                                        <FormControl>
                                            <SelectTrigger className={cn("shadow-sm", !field.value && "")}>
                                                <SelectValue placeholder="Select type" />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            <SelectGroup>
                                                <SelectLabel>Type of product</SelectLabel>
                                                {productTypes.map(productType => (
                                                    <SelectItem value={productType.value} key={productType.value}>
                                                        {productType.label.toUpperCase()}
                                                    </SelectItem>
                                                ))}
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="identity_id"
                        render={({ field }) => (
                            <FormItem className="col-span-1">
                                <FormLabel>Identity</FormLabel>
                                <FormControl>
                                    {/* @ts-ignore */}
                                    <Select onValueChange={field.onChange} defaultValue={field.value?.toString()}>
                                        <FormControl>
                                            <SelectTrigger className={cn("shadow-sm", !field.value && "")}>
                                                <SelectValue placeholder="Select identity" />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            <SelectGroup>
                                                <SelectLabel>Type of product</SelectLabel>
                                                {(identities || []).map(identity => (
                                                    <SelectItem
                                                        value={identity.id.toString()}
                                                        key={identity.id.toString()}
                                                    >
                                                        {identity.name}
                                                    </SelectItem>
                                                ))}
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <Flex justifyContent="end" className="mt-4">
                    <Button type="submit">Submit</Button>
                </Flex>
            </form>
        </Form>
    );
}
