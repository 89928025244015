import { Bar, BarChart, CartesianGrid, PolarAngleAxis, RadialBar, RadialBarChart, XAxis, YAxis } from "recharts";

import { useAuth } from "@/auth/hooks";
import { Card, CardContent } from "@/components//ui/card";
import { ChartContainer, ChartTooltip, ChartTooltipContent } from "@/components//ui/chart";
import { TypographyH1 } from "@/components/custom/text/TypographyH1";
import { Badge } from "@/components/ui/badge";
import Flex from "@/components/ui/flex";
import { productHooks } from "@/product";
import { statisticHooks } from "@/statistic";
import { FilterOperator, useQueryBuilder } from "@cgarciagarcia/react-query-builder";
import { useEffect, useState } from "react";
import { OutOfStockProducts } from "../components/OutOfStockProducts";

export function DashboardPage() {
    const [totalOrderCount, setTotalOrderCount] = useState<number>(0);
    const { activeDomain } = useAuth();
    const queryBuilder = useQueryBuilder({
        filters: [
            {
                attribute: "in_stock",
                value: [0],
                operator: FilterOperator.LessThanOrEqual
            }
        ],
        params: {
            page: [1]
        }
    });
    const { data: products } = productHooks.useGetProducts(activeDomain, queryBuilder);
    const { data: ordersByDay = [] } = statisticHooks.useGetOrdersByDay(activeDomain.id, "2024-12-17");

    useEffect(() => {
        if (ordersByDay.length > 0) {
            setTotalOrderCount(ordersByDay.reduce((acc, curr) => acc + curr.count, 0));
        }
    }, [ordersByDay]);

    return (
        <div className="chart-wrapper mx-auto flex flex-col flex-wrap items-start justify-center gap-6 sm:flex-row">
            <div className="grid w-full gap-6 sm:grid-cols-2  lg:grid-cols-1">
                <Card className="h-96" x-chunk="charts-01-chunk-1">
                    <CardContent className="flex flex-1 items-center pt-12" style={{ maxHeight: "400px" }}>
                        <div className="w-full h-full grid grid-cols-12 gap-x-16">
                            <div className="col-span-3 h-full flex items-center justify-left">
                                <Flex flexDirection="col" alignItems="start" className="gap-y-2">
                                    <TypographyH1>Orders last 30 days</TypographyH1>
                                    <div>total count: {Intl.NumberFormat("nl-NL").format(totalOrderCount)}</div>
                                    <div className="text-muted-foreground text-sm flex gap-x-2">
                                        <Badge className=" text-white ">25%+</Badge> Compared to previous month
                                    </div>
                                </Flex>
                            </div>
                            <div className="col-span-9">
                                <ChartContainer
                                    config={{
                                        resting: {
                                            label: "Resting",
                                            color: "hsl(var(--chart-2))"
                                        }
                                    }}
                                    className="w-full"
                                    style={{ maxHeight: "300px" }}
                                >
                                    <BarChart
                                        accessibilityLayer
                                        height={100}
                                        margin={{
                                            left: 14,
                                            right: 14,
                                            top: 10
                                        }}
                                        data={ordersByDay}
                                    >
                                        <CartesianGrid
                                            strokeDasharray="4 4"
                                            vertical={false}
                                            // stroke="hsl(var(--muted-foreground))"
                                            strokeOpacity={0.5}
                                        />
                                        <YAxis hide domain={["dataMin - 10", "dataMax + 10"]} />
                                        <XAxis
                                            dataKey="date"
                                            tickLine={false}
                                            axisLine={false}
                                            tickFormatter={value => {
                                                return new Date(value).toLocaleDateString("nl-NL", {
                                                    day: "2-digit",
                                                    month: "2-digit"
                                                });
                                            }}
                                        />
                                        <Bar
                                            dataKey="count"
                                            type="natural"
                                            fill="var(--color-resting)"
                                            stroke="var(--color-resting)"
                                            strokeWidth={2}
                                        />
                                        <ChartTooltip
                                            content={
                                                <ChartTooltipContent
                                                    indicator="line"
                                                    labelFormatter={value => {
                                                        return new Date(value).toLocaleDateString("nl-NL", {
                                                            day: "2-digit",
                                                            month: "2-digit"
                                                        });
                                                    }}
                                                />
                                            }
                                            cursor={false}
                                        />
                                    </BarChart>
                                </ChartContainer>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>
            <div className="grid w-full flex-1 gap-6">
                <OutOfStockProducts products={products?.data ?? []} />
            </div>
            <div className="grid w-full flex-1 gap-6">
                <Card className="max-w-md" x-chunk="charts-01-chunk-5">
                    <CardContent className="flex gap-4 p-4">
                        <div className="grid items-center gap-2">
                            <div className="grid flex-1 auto-rows-min gap-0.5">
                                <div className="text-sm text-muted-foreground">Move</div>
                                <div className="flex items-baseline gap-1 text-xl font-bold tabular-nums leading-none">
                                    562/600
                                    <span className="text-sm font-normal text-muted-foreground">kcal</span>
                                </div>
                            </div>
                            <div className="grid flex-1 auto-rows-min gap-0.5">
                                <div className="text-sm text-muted-foreground">Exercise</div>
                                <div className="flex items-baseline gap-1 text-xl font-bold tabular-nums leading-none">
                                    73/120
                                    <span className="text-sm font-normal text-muted-foreground">min</span>
                                </div>
                            </div>
                            <div className="grid flex-1 auto-rows-min gap-0.5">
                                <div className="text-sm text-muted-foreground">Stand</div>
                                <div className="flex items-baseline gap-1 text-xl font-bold tabular-nums leading-none">
                                    8/12
                                    <span className="text-sm font-normal text-muted-foreground">hr</span>
                                </div>
                            </div>
                        </div>
                        <ChartContainer
                            config={{
                                move: {
                                    label: "Move",
                                    color: "hsl(var(--chart-1))"
                                },
                                exercise: {
                                    label: "Exercise",
                                    color: "hsl(var(--chart-2))"
                                },
                                stand: {
                                    label: "Stand",
                                    color: "hsl(var(--chart-3))"
                                }
                            }}
                            className="mx-auto aspect-square w-full max-w-[80%]"
                        >
                            <RadialBarChart
                                margin={{
                                    left: -10,
                                    right: -10,
                                    top: -10,
                                    bottom: -10
                                }}
                                data={[
                                    {
                                        activity: "stand",
                                        value: (8 / 12) * 100,
                                        fill: "var(--color-stand)"
                                    },
                                    {
                                        activity: "exercise",
                                        value: (46 / 60) * 100,
                                        fill: "var(--color-exercise)"
                                    },
                                    {
                                        activity: "move",
                                        value: (245 / 360) * 100,
                                        fill: "var(--color-move)"
                                    }
                                ]}
                                innerRadius="20%"
                                barSize={24}
                                startAngle={90}
                                endAngle={450}
                            >
                                <PolarAngleAxis type="number" domain={[0, 100]} dataKey="value" tick={false} />
                                <RadialBar dataKey="value" background cornerRadius={5} />
                            </RadialBarChart>
                        </ChartContainer>
                    </CardContent>
                </Card>
                {/* <Card className="max-w-md" x-chunk="charts-01-chunk-6">
                    <CardHeader className="p-4 pb-0">
                        <CardTitle>Active Energy</CardTitle>
                        <CardDescription>You're burning an average of 754 calories per day. Good job!</CardDescription>
                    </CardHeader>
                    <CardContent className="flex flex-row items-baseline gap-4 p-4 pt-2">
                        <div className="flex items-baseline gap-2 text-3xl font-bold tabular-nums leading-none">
                            1,254
                            <span className="text-sm font-normal text-muted-foreground">kcal/day</span>
                        </div>
                    </CardContent>
                </Card> */}
                {/* <Card className="max-w-md" x-chunk="charts-01-chunk-7">
          <CardHeader className="space-y-0 pb-0">
            <CardDescription>Time in Bed</CardDescription>
            <CardTitle className="flex items-baseline gap-1 text-4xl tabular-nums">
              8
              <span className="font-sans text-sm font-normal tracking-normal text-muted-foreground">
                hr
              </span>
              35
              <span className="font-sans text-sm font-normal tracking-normal text-muted-foreground">
                min
              </span>
            </CardTitle>
          </CardHeader>
          <CardContent className="p-0">
            <ChartContainer
              config={{
                time: {
                  label: "Time",
                  color: "hsl(var(--chart-2))",
                },
              }}
            >
              <AreaChart
                accessibilityLayer
                data={[
                  {
                    date: "2024-01-01",
                    time: 8.5,
                  },
                  {
                    date: "2024-01-02",
                    time: 7.2,
                  },
                  {
                    date: "2024-01-03",
                    time: 8.1,
                  },
                  {
                    date: "2024-01-04",
                    time: 6.2,
                  },
                  {
                    date: "2024-01-05",
                    time: 5.2,
                  },
                  {
                    date: "2024-01-06",
                    time: 8.1,
                  },
                  {
                    date: "2024-01-07",
                    time: 7.0,
                  },
                ]}
                margin={{
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                }}
              >
                <XAxis dataKey="date" hide />
                <YAxis domain={["dataMin - 5", "dataMax + 2"]} hide />
                <defs>
                  <linearGradient id="fillTime" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="5%"
                      stopColor="var(--color-time)"
                      stopOpacity={0.8}
                    />
                    <stop
                      offset="95%"
                      stopColor="var(--color-time)"
                      stopOpacity={0.1}
                    />
                  </linearGradient>
                </defs>
                <Area
                  dataKey="time"
                  type="natural"
                  fill="url(#fillTime)"
                  fillOpacity={0.4}
                  stroke="var(--color-time)"
                />
                <ChartTooltip
                  cursor={false}
                  content={<ChartTooltipContent hideLabel />}
                  formatter={(value) => (
                    <div className="flex min-w-[120px] items-center text-xs text-muted-foreground">
                      Time in bed
                      <div className="ml-auto flex items-baseline gap-0.5 font-mono font-medium tabular-nums text-foreground">
                        {value}
                        <span className="font-normal text-muted-foreground">
                          hr
                        </span>
                      </div>
                    </div>
                  )}
                />
              </AreaChart>
            </ChartContainer>
          </CardContent>
        </Card> */}
            </div>
        </div>
    );
}
