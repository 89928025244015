import { QueryClient } from "react-query";
import { toast } from "sonner";

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            useErrorBoundary: false,
            refetchOnWindowFocus: false,
            onError: (error: any) => {
                toast.error(error.message);
            }
        }
    }
});
