import { config } from "@/app";
import { MoneyFormatter } from "@/common";
import { TypographyH1 } from "@/components/custom/text/TypographyH1";
import { TypographyP } from "@/components/custom/text/TypographyP";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import Flex from "@/components/ui/flex";
import { OrderStatusBadge } from "@/order/components/OrderStatusBadge";
import { useGetProductOrders } from "@/order/hooks";
import { ViewOffIcon } from "@hugeicons/react";
import { Fragment } from "react";
import { IProduct } from "../types";

interface IProductOrdersProps {
    product: IProduct;
    onClickShowOrder: (orderId: number) => void;
}
export const ProductOrders = ({ product, onClickShowOrder }: IProductOrdersProps) => {
    const { data: orders } = useGetProductOrders(product.id);

    return !orders?.length ? (
        <Flex className="w-full h-full relative" justifyContent="center" alignItems="center">
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-2/3 w-full h-full">
                <svg viewBox="0 0 768 768" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask
                        id="mask0_5022_372273"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="768"
                        height="768"
                    >
                        <rect width="768" height="768" fill="url(#paint0_radial_5022_372273)" />
                    </mask>
                    <g mask="url(#mask0_5022_372273)">
                        <circle cx="384" cy="384" r="47.5" stroke="#EAECF0" />
                        <circle cx="384" cy="384" r="95.5" stroke="#EAECF0" />
                        <circle cx="384" cy="384" r="143.5" stroke="#EAECF0" />
                        <circle cx="384" cy="384" r="191.5" stroke="#EAECF0" />
                        <circle cx="384" cy="384" r="239.5" stroke="#EAECF0" />
                        <circle cx="384" cy="384" r="287.5" stroke="#EAECF0" />
                        <circle cx="384" cy="384" r="335.5" stroke="#EAECF0" />
                        <circle cx="384" cy="384" r="383.5" stroke="#EAECF0" />
                    </g>
                    <defs>
                        <radialGradient
                            id="paint0_radial_5022_372273"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(384 384) rotate(90) scale(384 384)"
                        >
                            <stop />
                            <stop offset="1" stop-opacity="0" />
                        </radialGradient>
                    </defs>
                </svg>
            </div>

            <Flex flexDirection="col" alignItems="center" className="gap-y-2 -mt-10 z-[10]">
                <ViewOffIcon className="w-16 h-16 text-muted-foreground" />
                <TypographyH1 className="text-muted-foreground">No orders found.</TypographyH1>
                <TypographyP className="text-muted-foreground mt-2 ">
                    No related orders where found for this product.
                </TypographyP>
            </Flex>
        </Flex>
    ) : (
        <Fragment>
            <TypographyH1 className="sm:text-lg lg:text-3xl text-foreground font-semibold mb-0">Orders</TypographyH1>
            <TypographyP className="text-muted-foreground mt-2 mb-8">
                Here you can see all orders for this product.
            </TypographyP>
            <div className="pb-8">
                {orders?.map(order => (
                    <div key={order.id} className="pl-2 ">
                        <div className="border-l pl-6 pt-8 relative" key={order.id}>
                            <div className="absolute top-16 left-0 w-4 h-4 -translate-x-1/2 -translate-y-1/2 z-10 bg-primary rounded-full bg-slate-300" />
                            <Card>
                                <CardContent className="py-2 px-4">
                                    <Flex justifyContent="start" alignItems="start" className="gap-x-4">
                                        <Flex flexDirection="col" className="text-center grow-0 w-12">
                                            <time className="uppercase text-xs text-muted-foreground">
                                                {Intl.DateTimeFormat("nl-NL", {
                                                    month: "short"
                                                }).format(new Date(order.created_at))}
                                            </time>
                                            <p className="font-bold">
                                                {" "}
                                                {new Date(order.created_at)
                                                    .toLocaleString("nl-NL", { day: "2-digit" })
                                                    .toUpperCase()}
                                            </p>
                                        </Flex>
                                        <Flex justifyContent="start" alignItems="start" className="gap-x-8">
                                            <Flex flexDirection="col" alignItems="start" className="gap-y-2">
                                                <p className="text-xs uppercase text-muted-foreground">
                                                    Customer details
                                                </p>
                                                <Flex flexDirection="col" alignItems="start" className="gap-y-1.5">
                                                    <p className="text-sm">
                                                        {order.customer?.shipmentAddress?.full_name}
                                                    </p>
                                                    <p className="text-sm">
                                                        {order.customer?.shipmentAddress?.fullStreetDetails}
                                                    </p>
                                                    <p className="relative text-sm">
                                                        <img
                                                            src={`${config.appUrl}/assets/img/flags/${order.customer?.shipmentAddress?.country_iso_3166_2}.svg`}
                                                            alt=""
                                                            className="w-3 h-3 rounded-full object-cover absolute -right-4 top-1.5"
                                                        />
                                                        {order.customer?.shipmentAddress?.zip_code}{" "}
                                                        {order.customer?.shipmentAddress?.city}
                                                    </p>
                                                </Flex>
                                            </Flex>
                                            <Flex flexDirection="col" alignItems="start" className="gap-y-2">
                                                <p className="text-xs uppercase text-muted-foreground">
                                                    Total order value
                                                </p>

                                                <p className="text-sm">
                                                    {MoneyFormatter(
                                                        order.products.reduce((acc, curr) => acc + curr.price || 0, 0)
                                                    )}
                                                </p>

                                                <p className="text-xs uppercase text-muted-foreground">Qty ordered</p>
                                                <p className="text-sm">
                                                    {order.products
                                                        ?.filter(orderProduct => orderProduct.product_id === product.id)
                                                        ?.reduce((acc, orderProduct) => acc + orderProduct.quantity, 0)}
                                                </p>
                                            </Flex>
                                            <Flex
                                                flexDirection="col"
                                                alignItems="start"
                                                className="gap-y-2 grow-0 w-auto"
                                            >
                                                <OrderStatusBadge status={order.status} />
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </CardContent>
                                <CardFooter className="bg-muted p-3 px-4 text-xs text-muted-foreground">
                                    <Flex>
                                        <Flex justifyContent="start" alignItems="center">
                                            <img
                                                className="rounded-full w-4 h-4 mr-2"
                                                src={`${config.appUrl}/assets/img/channels/${order?.channel?.icon}`}
                                                alt=""
                                                width="18"
                                                height="18"
                                            />
                                            {order.channel.company_name}
                                        </Flex>
                                        <Button variant="ghost" size="xs" onClick={() => onClickShowOrder(order.id)}>
                                            {`#${order.id}`} - {order.external_order_id}
                                        </Button>
                                    </Flex>
                                </CardFooter>
                            </Card>
                        </div>
                    </div>
                ))}
            </div>
        </Fragment>
    );
};
