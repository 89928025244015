import { axios } from "@/app";
import { domainTypes } from "@/domain";
import { QueryBuilder } from "@cgarciagarcia/react-query-builder";
import { IChannel, IChannelResponse } from "./types";

export const getChannels = async (domain: domainTypes.IDomain, queryBuilder?: QueryBuilder): Promise<IChannel[]> => {
    const queryString = queryBuilder ? `?${queryBuilder.build()}` : "";
    const response = await axios.get<IChannelResponse>(`domains/${domain.id}/channels${queryString}`);

    return response.data.data;
};

export const getChannel = async (id: number): Promise<IChannel> => {
    const response = await axios.get<{ data: IChannel }>(`channels/${id}`);

    return response.data.data;
};

export const createChannel = async (domain: domainTypes.IDomain, channel: IChannel): Promise<IChannel> => {
    const response = await axios.post<IChannel>(`domains/${domain.id}/channels`, channel);

    return response.data;
};

export const updateChannel = async (channel: IChannel): Promise<IChannel> => {
    const response = await axios.put<IChannel>(`channels/${channel.id}`, channel);

    return response.data;
};
