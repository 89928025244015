import { channelTypes } from "@/channel";
import { commonTypes } from "@/common";
import { domainTypes } from "@/domain";
import { QueryBuilder } from "@cgarciagarcia/react-query-builder";
import { useMutation, useQuery } from "react-query";
import { QueryKeys } from "./constants/QueryKeys";
import { createChannel, getChannel, getChannels, updateChannel } from "./queries";
import { IChannel } from "./types";

export const useGetChannels = (
    domain: domainTypes.IDomain,
    queryBuilder?: QueryBuilder,
    options?: commonTypes.IUseGetOptions
) =>
    useQuery(
        [QueryKeys.ChannelItems, { domainId: domain.id, ...queryBuilder?.toArray() }],
        () => getChannels(domain, queryBuilder),
        options
    );

export const useGetChannel = (id: number, options?: commonTypes.IUseGetOptions) =>
    useQuery<IChannel, Error>([QueryKeys.ChannelItem, { id }], () => getChannel(id), options);

export const useCreateChannel = (domain: domainTypes.IDomain) =>
    useMutation((channel: channelTypes.IChannel) => createChannel(domain, channel));

export const useUpdateChannel = () => useMutation(updateChannel);
