import { Button } from "@/components/ui/button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import Flex from "@/components/ui/flex";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import { domainTypes } from "@/domain";
import { identityTypes } from "@/identity";
import { cn } from "@/lib/utils";
import { productHooks } from "@/product";
import { supplierTypes } from "@/supplier";
import { warehouseTypes } from "@/warehouse";
import { Add01Icon, Delete04Icon, EuroIcon, PlusSignIcon } from "@hugeicons/react";

import MediaLibraryCollection from "@/components/custom/media-library-pro-react-collection/MediaLibraryCollection";
import { MoneyInput } from "@/components/custom/moneyInput/MoneyInput";
import { IProductWarehouse } from "@/product/types";
import { useQueryBuilder } from "@cgarciagarcia/react-query-builder";
import { Check, ChevronsUpDown } from "lucide-react";
import { Fragment, useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useFieldArray, useForm } from "react-hook-form";
import { IPurchaseOrder } from "../types";
import { AsyncProductSelect } from "./AsyncProductSelect";

interface IPurchaseOrderFormProps {
    handleAddSupplier: () => void;
    domain: domainTypes.IDomain;
    suppliers: supplierTypes.ISupplier[];
    warehouses: warehouseTypes.IWarehouse[];
    identities: identityTypes.IIdentity[];
    supplierId: number | null;
    onSubmit: (data: IPurchaseOrder) => void;
}

export const PurchaseOrderForm = ({
    domain,
    suppliers,
    warehouses,
    identities,
    handleAddSupplier,
    supplierId,
    onSubmit
}: IPurchaseOrderFormProps) => {
    const queryBuilder = useQueryBuilder({
        params: {
            page: [1]
        }
    });
    const [identityId, setIdentityId] = useState<number | null>(null);
    const [productSearchQuery, setProductSearchQuery] = useState<string>("");
    const [supplierCommandOpen, setSupplierCommandOpen] = useState<boolean>(false);
    const [totalValue, setTotalValue] = useState<number>(0);
    const { data: products, isLoading: isProductLoading } = productHooks.useGetProducts(domain, queryBuilder, {
        enabled: !!productSearchQuery
    });

    const { control, reset, ...form } = useForm<IPurchaseOrder>({
        defaultValues: {
            productPurchaseOrder: [{}]
        }
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "productPurchaseOrder"
    });

    useEffect(() => {
        const data = form.watch();
        if (data.identity) {
            setIdentityId(Number(data.identity.id));
        }
    }, [form.watch()]);

    useEffect(() => {
        const data = form.watch();

        reset({
            ...data,
            supplier_id: supplierId
        });
    }, [supplierId]);

    const calculateNewPurchasePrice = (index: number): number => {
        const { price = 0, qty = 0, product } = form.watch(`productPurchaseOrder.${index}`);
        const totalInStock =
            (!!product?.productWarehouses &&
                product.productWarehouses.reduce((acc: number, warehouse: IProductWarehouse) => {
                    return acc + warehouse.in_stock;
                }, 0)) ||
            0;
        const newPurchasePrice = (price * qty + product?.purchase_price * totalInStock) / (totalInStock + qty);

        return newPurchasePrice;
    };

    useEffect(() => {
        if (form.watch("productPurchaseOrder")) {
            const value = form.watch("productPurchaseOrder").reduce((acc: number, product: any) => {
                if (product.price && product.qty) {
                    return acc + product.price * product.qty;
                }

                return acc;
            }, 0);

            setTotalValue(value);
        }
    }, [form.watch()]);

    return (
        <Form {...form} reset={reset} watch={form.watch} control={control}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="h-full mb-8">
                <Flex flexDirection="col" justifyContent="between" className="h-full py-1">
                    <div className="grow overflow-scroll px-8">
                        <div className="grid grid-cols-8">
                            <div className="col-span-8 grid-cols-8 grid gap-4">
                                <FormField
                                    control={control}
                                    name="identity.id"
                                    render={({ field }) => (
                                        <FormItem className="col-span-4">
                                            <FormLabel>Select the company identity</FormLabel>
                                            {/* @ts-ignore */}
                                            <Select onValueChange={field.onChange} defaultValue={field.value as string}>
                                                <FormControl>
                                                    <SelectTrigger
                                                        className={cn(
                                                            "shadow-sm",
                                                            !field.value && "text-muted-foreground"
                                                        )}
                                                    >
                                                        <SelectValue placeholder="Identity" />
                                                    </SelectTrigger>
                                                </FormControl>
                                                <SelectContent>
                                                    {identities.map((identity: identityTypes.IIdentity) => (
                                                        <SelectItem value={identity.id.toString()} key={identity.id}>
                                                            {identity.name}
                                                        </SelectItem>
                                                    ))}
                                                </SelectContent>
                                            </Select>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={control}
                                    name="supplier_id"
                                    render={({ field }) => (
                                        <FormItem className="flex-col  col-span-4">
                                            <FormLabel>Select supplier</FormLabel>
                                            <Popover open={supplierCommandOpen} onOpenChange={setSupplierCommandOpen}>
                                                <PopoverTrigger asChild>
                                                    <FormControl>
                                                        <Button
                                                            variant="outline"
                                                            role="combobox"
                                                            className={cn(
                                                                "w-full justify-between overflow-hidden",
                                                                !field.value && "text-muted-foreground"
                                                            )}
                                                        >
                                                            {field.value
                                                                ? suppliers.find(
                                                                      supplier => supplier.id === field.value
                                                                  )?.company
                                                                : "Supplier"}
                                                            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                                        </Button>
                                                    </FormControl>
                                                </PopoverTrigger>
                                                <PopoverContent className="w-full p-0">
                                                    <Command>
                                                        <CommandInput
                                                            onChangeCapture={tet =>
                                                                console.log(tet.currentTarget.value)
                                                            }
                                                            placeholder="Search supplier..."
                                                        />
                                                        <CommandEmpty>No supplier found.</CommandEmpty>
                                                        <CommandGroup>
                                                            <CommandList>
                                                                {suppliers.map(supplier => (
                                                                    <CommandItem
                                                                        value={supplier.company}
                                                                        key={supplier.id}
                                                                        onSelect={() => {
                                                                            form.setValue("supplier_id", supplier.id);
                                                                            setSupplierCommandOpen(false);
                                                                        }}
                                                                    >
                                                                        <Check
                                                                            className={cn(
                                                                                "mr-2 h-4 w-4",
                                                                                supplier.id === field.value
                                                                                    ? "opacity-100"
                                                                                    : "opacity-0"
                                                                            )}
                                                                        />
                                                                        {supplier.company}
                                                                    </CommandItem>
                                                                ))}
                                                            </CommandList>
                                                        </CommandGroup>
                                                    </Command>
                                                    <Flex
                                                        className="gap-x-2 border-t"
                                                        alignItems="center"
                                                        justifyContent="start"
                                                    >
                                                        <Flex
                                                            justifyContent="center"
                                                            className="gap-x-2 text-sm cursor-pointer hover:underline"
                                                            alignItems="center"
                                                            // onClick={() => setSupplierCommandOpen(false)}
                                                        >
                                                            <Button
                                                                icon={PlusSignIcon}
                                                                variant="ghost"
                                                                onClick={handleAddSupplier}
                                                                className="w-full p-4"
                                                            >
                                                                Add new supplier
                                                            </Button>
                                                        </Flex>
                                                    </Flex>
                                                </PopoverContent>
                                            </Popover>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={control}
                                    name="notice"
                                    render={({ field }) => (
                                        <FormItem className="col-span-8">
                                            <FormLabel>Extra Notice</FormLabel>
                                            <FormControl>
                                                <Input {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="my-10" />
                        <MediaLibraryCollection
                            form={form}
                            headers={{
                                withCredentials: "true"
                            }}
                            name="attachments"
                        />

                        <Separator className="my-10 border-dashed" />
                        <Flex className="gap-y-4 w-full" flexDirection="col" alignItems="stretch">
                            {fields.map((_item: any, index: number) => {
                                return (
                                    <div className="flex justify-between items-end gap-x-6" key={index}>
                                        <FormField
                                            control={control}
                                            name={`productPurchaseOrder.${index}.product`}
                                            render={({ field }) => (
                                                <AsyncProductSelect
                                                    field={field}
                                                    form={form}
                                                    products={products?.data || []}
                                                    isLoading={isProductLoading}
                                                    identityId={identityId}
                                                    search={query => {
                                                        setProductSearchQuery(query),
                                                            queryBuilder
                                                                .removeParam("search")
                                                                .setParam("search", query);
                                                    }}
                                                />
                                            )}
                                        />

                                        <FormField
                                            control={control}
                                            name={`productPurchaseOrder.${index}.warehouse_id`}
                                            render={({ field }) => (
                                                <FormItem className="col-span-4 mb-0">
                                                    <FormLabel>Warehouse</FormLabel>
                                                    {/* @ts-ignore */}
                                                    <Select
                                                        onValueChange={field.onChange}
                                                        defaultValue={field.value as any}
                                                    >
                                                        <FormControl>
                                                            <SelectTrigger>
                                                                <SelectValue placeholder="Warehouse" />
                                                            </SelectTrigger>
                                                        </FormControl>
                                                        <SelectContent>
                                                            {warehouses.map((warehouse: warehouseTypes.IWarehouse) => (
                                                                <SelectItem
                                                                    value={warehouse.id.toString()}
                                                                    key={warehouse.id}
                                                                >
                                                                    {warehouse.name}
                                                                </SelectItem>
                                                            ))}
                                                        </SelectContent>
                                                    </Select>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />

                                        <FormField
                                            control={control}
                                            name={`productPurchaseOrder.${index}.qty`}
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>Quantity</FormLabel>
                                                    <FormControl>
                                                        <Input {...field} placeholder="76" />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />

                                        <Flex className="w-auto" alignItems="end">
                                            <FormField
                                                control={control}
                                                name={`productPurchaseOrder.${index}.price`}
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <FormLabel>Purchase price new</FormLabel>
                                                        <FormControl>
                                                            <MoneyInput
                                                                prefix={<EuroIcon className="w-3" variant="solid" />}
                                                                {...field}
                                                                placeholder="1000.00"
                                                            />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )}
                                            />

                                            {/* <Flex>
                                                <CurrencyFormat
                                                    thousandSeparator={true}
                                                    displayType="text"
                                                    prefix="€"
                                                    fixedDecimalScale={true}
                                                    className="px-4"
                                                    value={(_item?.qty * _item?.price) / 100}
                                                />
                                            </Flex> */}

                                            <Button
                                                type="button"
                                                className="text-muted-foreground"
                                                variant="ghost"
                                                onClick={() => remove(index)}
                                            >
                                                <Delete04Icon className="w-4" />
                                            </Button>
                                        </Flex>
                                    </div>
                                );
                            })}
                        </Flex>
                        <Button
                            type="button"
                            icon={PlusSignIcon}
                            className="text-muted-foreground mt-4"
                            variant="ghost"
                            onClick={() =>
                                append({
                                    id: 0,
                                    // @ts-ignore
                                    warehouse_id: warehouses[0].id.toString(),
                                    qty: 0,
                                    price: 0
                                })
                            }
                        >
                            Add product
                        </Button>
                        <Separator className="my-10 border-dashed" />
                    </div>
                    <div
                        className="flex items-center justify-between text-right w-full px-6 py-3"
                        style={{
                            boxShadow:
                                "0px -2px 4px 0px rgba(101, 101, 101, 0.01), 0px -4px 8px 0px rgba(101, 101, 101, 0.04)"
                        }}
                    >
                        <div
                            className={cn(
                                "transition-opacity ease-in-out duration-300",
                                !totalValue && "opacity-0",
                                totalValue && "opacity-100"
                            )}
                        >
                            {totalValue && (
                                <Fragment>
                                    Total value:
                                    <CurrencyFormat
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        displayType="text"
                                        prefix="€"
                                        fixedDecimalScale={true}
                                        value={totalValue / 100}
                                    />
                                </Fragment>
                            )}
                        </div>
                        <Button type="submit" icon={Add01Icon}>
                            Create purchase order
                        </Button>
                    </div>
                </Flex>
            </form>
        </Form>
    );
};
