// import { ControlledFormField } from "@/common";
// import { Button, Calendar, Form, MultiRangeSlider } from "@ui";
import { Calendar } from "@/components/ui/calendar";
import { Form, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { IOrder } from "../types";

interface IExactDeliveryFormProps {
    onSubmit: (formValues: any) => void;
    order?: IOrder;
}

export const ExactDeliveryForm = ({ order, onSubmit }: IExactDeliveryFormProps) => {
    const form = useForm<IOrder>({
        defaultValues: order
    });

    useEffect(() => form.reset(order), [order]);

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} id="exact-delivery-form">
                <FormField
                    control={form.control}
                    name="exact_delivery_date_at"
                    render={({ field }) => (
                        <FormItem className="flex w-full">
                            <Calendar
                                mode="single"
                                className="sm:w-full"
                                selected={new Date(field.value)}
                                onSelect={field.onChange}
                                disabled={date => date <= new Date()}
                                initialFocus
                            />
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </form>
        </Form>
    );
};
