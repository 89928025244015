import { Check } from "lucide-react";
import React, { useEffect } from "react";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandSeparator
} from "@/components/ui/command";
import Flex from "@/components/ui/flex";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/utils";
import { PlusSignCircleIcon } from "@hugeicons/react";

interface DataTableFacetedFilterProps<TData, TValue> {
    onChange: (value: string[]) => void;
    title?: string;
    options: {
        label: string;
        value: string;
        icon?: React.ReactNode;
    }[];
    defaultSelected?: string[];
}

export function DataTableFacetedFilter<TData, TValue>({
    onChange,
    title,
    options,
    defaultSelected = []
}: DataTableFacetedFilterProps<TData, TValue>) {
    const [selectedValues, setSelectedValues] = React.useState<string[]>(defaultSelected);

    useEffect(() => {
        onChange(selectedValues);
    }, [selectedValues]);

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button variant="outline" className="border-dashed">
                    <Flex className="gap-x-2.5">
                        <PlusSignCircleIcon width={18} height={18} />
                        {title}
                    </Flex>
                    {selectedValues.length > 0 && (
                        <>
                            <Separator orientation="vertical" className="mx-2 h-4" />
                            <Badge variant="secondary" className="rounded-sm px-1 font-normal lg:hidden">
                                {selectedValues.length}
                            </Badge>
                            <div className="hidden space-x-1 lg:flex">
                                {selectedValues.length > 2 ? (
                                    <Badge variant="secondary" className="rounded-sm px-1 font-normal">
                                        {selectedValues.length} selected
                                    </Badge>
                                ) : (
                                    options
                                        .filter(option => selectedValues.includes(option.value))
                                        .map(option => (
                                            <Badge
                                                variant="secondary"
                                                key={option.value}
                                                className="rounded-sm px-1 font-normal"
                                            >
                                                {option.label}
                                            </Badge>
                                        ))
                                )}
                            </div>
                        </>
                    )}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[200px] p-0" align="start">
                <Command>
                    <CommandInput placeholder={title} />
                    <CommandList>
                        <CommandEmpty>No results found.</CommandEmpty>
                        <CommandGroup>
                            {options.map(option => {
                                const isSelected = selectedValues.includes(option.value);
                                const Icon = option.icon;
                                return (
                                    <CommandItem
                                        key={option.value}
                                        onSelect={() => {
                                            if (isSelected) {
                                                setSelectedValues(
                                                    selectedValues.filter(value => value !== option.value)
                                                );
                                            } else {
                                                setSelectedValues([...selectedValues, option.value]);
                                            }
                                            onChange?.(selectedValues);
                                        }}
                                    >
                                        <div
                                            className={cn(
                                                "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                                                isSelected
                                                    ? "bg-primary text-primary-foreground"
                                                    : "opacity-50 [&_svg]:invisible"
                                            )}
                                        >
                                            <Check />
                                        </div>
                                        {Icon && Icon}
                                        <span>{option.label}</span>
                                        {/* {facets?.get(option.value) && (
                                            <span className="ml-auto flex h-4 w-4 items-center justify-center font-mono text-xs">
                                                {facets.get(option.value)}
                                            </span>
                                        )} */}
                                    </CommandItem>
                                );
                            })}
                        </CommandGroup>
                        {selectedValues.length > 0 && (
                            <>
                                <CommandSeparator />
                                <CommandGroup>
                                    <CommandItem
                                        onSelect={() => setSelectedValues([])}
                                        className="justify-center text-center"
                                    >
                                        Clear filters
                                    </CommandItem>
                                </CommandGroup>
                            </>
                        )}
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    );
}
