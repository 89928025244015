import { axios, config } from "@/app";
import { notificationTypes } from "@/common/components/notification";
import { domainTypes } from "@/domain";
import { QueryBuilder } from "@cgarciagarcia/react-query-builder";
import fileDownload from "js-file-download";
import { OrderStatus } from "./constans/OrderStatus";
import { IFailedOrder, IOrder, IOrderCount, IOrderResponse, IQueue } from "./types";

export const getDomainOrders = async (
    domain: domainTypes.IDomain,
    queryBuilder?: QueryBuilder
): Promise<IOrderResponse> => {
    const response = await axios.get<IOrderResponse>(
        `${config.appUrl}api/v2/domains/${domain.id}/orders${queryBuilder?.build()}`
    );

    return response.data;
};

export const getOrder = async (id: number): Promise<IOrder> => {
    const response = await axios.get<IOrder>(`orders/${id}`);

    return response.data;
};

export const getOrderCountPerStatus = async (domain: domainTypes.IDomain): Promise<IOrderCount[]> => {
    const response = await axios.get<IOrderCount[]>(`domains/${domain.id}/orders-count`);

    return response.data;
};

export const updateOrder = async (order: IOrder): Promise<number> => {
    await axios.put<IOrder>(`orders/${order.id}`, { ...order });

    return order.id;
};

export const createOrderNotification = async ({
    order,
    formValues
}: {
    order: IOrder;
    formValues: notificationTypes.INotification;
}) => {
    await axios.post(`orders/${order.id}/notifications`, { ...formValues });

    return order;
};

interface IUpdateOrderStatusProps {
    orderIds: number[];
    newStatus: number;
    currentStatus: OrderStatus;
    carrierId?: number;
}

export const updateOrderStatus = async ({ orderIds, newStatus, carrierId, currentStatus }: IUpdateOrderStatusProps) => {
    const response = await axios.post("orders/update-status", {
        orderIds,
        newStatus,
        carrierId,
        currentStatus
    });

    return response.data;
};

export const updateOrderToWarehouse = async ({
    orderIds,
    currentStatus
}: {
    orderIds: number[];
    currentStatus: OrderStatus;
}): Promise<{ data: number[]; link: string }> => {
    const response = await axios.post<{ data: number[]; link: string }>("orders/process-warehouse", {
        orderIds,
        currentStatus
    });

    return response.data;
};

export const getDownloadCsv = async ({
    domainId,
    orderIds,
    status,
    filters
}: {
    domainId: number;
    orderIds: number[];
    status: OrderStatus;
    filters: any;
}) => {
    const response = await axios.post(`domains/${domainId}/orders-csv`, {
        orderIds,
        status,
        filters
    });

    fileDownload(response.data, "orders.csv");
};

export const getShipmentLabels = async ({ orderIds }: { orderIds: number[] }) => {
    const response = await axios.post(
        "orders/attachments",
        { orderIds },
        {
            headers: { accept: "application/pdf" },
            responseType: "blob"
        }
    );

    fileDownload(response.data, "orders.pdf", "PDF-1.5");
};

export const resetOrderAttachmentFlag = async ({ orderIds }: { orderIds: number[] }) => {
    const response = await axios.post("orders/reset-download-attachment", {
        orderIds
    });

    return response.data;
};

export const updateExactDeliveryDate = async ({ orderIds, formValues }: { orderIds: number[]; formValues: any }) => {
    const response = await axios.put("orders/bulk", {
        order_ids: orderIds,
        ...formValues
    });

    return response.data;
};
/* ADMIN ENDPOINTS */
export const updateResetOrder = async (order: IOrder) => {
    await axios.put(`orders/${order.id}/reset`);

    return order;
};

export const forceQueueStatusToSuccess = async ({ orderIds }: { orderIds: number[] }) => {
    const response = await axios.post("orders/queues", { orderIds });

    return response.data;
};

export const createOrder = async ({ domainId, formValues }: { domainId: number; formValues: any }) => {
    const response = await axios.post(`domains/${domainId}/sales-order`, {
        ...formValues
    });

    return response.data;
};

export const updateQueueStatus = async (queue: IQueue) => {
    const response = await axios.put(`queues/${queue.id}`);

    return response.data;
};

export const updateCarrier = async (orderId: number, carrierId: number) => {
    const response = await axios.put(`orders/${orderId}/carriers/${carrierId}`);

    return response.data;
};

export const confirmPickUpOrder = async (order: IOrder): Promise<IOrder> => {
    const response = await axios.put<{ data: IOrder }>(`orders/${order.id}/confirm`, order);

    return response.data.data;
};

export const addCustomerAlert = async (order: IOrder) => {
    await axios.post(`orders/${order.id}/customer-alerts`);

    return order;
};

export const removeCustomerAlert = async (order: IOrder) => {
    await axios.delete(`orders/${order.id}/customer-alerts`);

    return order;
};

export const getProductOrders = async (productId: number): Promise<IOrder[]> => {
    const response = await axios.get<{ data: IOrder[] }>(`products/${productId}/orders`);

    return response.data.data;
};

export const getFailedOrders = async (domainId: number): Promise<IFailedOrder[]> => {
    const response = await axios.get<{ data: IFailedOrder[] }>(`domains/${domainId}/failed-orders`);

    return response.data.data;
};

export const deleteFailedOrder = async (failedOrderId: number): Promise<null> => {
    await axios.delete<null>(`failed-orders/${failedOrderId}`);

    return null;
};

export const OrderResendInvoice = async (orderId: number) => {
    const response = await axios.post(`orders/${orderId}/resend-invoice`);

    return response.data;
};

export const getOrderTransportStatus = async (orderId: number): Promise<number> => {
    await axios.get<null>(`orders/${orderId}/transport-status`);

    return orderId;
};
