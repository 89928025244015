import { config } from "@/app";
import { TypographyH3 } from "@/components/custom/text/TypographyH3";
import { TypographyP } from "@/components/custom/text/TypographyP";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import Flex from "@/components/ui/flex";
import { Separator } from "@/components/ui/separator";
import { Sheet, SheetContent, SheetDescription, SheetFooter, SheetHeader, SheetTitle } from "@/components/ui/sheet";
import { Tabs, TabsList } from "@/components/ui/tabs";
import { countryHooks } from "@/country";
import { customerHooks } from "@/customer";
import { cn } from "@/lib/utils";
import { ProductAiLoadingForm } from "@/product/components/ProductAiLoadingForm";
import {
    Alert02Icon,
    Attachment02Icon,
    DashedLine02Icon,
    InformationDiamondIcon,
    MailSend01Icon,
    Notification03Icon,
    NotificationOff03Icon,
    PencilEdit01Icon,
    StarIcon,
    Target02Icon,
    Tick02Icon,
    Timer02Icon,
    TruckDeliveryIcon,
    UserIcon,
    WorkHistoryIcon
} from "@hugeicons/react";
import { TabsContent, TabsTrigger } from "@radix-ui/react-tabs";
import { Fragment, useState } from "react";
import { ActivityLog } from "../components/ActivityLog";
import { CustomerDetails } from "../components/CustomerDetails";
import { CustomerForm } from "../components/CustomerForm";
import { OrderedProductTable } from "../components/OrderedProductTable";
import { TransportStatus } from "../components/TransportStatus";
import { OrderStatus } from "../constans/OrderStatus";
import { useAddCustomerAlert, useGetOrder, useRemoveCustomerAlert, useReSendOrderConfirmation } from "../hooks";

interface IOrderSheetProps {
    orderId: number | undefined;
    open: boolean;
    onClose: () => void;
}

const OrderSheet = ({ orderId = 0, open, onClose }: IOrderSheetProps) => {
    const { data: order, isLoading } = useGetOrder(orderId, { enabled: !!orderId });
    const [activeTab, setActiveTab] = useState<string>("general");
    const { data: countries = [] } = countryHooks.useGetCountries();
    const { mutate: updateCustomer } = customerHooks.useUpdateCustomer();
    const { mutateAsync: addCustomerAlert } = useAddCustomerAlert();
    const { mutateAsync: removeCustomerAlert } = useRemoveCustomerAlert();
    const { mutateAsync: resendOrderConfirmation } = useReSendOrderConfirmation();

    const onClickAddOrderAlert = () => {
        if (order?.id) {
            addCustomerAlert(order);
        }
    };

    const onClickRemoveOrderAlert = () => {
        if (order?.id) {
            removeCustomerAlert(order);
        }
    };

    const onClickResendOrderConfirmation = () => {
        if (order?.id) {
            resendOrderConfirmation(order.id);
        }
    };

    return (
        <Sheet open={open} onOpenChange={onClose}>
            <SheetTitle />
            <SheetContent className="sm:max-w-3xl p-0 overflow-hidden">
                {!order || (isLoading && <ProductAiLoadingForm />)}
                {order && (
                    <Tabs
                        defaultValue="general"
                        className="h-full"
                        orientation="vertical"
                        onValueChange={value => setActiveTab(value)}
                    >
                        <Flex justifyContent="start" alignItems="start" className="h-full bg-slate-100">
                            <div className=" h-full w-18 w-18 p-2">
                                <TabsList className="h-full flex flex-col justify-start py-4 gap-y-4 px-0">
                                    <TabsTrigger value="general" className="data-[state=active]:text-primary">
                                        <Flex
                                            alignItems="center"
                                            justifyContent="center"
                                            className="bg-white w-10 h-10 rounded-full over"
                                        >
                                            <InformationDiamondIcon className="w-5 h-5" />
                                        </Flex>
                                    </TabsTrigger>
                                    <TabsTrigger value="features" className="data-[state=active]:text-primary">
                                        <Flex
                                            alignItems="center"
                                            justifyContent="center"
                                            className="bg-white w-10 h-10 rounded-full"
                                        >
                                            <Attachment02Icon className="w-5 h-5" />
                                        </Flex>
                                    </TabsTrigger>
                                    <TabsTrigger value="customer-form" className="data-[state=active]:text-primary">
                                        <Flex
                                            alignItems="center"
                                            justifyContent="center"
                                            className="bg-white w-10 h-10 rounded-full"
                                        >
                                            a{/* <BrandfetchIcon className="w-5 h-5" /> */}
                                        </Flex>
                                    </TabsTrigger>
                                    <TabsTrigger value="images" className="data-[state=active]:text-primary">
                                        <Flex
                                            alignItems="center"
                                            justifyContent="center"
                                            className="bg-white w-10 h-10 rounded-full"
                                        >
                                            <UserIcon className="w-5 h-5" />
                                        </Flex>
                                    </TabsTrigger>
                                    <TabsTrigger value="pdf" className="data-[state=active]:text-primary">
                                        <Flex
                                            alignItems="center"
                                            justifyContent="center"
                                            className="bg-white w-10 h-10 rounded-full"
                                        >
                                            <DashedLine02Icon className="w-5 h-5" />
                                        </Flex>
                                    </TabsTrigger>
                                    <TabsTrigger value="carrier" className="data-[state=active]:text-primary">
                                        <Flex
                                            alignItems="center"
                                            justifyContent="center"
                                            className="bg-white w-10 h-10 rounded-full"
                                        >
                                            <TruckDeliveryIcon className="w-5 h-5" />
                                        </Flex>
                                    </TabsTrigger>
                                    <TabsTrigger value="history" className="data-[state=active]:text-primary">
                                        <Flex
                                            alignItems="center"
                                            justifyContent="center"
                                            className="bg-white w-10 h-10 rounded-full"
                                        >
                                            <WorkHistoryIcon className="w-5 h-5" />
                                        </Flex>
                                    </TabsTrigger>
                                </TabsList>
                            </div>
                            <Flex
                                alignItems="start"
                                justifyContent="between"
                                flexDirection="col"
                                className="w-full h-full bg-white"
                            >
                                <SheetHeader className="px-6 py-6">
                                    <SheetTitle className="flex items-center gap-x-2.5">
                                        <img
                                            className="rounded-md w-8 h-8"
                                            src={`${config.appUrl}/assets/img/channels/${order?.channel.icon}`}
                                            alt=""
                                            width="26"
                                            height="26"
                                        />
                                        <div>
                                            <div className="text-muted-foreground text-xs font-light">
                                                {order?.channel.company_name}
                                            </div>
                                            <div className="font-medium text-sm">
                                                #{order?.id} - {order?.external_order_id}
                                            </div>
                                        </div>
                                    </SheetTitle>
                                </SheetHeader>
                                <div className="h-full w-full overflow-scroll px-6 py-0">
                                    <div className="mx-8">
                                        <Timeline orderStatus={order?.status} />
                                    </div>
                                    <TabsContent value="general">
                                        <Fragment>
                                            <Alert
                                                variant="destructive"
                                                className={cn(
                                                    "bg-primary-foreground border-none transition-all ease-in-out duration-300 mb-0",
                                                    order.customer?.shipmentAddress?.is_alarm && "h-11 mb-8",
                                                    !order.customer?.shipmentAddress?.is_alarm &&
                                                        "h-0 overflow-hidden p-0 mt-0"
                                                )}
                                            >
                                                <Flex justifyContent="start" alignItems="center" className="gap-x-4">
                                                    <Alert02Icon className="h-4 w-4 animate-caret-blink" />
                                                    {/* <AlertTitle>Heads up!</AlertTitle> */}
                                                    <AlertDescription>
                                                        This customer needs double check.
                                                    </AlertDescription>
                                                </Flex>
                                            </Alert>

                                            <Card className="w-full border rounded-md mb-12">
                                                <CardContent className="p-4">
                                                    <Flex>
                                                        <Avatar className="h-10 w-10 ">
                                                            <AvatarFallback className="text-sm text-primary bg-primary-foreground">
                                                                {order?.customer?.shipmentAddress?.full_name}
                                                            </AvatarFallback>
                                                        </Avatar>
                                                        <Flex
                                                            justifyContent="start"
                                                            alignItems="start"
                                                            flexDirection="col"
                                                            className="gap-y-1 ml-4"
                                                        >
                                                            <p className="text-xs leading-none text-muted-foreground">
                                                                Customer
                                                            </p>
                                                            <h3 className="font-semibold leading-none text-foreground">
                                                                {order?.customer?.shipmentAddress?.full_name}
                                                            </h3>
                                                        </Flex>

                                                        <Flex justifyContent="end" className="gap-x-2">
                                                            {order.customer?.shipmentAddress?.is_alarm ? (
                                                                <Button
                                                                    size="sm"
                                                                    variant="outline"
                                                                    onClick={() => {
                                                                        setTimeout(() => {
                                                                            onClickRemoveOrderAlert();
                                                                        }, 200);
                                                                    }}
                                                                    icon={NotificationOff03Icon}
                                                                >
                                                                    Remove customer alert
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    size="sm"
                                                                    variant="outline"
                                                                    onClick={onClickAddOrderAlert}
                                                                    icon={Notification03Icon}
                                                                >
                                                                    Alert customer
                                                                </Button>
                                                            )}
                                                            <Button size="sm" variant="outline">
                                                                Edit
                                                            </Button>
                                                        </Flex>
                                                    </Flex>
                                                </CardContent>
                                            </Card>

                                            <Flex alignItems="start">
                                                <Flex justifyContent="start" alignItems="start" className="gap-y-4">
                                                    <Flex
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        className="w-10 h-10 rounded-md bg-muted text-muted-foreground mr-4"
                                                    >
                                                        <Timer02Icon className="w-6 h-6" />
                                                    </Flex>
                                                    <Flex
                                                        justifyContent="start"
                                                        alignItems="start"
                                                        flexDirection="col"
                                                        className="gap-y-1"
                                                    >
                                                        <Flex
                                                            justifyContent="start"
                                                            className="gap-x-2 text-muted-foreground"
                                                        >
                                                            <p className="text-xs text-muted-foreground font-medium">
                                                                Latest delivery
                                                            </p>
                                                            <PencilEdit01Icon className="w-4 h-4 cursor-pointer hover:text-foreground" />
                                                        </Flex>

                                                        <p className="text-card-foreground text-sm">
                                                            {Intl.DateTimeFormat("nl-NL", {
                                                                dateStyle: "medium",
                                                                timeStyle: "short"
                                                            }).format(new Date("2021-10-10T10:00:00"))}
                                                            {/* {order?.latest_delivery_date_at} */}
                                                        </p>
                                                    </Flex>
                                                </Flex>
                                                <Flex justifyContent="start" alignItems="start">
                                                    <Flex
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        className="w-10 h-10 rounded-md bg-muted text-muted-foreground mr-4"
                                                    >
                                                        <TruckDeliveryIcon className="w-6 h-6" />
                                                    </Flex>
                                                    <Flex
                                                        flexDirection="col"
                                                        justifyContent="start"
                                                        alignItems="start"
                                                        className="gap-y-1"
                                                    >
                                                        <p className="text-xs text-muted-foreground font-medium ">
                                                            Track & Trace
                                                        </p>
                                                        <p className="text-card-foreground text-sm">3S2D3D2D3D2D3D</p>
                                                    </Flex>
                                                </Flex>
                                                <Flex justifyContent="start" alignItems="start">
                                                    <Flex
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        className="w-10 h-10 rounded-md bg-muted text-muted-foreground mr-4"
                                                    >
                                                        <Target02Icon className="w-6 h-6" />
                                                    </Flex>
                                                    <Flex
                                                        flexDirection="col"
                                                        justifyContent="start"
                                                        alignItems="start"
                                                        className="gap-y-1"
                                                    >
                                                        <p className="text-xs text-muted-foreground font-medium">
                                                            Delivered
                                                        </p>
                                                        <p className="text-card-foreground text-sm">
                                                            {Intl.DateTimeFormat("nl-NL", {
                                                                dateStyle: "medium",
                                                                timeStyle: "short"
                                                            }).format(new Date("2021-10-10T10:00:00"))}
                                                        </p>
                                                    </Flex>
                                                </Flex>
                                            </Flex>

                                            <Separator className="my-10" />

                                            <div className="grid grid-cols-2">
                                                {order.customer?.shipmentAddress && (
                                                    <CustomerDetails
                                                        customer={order.customer?.shipmentAddress}
                                                        title="Shipment address"
                                                    />
                                                )}

                                                {order.customer?.billingAddress && (
                                                    <CustomerDetails
                                                        customer={order.customer?.billingAddress}
                                                        title="Billing address"
                                                    />
                                                )}
                                            </div>

                                            <Separator className="my-10" />

                                            <TypographyH3>Ordered Products Summary</TypographyH3>
                                            <TypographyP className="leading-none mt-1 mb-4">
                                                This section provides an overview of ordered products displayed above a
                                                table.
                                            </TypographyP>

                                            <OrderedProductTable products={order?.products || []} />

                                            <Card className="p-0">
                                                <CardContent className="p-0"></CardContent>
                                            </Card>
                                        </Fragment>
                                    </TabsContent>
                                    <TabsContent value="features">
                                        <Fragment>
                                            <Card className="bg-muted text-muted-foreground mt-10">
                                                <CardContent className="p-4">
                                                    <Flex justifyContent="start" className="gap-x-4">
                                                        <div className="bg-slate-500 rounded-xl">
                                                            <Attachment02Icon className="w-4 h-4 m-2 text-white" />
                                                        </div>
                                                        <div>
                                                            <TypographyP className="p-0 m-0">Invoice </TypographyP>
                                                            <TypographyH3>abc</TypographyH3>
                                                        </div>
                                                    </Flex>
                                                </CardContent>
                                            </Card>
                                            <Card className="bg-muted text-muted-foreground mt-4">
                                                <CardContent className="p-4">
                                                    <Flex justifyContent="start" className="gap-x-4">
                                                        <div className="bg-slate-500 rounded-xl">
                                                            <Attachment02Icon className="w-4 h-4 m-2 text-white" />
                                                        </div>
                                                        <div>
                                                            <TypographyP className="p-0 m-0">Invoice </TypographyP>
                                                            <TypographyH3>abc</TypographyH3>
                                                        </div>
                                                    </Flex>
                                                </CardContent>
                                            </Card>
                                        </Fragment>
                                    </TabsContent>
                                    <TabsContent value="customer-form">
                                        {order.customer?.shipmentAddress && (
                                            <CustomerForm
                                                countries={countries}
                                                customer={order.customer?.shipmentAddress}
                                                onSubmit={data => updateCustomer(data)}
                                                title="Shipping address"
                                            />
                                        )}
                                        <Separator className="my-10" />
                                        {order.customer?.billingAddress && (
                                            <CustomerForm
                                                countries={countries}
                                                customer={order.customer?.billingAddress}
                                                onSubmit={data => updateCustomer(data)}
                                                title="Shipping address"
                                            />
                                        )}
                                    </TabsContent>
                                    <TabsContent value="carrier">
                                        <TransportStatus order={order} />
                                    </TabsContent>
                                    <TabsContent value="history">
                                        <ActivityLog activities={order?.activities || []} />
                                    </TabsContent>
                                </div>
                                <SheetFooter
                                    className="justify-end flex w-full m-0 py-3 px-6 z-50"
                                    style={{
                                        boxShadow:
                                            "0px -2px 4px 0px rgba(101, 101, 101, 0.01), 0px -4px 8px 0px rgba(101, 101, 101, 0.04)"
                                    }}
                                >
                                    <Button
                                        icon={MailSend01Icon}
                                        onClick={() => onClickResendOrderConfirmation()}
                                        variant="ghost"
                                    >
                                        Mail Invoice
                                    </Button>
                                    <Button icon={StarIcon} onClick={onClose} variant="ghost">
                                        Review request
                                    </Button>
                                    <Button onClick={onClose} variant="outline">
                                        Close
                                    </Button>
                                </SheetFooter>
                            </Flex>
                        </Flex>
                    </Tabs>
                )}
            </SheetContent>
            <SheetDescription />
        </Sheet>
    );
};

const Timeline = ({ orderStatus }: { orderStatus: OrderStatus }) => {
    const statusToWidth: Record<OrderStatus, string> = {
        [OrderStatus.PickUp]: "0%",
        [OrderStatus.New]: "16.5%",
        [OrderStatus.FutureShipping]: "25%",
        [OrderStatus.Shipping]: "38%",
        [OrderStatus.Warehouse]: "62%",
        [OrderStatus.Shipped]: "85%",
        [OrderStatus.Cancelled]: "0%",
        [OrderStatus.Completed]: "100%",
        [OrderStatus.Error]: "0%"
    };

    const isStatusPassed = (status: OrderStatus) => {
        const statusOrder = [
            OrderStatus.PickUp,
            OrderStatus.New,
            OrderStatus.FutureShipping,
            OrderStatus.Shipping,
            OrderStatus.Warehouse,
            OrderStatus.Shipped,
            OrderStatus.Completed
        ];
        return statusOrder.indexOf(status) >= statusOrder.indexOf(orderStatus);
    };

    return (
        <div className="h-auto w-full mb-16">
            <div className="relative">
                <Flex justifyContent="between" alignItems="center">
                    <div className="z-20 relative">
                        <Flex alignItems="center" justifyContent="center">
                            <Flex
                                justifyContent="center"
                                alignItems="center"
                                className={cn(
                                    isStatusPassed(OrderStatus.New) ? "bg-green-500" : "bg-slate-100",
                                    "w-5 h-5 rounded-full"
                                )}
                            >
                                <Tick02Icon variant="solid" className="text-white w-4" />
                            </Flex>
                        </Flex>
                        <div className="absolute -bottom-8 text-center left-1/2 -translate-x-1/2 w-auto">
                            <p className="text-foreground text-sm whitespace-nowrap mt-1 font-normal">New</p>
                        </div>
                    </div>

                    <div className="z-20 relative">
                        <Flex alignItems="center" justifyContent="center">
                            <Flex
                                justifyContent="center"
                                alignItems="center"
                                className={cn(
                                    isStatusPassed(OrderStatus.Shipping) ? "bg-green-500" : "bg-slate-100",
                                    "w-5 h-5 rounded-full"
                                )}
                            >
                                <Tick02Icon variant="solid" className="text-white w-4" />
                            </Flex>
                        </Flex>
                        <div className="absolute -bottom-8 text-center left-1/2 -translate-x-1/2 w-auto">
                            <p className="text-foreground text-sm whitespace-nowrap mt-1 font-normal">Shipping</p>
                        </div>
                    </div>

                    <div className="z-20 relative">
                        <Flex alignItems="center" justifyContent="center">
                            <Flex
                                justifyContent="center"
                                alignItems="center"
                                className={cn(
                                    isStatusPassed(OrderStatus.Warehouse) ? "bg-green-500" : "bg-slate-100",
                                    "w-5 h-5 rounded-full"
                                )}
                            >
                                <Tick02Icon variant="solid" className="text-white w-4" />
                            </Flex>
                        </Flex>
                        <div className="absolute -bottom-8 text-center left-1/2 -translate-x-1/2 w-auto">
                            <p className="text-foreground text-sm whitespace-nowrap mt-1 font-normal">Warehouse</p>
                        </div>
                    </div>

                    <div className="z-20 relative">
                        <span className="relative flex h-4 w-4">
                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-300 opacity-75" />
                            <span className="relative inline-flex rounded-full h-4 w-4 bg-green-500" />
                        </span>
                        <div className="absolute -bottom-8 text-center left-1/2 -translate-x-1/2 w-auto">
                            <p className="text-slate-600 text-sm whitespace-nowrap mt-1 font-normal">Shipped</p>
                        </div>
                    </div>

                    <div className="z-20 relative">
                        <Flex alignItems="center" justifyContent="center">
                            <Flex
                                justifyContent="center"
                                alignItems="center"
                                className={cn(
                                    isStatusPassed(OrderStatus.Completed) ? "bg-green-500" : "bg-slate-100",
                                    "w-5 h-5 rounded-full"
                                )}
                            >
                                <Tick02Icon variant="solid" className="text-white w-4" />
                            </Flex>
                        </Flex>
                        <div className="absolute -bottom-8 text-center left-1/2 -translate-x-1/2 w-auto">
                            <p className="text-foreground text-sm whitespace-nowrap mt-1 font-normal">Delivered</p>
                        </div>
                    </div>
                </Flex>
                <div
                    className="border-b border border-emerald-500 z-10 absolute top-1/2 -translate-y-1/2"
                    style={{ width: statusToWidth[orderStatus] }}
                />
                <div className="border-b border border-slate-200 border-dashed bg-white z-0 absolute top-1/2 -translate-y-1/2 w-full " />
            </div>
        </div>
    );
};

export default OrderSheet;
