import Flex from "@/components/ui/flex";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";

interface IDataTableSkeleton {
    showSearch?: boolean;
}
export const DataTableSkeleton = ({ showSearch = true }: IDataTableSkeleton) => {
    return (
        <div
            className={cn(
                "border bg-card text-card-foreground overflow-hidden rounded-md p-0 shadow-3xl flex flex-col",
                !showSearch && "border-none rounded-t-none"
            )}
        >
            {showSearch && (
                <div className="flex justify-end p-6">
                    <Skeleton className="w-64 h-10 bg-slate-100" />
                </div>
            )}

            <Skeleton className="w-full h-8 rounded-none bg-slate-100" />

            {Array.from({ length: 15 }).map((_, index) => (
                <Flex key={index} className="bg-white p-4 border-b border-slate-100" justifyContent="start">
                    <Skeleton className="w-full h-5 w-5 rounded-sm bg-slate-100 mt-4" />
                    <Skeleton className="w-full h-8 w-8 ml-4 rounded-full bg-slate-100 mt-4" />
                    <Flex flexDirection="col" alignItems="start" className="w-36 pl-4">
                        <Skeleton className="w-full h-2.5 bg-slate-100 mt-4 w-3/5" />
                        <Skeleton className="w-full h-4 bg-slate-100 mt-2 w-full" />
                    </Flex>

                    <Skeleton className="w-full h-3 bg-slate-100 mt-2 w-48 mx-8" />
                    <Skeleton className="w-full h-3 bg-slate-100 mt-2 w-32 mx-8" />
                    <Skeleton className="w-full h-3 bg-slate-100 mt-2 w-28 mx-8" />

                    <Flex flexDirection="col" alignItems="start" className="w-36 pl-4">
                        <Skeleton className="w-full h-2.5 bg-slate-100 mt-4 w-3/5" />
                        <Skeleton className="w-full h-4 bg-slate-100 mt-2 w-full" />
                    </Flex>
                </Flex>
            ))}
        </div>
    );
};
