import { ActivityEvent } from "@/activity/constants/ActivityEvent";
import { TypographyH1 } from "@/components/custom/text/TypographyH1";
import { TypographyP } from "@/components/custom/text/TypographyP";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import Flex from "@/components/ui/flex";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import { Textarea } from "@/components/ui/textarea";
import { TradeDownIcon, TradeUpIcon, WarehouseIcon } from "@hugeicons/react";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { IProduct, IProductWarehouse } from "../types";

interface IWarehouseStockFormProps {
    product: IProduct;
    onSubmit: (data: IFormValues) => void;
}

interface IFormValues extends IProduct {
    reason: string;
}
export const WarehouseStockForm = ({ product, onSubmit }: IWarehouseStockFormProps) => {
    const [productWarehouses, setProductWarehouses] = useState<IProductWarehouse[]>(product.productWarehouses || []);
    const { control, reset, ...form } = useForm<IFormValues>({
        defaultValues: product
    });

    const { fields } = useFieldArray({
        control,
        name: "productWarehouses"
    });

    const handleSubmit = async (data: IFormValues) => {
        onSubmit(data);

        reset({
            ...data,
            productWarehouses: data.productWarehouses?.map(warehouse => ({
                ...warehouse,
                add_stock: 0
            }))
        });
    };

    useEffect(() => {
        const values = form.watch();

        setProductWarehouses(values.productWarehouses || []);
    }, [form, productWarehouses, form.watch()]);

    return (
        <div className="pb-16">
            <TypographyH1 className="sm:text-lg lg:text-xl text-foreground font-medium mb-0">
                Stock adjustments
            </TypographyH1>
            <TypographyP className="text-muted-foreground mt-2 mb-8">
                Here you can adjust the stock for this product. You can increase or decrease the stock.
            </TypographyP>
            <Form reset={reset} control={control} {...form}>
                <form onSubmit={form.handleSubmit(handleSubmit)} className="p-1">
                    <div className="">
                        {fields.map((_field, index) => (
                            <div className="border border-border border-dashed rounded-lg py-2 px-4">
                                <FormField
                                    control={control}
                                    name={`productWarehouses.${index}.add_stock`}
                                    render={({ field }) => (
                                        <FormItem className="flex items-center gap-2">
                                            <div className="w-56 bg-red">
                                                <Flex className="gap-x-2">
                                                    <Flex className="w-16" justifyContent="center" alignItems="center">
                                                        <WarehouseIcon
                                                            className="text-gray-400"
                                                            width={28}
                                                            height={28}
                                                        />
                                                    </Flex>
                                                    <Flex
                                                        flexDirection="col"
                                                        className="gap-y-0"
                                                        justifyContent="start"
                                                        alignItems="start"
                                                    >
                                                        <p className="text-sm">
                                                            {product?.productWarehouses?.[index].warehouse.name}
                                                        </p>
                                                        <p className="text-xs text-gray-500">
                                                            {product?.productWarehouses?.[index].warehouse.address}
                                                        </p>
                                                    </Flex>
                                                </Flex>
                                            </div>
                                            <div className="w-1/5 grow">
                                                <p className="text-xs uppercase text-muted-foreground">Current stock</p>
                                                <p>{product?.productWarehouses?.[index].in_stock}</p>
                                            </div>
                                            <FormControl>
                                                <Input
                                                    prefix={
                                                        !!productWarehouses?.[index].add_stock &&
                                                        productWarehouses?.[index].add_stock > 0 ? (
                                                            <TradeUpIcon className="w-4 h-4 text-green-500" />
                                                        ) : (
                                                            <TradeDownIcon className="w-4 h-4 text-primary" />
                                                        )
                                                    }
                                                    type="number"
                                                    className="w-32"
                                                    placeholder="Stock"
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                        ))}
                    </div>

                    <Flex flexDirection="col" justifyContent="start" alignItems="end" className="gap-y-4 w-full mt-6">
                        <FormField
                            control={control}
                            name={`reason`}
                            render={({ field }) => (
                                <FormItem className="w-full">
                                    <FormLabel>Reason for adjustments:</FormLabel>
                                    <FormControl>
                                        <Textarea
                                            className="w-full"
                                            placeholder="Why did you change the stock?"
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <Button type="submit">Save</Button>
                    </Flex>
                </form>
            </Form>

            <Separator className="my-12" />

            <TypographyH1 className="sm:text-lg lg:text-xl text-foreground font-medium mb-0">
                History activities
            </TypographyH1>
            <TypographyP className="text-muted-foreground mt-2 mb-8">
                You can track all stock adjustments here.
            </TypographyP>
            <div className="pl-2 my-8">
                {product.activities
                    ?.filter(activity => activity.event === ActivityEvent.StockUpdate)
                    .map(activity => (
                        <div className="border-l pl-6 pt-12 relative" key={activity.id}>
                            <div className="absolute top-20 left-0 w-4 h-4 -translate-x-1/2 -translate-y-1/2 z-10 bg-primary rounded-full bg-slate-300"></div>
                            <Card className="">
                                <CardContent className="p-4">
                                    <Flex justifyContent="start" alignItems="start" className="gap-x-4">
                                        <Flex flexDirection="col" className="text-center grow-0 w-12">
                                            <time className="uppercase text-xs text-muted-foreground">
                                                {Intl.DateTimeFormat("nl-NL", {
                                                    month: "short"
                                                }).format(new Date(activity.created_at))}
                                            </time>
                                            <p className="font-bold">
                                                {" "}
                                                {new Date(activity.created_at)
                                                    .toLocaleString("nl-NL", { day: "2-digit" })
                                                    .toUpperCase()}
                                            </p>
                                        </Flex>
                                        <Flex flexDirection="col" className="gap-x-8">
                                            <Flex justifyContent="start" alignItems="start" className="gap-x-8">
                                                <Flex flexDirection="col" alignItems="start" className="gap-y-2">
                                                    <p className="text-xs uppercase text-muted-foreground">
                                                        Added to stock
                                                    </p>
                                                    <Flex flexDirection="col" alignItems="start" className="gap-y-1.5">
                                                        {activity.properties?.productWarehouses?.[0]?.add_stock} items
                                                    </Flex>
                                                </Flex>
                                                <Flex flexDirection="col" alignItems="start" className="gap-y-2">
                                                    <p className="text-xs uppercase text-muted-foreground">
                                                        Actioned by
                                                    </p>
                                                    <p className="text-sm">{activity.user?.full_name}</p>
                                                </Flex>
                                            </Flex>
                                            <div className="w-full">
                                                <p className="mt-4 text-muted-foreground font-light text-sm">Reason</p>
                                                <p className="bg-muted rounded-lg p-2 h-16 text-foreground mt-0.5 text-sm">
                                                    {activity.properties?.reason}
                                                </p>
                                            </div>
                                        </Flex>
                                    </Flex>
                                </CardContent>
                            </Card>
                        </div>
                    ))}
            </div>
        </div>
    );
};
