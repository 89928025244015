import { MoneyFormatter } from "@/common";
import { DataTable } from "@/components/custom/table";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import Flex from "@/components/ui/flex";
import { Link04Icon, SecurityBlockIcon, SecurityCheckIcon } from "@hugeicons/react";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { ColumnDef } from "@tanstack/react-table";
import { IProduct } from "../types";

interface IProductTableProps {
    data: IProduct[];
    onEditProduct: (id: number) => void;
}

export const ProductTable = ({ data, onEditProduct }: IProductTableProps) => {
    const columns: ColumnDef<IProduct>[] = [
        {
            id: "id",
            header: "ID",
            accessorKey: "id"
        },
        {
            id: "test",
            header: "test",
            cell: ({ row }) => {
                return row.getCanExpand() ? (
                    <button onClick={row.getToggleExpandedHandler()} style={{ cursor: "pointer" }}>
                        {row.getIsExpanded() ? "👇" : "👉"}
                    </button>
                ) : (
                    ""
                );
            }
        },
        {
            id: "name",
            header: "Name",
            cell: ({ row }) => (
                <Flex alignItems="stretch" justifyContent="start" className="gap-x-1.5 stetch h-10">
                    {row.original.children && row.original.children.length > 0 && (
                        <div className="h-full flex-grow flex items-center justify-start">
                            <Link04Icon width={26} height={26} />
                        </div>
                    )}
                    <div className="text-left">
                        <p className="flex-1 truncate w-64 text-gray-700 font-normal flex gap-x-1 items-center">
                            {row.original.name}{" "}
                            {row.original.verified_at ? (
                                <SecurityCheckIcon className="w-3.5 h-3.5 text-green-600" />
                            ) : (
                                <SecurityBlockIcon className="w-3.5 h-3.5 text-red-600" />
                            )}
                        </p>
                        <Flex justifyContent="start" className="gap-x-1.5">
                            <p className="flex items-center gap-x-1.5">{row.original.eans[0]?.ean} </p>
                            {row.original.eans?.length > 1 && (
                                <Badge variant={"outline"} className="text-xs font-normal text-muted-foreground">
                                    + {row.original.eans?.length - 1}
                                </Badge>
                            )}
                        </Flex>
                    </div>
                </Flex>
            )
        },
        {
            id: "identity",
            header: "Identity",
            cell: ({ row }) => row.original.identity.name
        },
        {
            id: "status",
            header: "Status",
            cell: ({ row }) =>
                row.original.status ? (
                    <div className="mt-1 flex items-center gap-x-1.5">
                        <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                            <div className="size-1.5 rounded-full bg-emerald-500"></div>
                        </div>
                        <p className="text-xs/5 text-gray-500">Active</p>
                    </div>
                ) : (
                    <Badge variant="outline">Offline</Badge>
                )
        },
        {
            id: "purchase_price",
            header: "Purchase price",
            meta: {
                align: "right"
            },
            cell: ({ row }) => (
                <div className="text-right">
                    {row.original.purchase_price ? MoneyFormatter(row.original.purchase_price) : "-"}
                </div>
            )
        },
        {
            id: "stock",
            header: "Stock",
            cell: ({ row }) => (
                <div className="text-center">
                    {row.original.productWarehouses?.reduce((acc, warehouse) => acc + warehouse.in_stock, 0)}
                </div>
            )
        },
        {
            id: "value",
            header: "Stock value",
            cell: ({ row }) => {
                const stock =
                    row.original.productWarehouses?.reduce((acc, warehouse) => acc + warehouse.in_stock, 0) || 0;
                const purchasePrice = row.original.purchase_price || 0;

                return (
                    <div className="text-center">
                        {stock && purchasePrice ? MoneyFormatter(stock * purchasePrice) : "-"}
                    </div>
                );
            }
        },
        // {
        //     id: "channels",
        //     header: "Channel",
        //     cell: ({ row }) => (
        //         <div className="flex gap-x-1 max-w-24">
        //             {row.original.channels?.map(
        //                 (channel) =>
        //                     channel.company_name && (
        //                         <Badge key={channel.id} color="slate">
        //                             {channel.company_name}
        //                         </Badge>
        //                     )
        //             )}
        //         </div>
        //     ),
        // },
        {
            id: "updated_at",
            header: "updated at",
            cell: ({ row }) => new Date(row.original.created_at).toDateString()
        },
        {
            id: "created_at",
            header: "Created at",
            cell: ({ row }) => new Date(row.original.created_at).toDateString()
        },
        {
            id: "edit",
            header: "",
            cell: ({ row }) => {
                const product = row.original;

                return (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                <span className="sr-only">Open menu</span>
                                <DotsHorizontalIcon className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel>Actions</DropdownMenuLabel>
                            <DropdownMenuItem onClick={() => onEditProduct(product.id)}>View product</DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                );
            }
        }
    ];

    return <DataTable<IProduct> data={data} columns={columns} />;
};
