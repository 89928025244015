import { cn } from "@/lib/utils";
import { Menu, Transition } from "@headlessui/react";
import { EllipseSelectionIcon } from "@hugeicons/react";
import React from "react";

export interface IDropdownMenuItem {
    name: string;
    description?: string;
    onClick: (test?: any) => void;
    show?: number[];
    disabled: boolean;
}

interface IDropdownMenuProps {
    name?: string;
    items: IDropdownMenuItem[];
}
export function DropdownMenu({ name, items }: IDropdownMenuProps) {
    const activeItems = items.filter(item => !item.disabled);

    return (
        <div className="relative z-10 inline-block text-left">
            <Menu>
                {({ open }) => (
                    <React.Fragment>
                        <span className="rounded-md shadow-sm">
                            <Menu.Button
                                className={cn(
                                    {
                                        "border-gray-300 text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800":
                                            activeItems.length > 0
                                    },
                                    { "border-gray-100 text-gray-300": activeItems.length < 1 },
                                    "inline-flex justify-center w-full  text-sm font-medium leading-4 transition duration-150 ease-in-out bg-white border",
                                    { "rounded-md px-4 py-2": name },
                                    { "rounded-full w-8 h-8 items-center": !name }
                                )}
                            >
                                {name ? (
                                    <React.Fragment>
                                        <span>{name}</span>
                                        <svg
                                            className={cn("w-5 h-5 ml-2 -mr-1")}
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </React.Fragment>
                                ) : (
                                    <EllipseSelectionIcon className="w-4" />
                                )}
                            </Menu.Button>
                        </span>
                        {activeItems.length > 0 && (
                            <Transition
                                show={open}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items
                                    static
                                    className="absolute right-0 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
                                >
                                    <div>
                                        {activeItems.map((item, index) =>
                                            item.disabled ? (
                                                <Menu.Item
                                                    as="span"
                                                    key={index}
                                                    disabled
                                                    className="w-full p-3 text-sm leading-4 text-left text-gray-700 opacity-50 cursor-not-allowed "
                                                >
                                                    <div>{item.name}</div>
                                                    {item.description && (
                                                        <span className="text-xs text-gray-600">
                                                            {item.description}
                                                        </span>
                                                    )}
                                                </Menu.Item>
                                            ) : (
                                                <Menu.Item key={index}>
                                                    {({ active }) => (
                                                        <div
                                                            onClick={item.onClick}
                                                            className={cn(
                                                                { "bg-gray-100 text-gray-900": active },
                                                                { "text-gray-700": !active },
                                                                { "border-t": index > 0 },
                                                                "w-full p-3 text-sm leading-5 text-left cursor-pointer"
                                                            )}
                                                        >
                                                            <div className="text-gray-900">{item.name}</div>
                                                            {item.description && (
                                                                <span className="text-xs leading-3 text-gray-600">
                                                                    {item.description}
                                                                </span>
                                                            )}
                                                        </div>
                                                    )}
                                                </Menu.Item>
                                            )
                                        )}
                                    </div>
                                </Menu.Items>
                            </Transition>
                        )}
                    </React.Fragment>
                )}
            </Menu>
        </div>
    );
}
