import { commonTypes } from "@/common";

import { IMeta } from "@/components/custom/pagination/types";
import { useQuery } from "react-query";
import { getDomainBrands } from "./queries";
import { IBrand } from "./types";

export const useDomainBrands = (domainId: number, options?: commonTypes.IUseGetOptions) =>
    useQuery<{ data: IBrand[]; meta: IMeta }, Error>({
        queryKey: ["brands", domainId],
        queryFn: () => getDomainBrands(domainId),
        ...options
    });
