import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";
import { OrderStatus } from "@/order/constans/OrderStatus";
import { IOrderCount } from "@/order/types";
import { Fragment } from "react";
interface TabItemsProps {
    failedOrders: any[]; // Adjust the type as necessary
    orderCountPerStatus: IOrderCount[];
    handleChangeStatus: (orderStatus: OrderStatus) => void;
    currentOrderStatus: OrderStatus;
}

export const TabItems = ({
    failedOrders,
    orderCountPerStatus,
    handleChangeStatus,
    currentOrderStatus
}: TabItemsProps) => {
    const items: Array<{ name: string; count: number; orderStatus: OrderStatus; disabled?: boolean }> = [
        {
            name: "ISSUES",
            count: failedOrders?.length || 0,
            disabled: failedOrders?.length === 0,
            orderStatus: 99
        },
        {
            name: "Pickup",
            count:
                orderCountPerStatus?.find((statuss: IOrderCount) => statuss.status === OrderStatus.PickUp)?.count || 0,
            orderStatus: OrderStatus.PickUp
        },
        {
            name: "New",
            count: orderCountPerStatus?.find((statuss: IOrderCount) => statuss.status === OrderStatus.New)?.count || 0,
            orderStatus: OrderStatus.New
        },
        {
            name: "Future shipping",
            count:
                orderCountPerStatus?.find((statuss: IOrderCount) => statuss.status === OrderStatus.FutureShipping)
                    ?.count || 0,
            orderStatus: OrderStatus.FutureShipping
        },
        {
            name: "Shipping",
            count:
                orderCountPerStatus?.find((statuss: IOrderCount) => statuss.status === OrderStatus.Shipping)?.count ||
                0,
            orderStatus: OrderStatus.Shipping
        },
        {
            name: "Warehouse",
            count:
                orderCountPerStatus?.find((statuss: IOrderCount) => statuss.status === OrderStatus.Warehouse)?.count ||
                0,
            orderStatus: OrderStatus.Warehouse
        },
        {
            name: "Shipped",
            count:
                orderCountPerStatus?.find((statuss: IOrderCount) => statuss.status === OrderStatus.Shipped)?.count || 0,
            orderStatus: OrderStatus.Shipped
        },
        {
            name: "Cancelled",
            count:
                orderCountPerStatus?.find((statuss: IOrderCount) => statuss.status === OrderStatus.Cancelled)?.count ||
                0,
            orderStatus: OrderStatus.Cancelled
        },
        {
            name: "Completed",
            count:
                orderCountPerStatus?.find((statuss: IOrderCount) => statuss.status === OrderStatus.Completed)?.count ||
                0,
            orderStatus: OrderStatus.Completed
        }
    ];

    return (
        <Fragment>
            {items.map((tabItem, index) => (
                <div
                    key={index}
                    onClick={() => handleChangeStatus(tabItem.orderStatus)}
                    className={cn(
                        "flex items-center justify-center px-4 pb-4 text-center text-sm transition-colors hover:text-primary cursor-pointer whitespace-nowrap",
                        tabItem.orderStatus === currentOrderStatus
                            ? "font-medium text-primary border-b-2 border-primary"
                            : "text-muted-foreground",
                        tabItem.disabled && "hidden"
                    )}
                >
                    <span className="mr-1.5">{tabItem.name}</span>
                    {tabItem.count > 0 && (
                        <Badge
                            className="rounded-full text-[10px] py-0.5 h-5 px-2"
                            color={tabItem.orderStatus === currentOrderStatus ? "purple" : "slate"}
                        >
                            {tabItem.count}
                        </Badge>
                    )}
                </div>
            ))}
        </Fragment>
    );
};
