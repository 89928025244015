import { activityTypes } from "@/activity";
import { TypographyH1 } from "@/components/custom/text/TypographyH1";
import { TypographyP } from "@/components/custom/text/TypographyP";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import Flex from "@/components/ui/flex";
import { Table, TableBody, TableCell, TableHeader, TableRow } from "@/components/ui/table";
import { OrderStatus } from "../constans/OrderStatus";

interface IActivityLogProps {
    activities: activityTypes.IActivity[];
}

export const ActivityLog = ({ activities }: IActivityLogProps) => {
    const convertToDate = (date: string) => {
        return new Date(date).toLocaleDateString("nl-NL", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric"
        });
    };

    const convertStatus = (status: number) => {
        return OrderStatus[status];
    };

    return (
        <div>
            <TypographyH1 className="sm:text-lg lg:text-3xl text-foreground font-semibold mb-0">
                Activity log
            </TypographyH1>
            <TypographyP className="text-muted-foreground mt-2 mb-8">
                Here you can see all activity for this order.
            </TypographyP>
            {activities
                .filter(activity => !!activity?.properties?.old)
                .map((activity, index) => {
                    return (
                        <div key={index} className="pl-2 ">
                            <div className="border-l pl-6 pt-8 relative">
                                <div className="absolute top-14 left-0 w-4 h-4 -translate-x-1/2 -translate-y-1/2 z-10 bg-primary rounded-full bg-slate-300" />
                                <Card key={index}>
                                    <CardContent className="p-0">
                                        <Table className="w-full p-0">
                                            <TableHeader>
                                                <TableRow className="bg-muted">
                                                    <TableCell>Property key</TableCell>
                                                    <TableCell>Current value</TableCell>
                                                    <TableCell>Previous value</TableCell>
                                                </TableRow>
                                            </TableHeader>
                                            <TableBody>
                                                {Object.keys(activity.properties?.attributes)
                                                    .filter(key => !!activity.properties?.attributes[key])
                                                    .filter(
                                                        key =>
                                                            activity.properties?.attributes[key] !==
                                                            activity.properties?.old[key]
                                                    )
                                                    .map((key: any, index: number) => {
                                                        let oldValue = activity.properties?.old[key];
                                                        let newValue = activity.properties?.attributes[key];

                                                        if (
                                                            [
                                                                "updated_at",
                                                                "created_at",
                                                                "latest_delivery_date_at"
                                                            ].includes(key)
                                                        ) {
                                                            oldValue = convertToDate(oldValue);
                                                            newValue = convertToDate(newValue);
                                                        }

                                                        if (key === "status") {
                                                            oldValue = convertStatus(oldValue);
                                                            newValue = convertStatus(newValue);
                                                        }

                                                        return (
                                                            <TableRow key={index}>
                                                                <TableCell>
                                                                    <p>{key}</p>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <p>{newValue}</p>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <p>{oldValue}</p>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </CardContent>
                                    <CardFooter className="bg-muted p-3 px-4 text-xs text-muted-foreground">
                                        <Flex>
                                            <Flex justifyContent="between" alignItems="center">
                                                <p>{convertToDate(activity.created_at)}</p>
                                                <p>
                                                    {activity.user?.first_name} {activity.user?.last_name || "System"}
                                                </p>
                                            </Flex>
                                        </Flex>
                                    </CardFooter>
                                </Card>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};
