import { queryClient } from "@/app";
import { authHooks } from "@/auth";
import { carrierHooks } from "@/carrier";
import { channelHooks } from "@/channel";
import { DropdownMenu, IDropdownMenuItem } from "@/components/custom/dropdownMenu";
import { Pagination } from "@/components/custom/pagination";
import { SearchField } from "@/components/custom/search/search";
import { DataTableSkeleton } from "@/components/custom/table/DataTableSkeleton";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import Flex from "@/components/ui/flex";
import { cn } from "@/lib/utils";
import { QueueStatus } from "@/queue";
import { HasOneOfRoles, rbacUtils, Roles } from "@/rbac";
import { useQueryBuilder } from "@cgarciagarcia/react-query-builder";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { CarrierDialog } from "../components/CarrierDialog";
import { ExactDeliveryDialog } from "../components/ExactDeliveryDialog";
import { FailedOrderTable } from "../components/FailedOrderTable";
import { FilterDropDown } from "../components/FilterDropdown";
import { OrderTable } from "../components/OrderTable";
import { OrderStatus } from "../constans/OrderStatus";
import { QueryKeys } from "../constans/QueryKeys";
import {
    useDeleteFailedOrder,
    useForceQueueStatusToSuccess,
    useGetDomainOrders,
    useGetDownloadCsv,
    useGetFailedOrders,
    useGetOrderCountPerStatus,
    useGetShipmentLabels,
    useResetOrderAttachmentFlag,
    useUpdateExactDeliveryDate,
    useUpdateOrderStatus,
    useUpdateOrdersToWarehouse,
    useUpdateQueueStatus
} from "../hooks";
import { IOrder, IOrderCount, IQueue } from "../types";
import OrderSheet from "./OrderSheet";

export const OrderOverviewPage = () => {
    const tableApi = useRef<any>();
    const [orderId, setOrderId] = useState<number | undefined>(undefined);
    const [isOrderSheetOpen, setIsOrderSheetOpen] = useState<boolean>(false);
    const { activeDomain, user } = authHooks.useAuth();
    const [selectedOrders, setSelectedOrders] = useState<number[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [filters, setFilters] = useState<any | null>(null);
    const [newStatus, setNewStatus] = useState<number>(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const paramStatus: keyof typeof OrderStatus = (searchParams.get("status") as keyof typeof OrderStatus) || "New";
    const [activeStatusKey, setActiveStatusKey] = useState<any>(paramStatus);
    const [activeStatusValue, setActiveStatusValue] = useState<any>(OrderStatus[activeStatusKey]);
    const [page, setPage] = useState<number>(Number(searchParams.get("page") || 1));
    const [maxOrderItems, sexMaxOrderItems] = useState<number>(100);
    const queryBuilder = useQueryBuilder({
        filters: [
            {
                attribute: "status",
                value: [1]
            }
        ],
        params: {
            limit: [100],
            page: [1]
        }
    });
    const { watch } = useForm<{
        per_page: { label: string; value: number };
    }>({
        defaultValues: {
            per_page: {
                label: "100",
                value: maxOrderItems
            }
        }
    });
    const perPage = watch("per_page");
    const [isCarrierDialogOpen, setIsCarrierDialogOpen] = useState<boolean>(false);
    const [isExactDeliveryDateDialogOpen, setIsExactDeliveryDateDialogOpen] = useState<boolean>(false);
    const { data: orders, isLoading: isOrdersLoading } = useGetDomainOrders(activeDomain, queryBuilder);
    const { data: orderCountPerStatus } = useGetOrderCountPerStatus(activeDomain);
    const { data: channels = [] } = channelHooks.useGetChannels(activeDomain);
    const { data: failedOrders, isLoading: isFailedOrdersLoading } = useGetFailedOrders(activeDomain.id);
    const { mutateAsync: deleteFailedOrder } = useDeleteFailedOrder();
    const { mutateAsync: updateOrderToWarehouse } = useUpdateOrdersToWarehouse();
    const { mutateAsync: downloadCsv } = useGetDownloadCsv();
    const { mutateAsync: downloadShipmentLabels } = useGetShipmentLabels();
    const { mutateAsync: updateOrderStatus } = useUpdateOrderStatus();
    const { mutateAsync: updateExactDeliveryDate } = useUpdateExactDeliveryDate();
    const { mutateAsync: resetOrderAttachmentFlag } = useResetOrderAttachmentFlag();
    const { mutateAsync: updateQueueStatus } = useUpdateQueueStatus();
    const { mutateAsync: forceQueueStatusToSuccess } = useForceQueueStatusToSuccess();
    const { data: carriers, isLoading } = carrierHooks.useGetDomainCarriers(activeDomain.id, 1);

    useEffect(() => {
        sexMaxOrderItems(perPage.value);
        setPage(1);
    }, [perPage]);

    const handleChangeStatus = (orderStatusValue: number) => {
        const orderStatusKey = OrderStatus[Number(orderStatusValue)];

        if (activeStatusKey !== orderStatusKey) {
            setPage(1);
            setSearchQuery("");
            setFilters(null);
            tableApi.current?.unSelectAllRows();
        }

        setSearchParams({ status: orderStatusKey });
        setActiveStatusKey(orderStatusKey);
        setActiveStatusValue(orderStatusValue);
        // tableApi.current?.unSelectAllRows();

        queryBuilder
            .removeFilter("status")
            .removeParam("page")
            .removeParam("search")
            .setParam("page", [1])
            .filter("status", [orderStatusValue]);
    };

    const handleUpdateOrderStatus = (status: number) => {
        if (
            (status === OrderStatus.FutureShipping || status === OrderStatus.Shipping) &&
            activeStatusValue === OrderStatus.New
        ) {
            setIsCarrierDialogOpen(true);
            setNewStatus(status);
        } else {
            updateOrderStatus({
                orderIds: selectedOrders,
                newStatus: status,
                currentStatus: activeStatusValue
            }).then(() => {
                // tableApi.current?.unSelectAllRows();
            });
        }
    };

    const handleOnRowSelectionChange = (selectedRows: IOrder[]) => {
        setSelectedOrders(selectedRows.map(row => row.id));
    };

    const handleProcessToWarehouse = () => {
        const orderIds = tableApi.current?.getSelectedRows();
        if (orderIds) {
            updateOrderToWarehouse({
                orderIds,
                currentStatus: activeStatusValue
            }).then(() => {
                tableApi.current?.unSelectAllRows();

                queryBuilder.removeParam("page").setParam("page", [1]);
                queryClient.invalidateQueries(QueryKeys.OrderItems);
                queryClient.invalidateQueries(QueryKeys.OrderCount);
            });
        }
    };

    const handleCancelOrder = (order: IOrder) => {
        updateOrderStatus({
            orderIds: [order.id],
            newStatus: OrderStatus.Cancelled,
            currentStatus: activeStatusValue
        });
    };

    const handleUpdateExactDeliveryDate = (formValues: any) => {
        if (selectedOrders.length) {
            updateExactDeliveryDate({
                orderIds: selectedOrders as [],
                formValues
            }).then(() => {
                setIsExactDeliveryDateDialogOpen(false);
                // tableApi.current?.unSelectAllRows();
            });
        }
    };

    const handleSelectCarrier = (formValues: { id: number }) => {
        updateOrderStatus({
            orderIds: selectedOrders,
            newStatus,
            carrierId: formValues.id,
            currentStatus: activeStatusValue
        }).then(() => {
            setIsCarrierDialogOpen(false);
            // tableApi.current?.unSelectAllRows();
        });
    };

    const handleDownloadCsv = () => {
        downloadCsv({
            domainId: activeDomain.id,
            // orderIds: tableApi.current?.getSelectedRows() || [],
            orderIds: [],
            status: activeStatusValue,
            filters
        });
    };

    const handleQueueUpdate = (queue: IQueue) => {
        if (queue.status !== QueueStatus.Success && rbacUtils.hasOneOfRoles(user.roles, Roles.SuperAdmin)) {
            updateQueueStatus(queue);
        }
    };

    const handleResetOrderAttachementFlag = () => {
        resetOrderAttachmentFlag({
            orderIds: []
            // orderIds: tableApi.current?.getSelectedRows() || [],
        });
    };

    const handleForceQueueStatusToSuccess = () => {
        forceQueueStatusToSuccess({
            orderIds: []
            // orderIds: tableApi.current?.getSelectedRows() || [],
        });
    };

    const handleShipmentLabels = () => {
        downloadShipmentLabels({
            // orderIds: tableApi.current?.getSelectedRows() || [],
            orderIds: []
        });
    };

    const handleSearch = (query: string) => {
        setSearchQuery(query);
        queryBuilder
            .removeParam("page")
            .setParam("page", [1])
            .removeParam("search")
            .setParam("search", [query]);
    };

    const handleFilter = (filterQuery: any) => {
        setFilters(filterQuery);
        setPage(1);
    };

    const handleDeleteFailedOrder = (id: number) => {
        deleteFailedOrder(id);
    };

    const filterButtons = (items: IDropdownMenuItem[]) =>
        items.map((item: IDropdownMenuItem) => ({
            ...item,
            disabled: item.disabled || !item.show || !item.show.includes(activeStatusValue)
        }));

    const buttonItems: IDropdownMenuItem[] = [
        {
            name: "Download CSV",
            description: "CSV with customer details.",
            onClick: () => handleDownloadCsv(),
            show: [OrderStatus.Shipping],
            disabled: !selectedOrders.length
        },
        {
            name: "Download shipment labels",
            description: "You can only download shipment labels once.",
            onClick: () => handleShipmentLabels(),
            show: [OrderStatus.Warehouse],
            disabled: !selectedOrders.length
        },
        {
            name: "Shipping orders",
            description: "Orders shipped next working day.",
            onClick: () => handleUpdateOrderStatus(OrderStatus.Shipping),
            show: [OrderStatus.New, OrderStatus.FutureShipping],
            disabled: !selectedOrders.length
        },
        {
            name: "Future shipment",
            description: "Orders are not shipped next working day.",
            onClick: () => handleUpdateOrderStatus(OrderStatus.FutureShipping),
            show: [OrderStatus.New, OrderStatus.Shipping],
            disabled: !selectedOrders.length
        },
        {
            name: "To warehouse",
            description: "Download slips and move to warehosue",
            onClick: () => handleProcessToWarehouse(),
            show: [OrderStatus.Shipping, OrderStatus.PickUp],
            disabled: !selectedOrders.length
        },
        {
            name: "Completed",
            description: "Order completed, no further actions needed.",
            onClick: () => handleUpdateOrderStatus(OrderStatus.Completed),
            show: [],
            disabled: !selectedOrders.length
        },
        {
            name: "Reset attachment flag",
            description: "Reset the attachemnt, so it can be downloaded again. Admin only",
            onClick: () => handleResetOrderAttachementFlag(),
            show: [
                OrderStatus.New,
                OrderStatus.FutureShipping,
                OrderStatus.Shipping,
                OrderStatus.Warehouse,
                OrderStatus.Shipped
            ],
            disabled: false // !(selectedOrders.length && rbacUtils.hasOneOfRoles(user.roles, Roles.SuperAdmin)),
        },
        {
            name: "Force queue status",
            description:
                "Force all queue statusses of an order to be successfull, Wrong use can cause delivery of unpaid products!",
            onClick: () => handleForceQueueStatusToSuccess(),
            show: [OrderStatus.FutureShipping, OrderStatus.Shipping],
            disabled: !(selectedOrders.length && rbacUtils.hasOneOfRoles(user.roles, Roles.SuperAdmin))
        },
        {
            name: "Update exact delivery",
            description: "Mass update the exact delivery update",
            onClick: () => setIsExactDeliveryDateDialogOpen(true),
            show: [OrderStatus.FutureShipping, OrderStatus.Shipping],
            disabled: !selectedOrders.length
        }
    ];

    const tabItems: Array<{ name: string; count: number; orderStatus: number; disabled?: boolean }> = [
        {
            name: "ISSUES",
            count: failedOrders?.length || 0,
            disabled: failedOrders?.length === 0,
            orderStatus: 99
        },
        {
            name: "Pickup",
            count:
                orderCountPerStatus?.find((statuss: IOrderCount) => statuss.status === OrderStatus.PickUp)?.count || 0,
            orderStatus: OrderStatus.PickUp
        },
        {
            name: "New",
            count: orderCountPerStatus?.find((statuss: IOrderCount) => statuss.status === OrderStatus.New)?.count || 0,
            orderStatus: OrderStatus.New
        },
        {
            name: "Future shipping",
            count:
                orderCountPerStatus?.find((statuss: IOrderCount) => statuss.status === OrderStatus.FutureShipping)
                    ?.count || 0,
            orderStatus: OrderStatus.FutureShipping
        },
        {
            name: "Shipping",
            count:
                orderCountPerStatus?.find((statuss: IOrderCount) => statuss.status === OrderStatus.Shipping)?.count ||
                0,
            orderStatus: OrderStatus.Shipping
        },
        {
            name: "Warehouse",
            count:
                orderCountPerStatus?.find((statuss: IOrderCount) => statuss.status === OrderStatus.Warehouse)?.count ||
                0,
            orderStatus: OrderStatus.Warehouse
        },
        {
            name: "Shipped",
            count:
                orderCountPerStatus?.find((statuss: IOrderCount) => statuss.status === OrderStatus.Shipped)?.count || 0,
            orderStatus: OrderStatus.Shipped
        },
        {
            name: "Cancelled",
            count:
                orderCountPerStatus?.find((statuss: IOrderCount) => statuss.status === OrderStatus.Cancelled)?.count ||
                0,
            orderStatus: OrderStatus.Cancelled
        },
        {
            name: "Completed",
            count:
                orderCountPerStatus?.find((statuss: IOrderCount) => statuss.status === OrderStatus.Completed)?.count ||
                0,
            orderStatus: OrderStatus.Completed
        }
    ];

    return (
        <React.Fragment>
            <Flex
                alignItems="end"
                justifyContent="between"
                className={cn("mb-0 text-foreground/80 my-8 w-full border-b ")}
            >
                {tabItems.map((tabItem, index) => (
                    <div
                        key={index}
                        onClick={() => handleChangeStatus(tabItem.orderStatus)}
                        className={cn(
                            "flex items-center justify-center px-4 pb-4 text-center text-sm transition-colors hover:text-primary cursor-pointer whitespace-nowrap",
                            tabItem.orderStatus === activeStatusValue
                                ? "font-medium text-primary border-b-2 border-primary"
                                : "text-muted-foreground",
                            tabItem.disabled && "hidden"
                        )}
                    >
                        <span className="mr-1.5">{tabItem.name}</span>
                        {tabItem.count > 0 && (
                            <Badge
                                className="rounded-full text-[10px] py-0.5 h-5 px-2"
                                color={tabItem.orderStatus === activeStatusValue ? "purple" : "slate"}
                            >
                                {tabItem.count}
                            </Badge>
                        )}
                    </div>
                ))}
            </Flex>

            {activeStatusValue !== 99 &&
                (isOrdersLoading ? (
                    <DataTableSkeleton />
                ) : (
                    <Fragment>
                        <div className="flex justify-between mb-8">
                            <div className="flex items-center gap-16">
                                <h3
                                    className={cn(
                                        "flex items-center text-sm",
                                        selectedOrders.length ? "text-slate-600" : "text-slate-300"
                                    )}
                                >
                                    {`Selected ${selectedOrders.length} from ${orders?.data.length} orders`}
                                </h3>
                            </div>
                            <div className="flex gap-x-4">
                                <HasOneOfRoles role={[Roles.Admin, Roles.SuperAdmin, Roles.Developer]}>
                                    <DropdownMenu name="Actions" items={filterButtons(buttonItems)} />
                                </HasOneOfRoles>
                                <FilterDropDown
                                    formValues={filters}
                                    carriers={carriers?.data || []}
                                    channels={channels}
                                    onSubmit={handleFilter}
                                />
                                <HasOneOfRoles role={Roles.Admin}>
                                    <Button
                                        //   icon={ArrowDownTrayIcon}
                                        onClick={() => handleDownloadCsv()}
                                        variant="outline"
                                        disabled={true}
                                    >
                                        <span className="flex items-center h-full text-sm">
                                            Download CSV
                                            {selectedOrders.length > 0 && (
                                                <div className="ml-2">
                                                    <Badge>{selectedOrders.length}</Badge>
                                                </div>
                                            )}
                                        </span>
                                    </Button>
                                </HasOneOfRoles>
                            </div>
                        </div>
                        <Card className="overflow-hidden rounded-md p-0 shadow-3xl">
                            <CardHeader>
                                <Flex justifyContent="end">
                                    {/* <CalendarDateRangePicker
                                        onHandleChange={(values: any) => {
                                            const { from, to } = values;
                                            // queryBuilder
                                            //     .removeFilter("order_placed_at")
                                            //     .filter("order_placed_at", [
                                            //         format(from, "yyyy-MM-dd'T'HH:mm:00.000'Z'"),
                                            //         format(to, "yyyy-MM-dd'T'HH:mm:00.000'Z'")
                                            //     ]);
                                        }}
                                    /> */}
                                    <div className="w-64">
                                        <SearchField onChange={handleSearch} defaultValue={searchQuery} />
                                    </div>
                                </Flex>
                            </CardHeader>
                            <CardContent className="p-0">
                                <OrderTable
                                    activeStatus={activeStatusValue}
                                    orders={orders}
                                    onClickQueueStatus={handleQueueUpdate}
                                    onClickCancelOrder={handleCancelOrder}
                                    onRowSelectionChange={handleOnRowSelectionChange}
                                    onEditOrder={(order: IOrder) => {
                                        setOrderId(order.id), setIsOrderSheetOpen(true);
                                    }}
                                    ref={tableApi}
                                />
                            </CardContent>
                        </Card>

                        {orders?.data && (
                            <Pagination
                                className="mt-4"
                                meta={orders?.meta}
                                onPageChange={page => queryBuilder.removeParam("page").setParam("page", page)}
                            />
                        )}
                    </Fragment>
                ))}

            {activeStatusValue === 99 && (
                <Fragment>
                    {isFailedOrdersLoading ? (
                        <DataTableSkeleton />
                    ) : (
                        <Card className="overflow-hidden rounded-md p-0 shadow-3xl border-t-none">
                            <CardHeader>
                                <h3 className="text-sm font-medium">Failed {failedOrders?.length} orders to import</h3>
                            </CardHeader>
                            <CardContent className="p-0 border-none">
                                <FailedOrderTable
                                    failedOrders={failedOrders || []}
                                    onClickFixedOrder={handleDeleteFailedOrder}
                                />
                            </CardContent>
                        </Card>
                    )}
                </Fragment>
            )}

            <CarrierDialog
                carriers={carriers?.data || []}
                isOpen={isCarrierDialogOpen}
                onSubmit={(value: any) => handleSelectCarrier(value)}
                onClickClose={() => setIsCarrierDialogOpen(prevState => !prevState)}
            />

            <ExactDeliveryDialog
                isOpen={isExactDeliveryDateDialogOpen}
                onSubmit={(exactDeliveryDate: Date) => handleUpdateExactDeliveryDate(exactDeliveryDate)}
                onClickClose={() => setIsExactDeliveryDateDialogOpen(prevState => !prevState)}
            />

            <OrderSheet open={isOrderSheetOpen} onClose={() => setIsOrderSheetOpen(false)} orderId={orderId} />
        </React.Fragment>
    );
};
