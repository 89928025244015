import { useAuth } from "@/auth/hooks";
import { TypographyH3 } from "@/components/custom/text/TypographyH3";
import { TypographyP } from "@/components/custom/text/TypographyP";
import { Button } from "@/components/ui/button";
import Flex from "@/components/ui/flex";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { AsyncProductSelect } from "@/purchaseOrder/components/AsyncProductSelect";
import { useQueryBuilder } from "@cgarciagarcia/react-query-builder";
import { PlusSignIcon, Remove01Icon } from "@hugeicons/react";
import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useGetProducts, useRemoveChildFromParent, useUpdateParentProduct } from "../hooks";
import { IProduct } from "../types";

interface IChildProductFormProps {
    product: IProduct;
}

export const ChildProductForm = ({ product }: IChildProductFormProps) => {
    const { activeDomain } = useAuth();
    const queryBuilder = useQueryBuilder({
        params: {
            page: [1]
        }
    });
    const { data: products, isLoading: isProductLoading } = useGetProducts(activeDomain, queryBuilder);
    const { mutateAsync: updateParentProduct } = useUpdateParentProduct();
    const { mutateAsync: removeChildFromParent } = useRemoveChildFromParent(product.id);
    const form = useForm<IProduct>({
        defaultValues: product
    });

    const onSubmit = (data: IProduct) => {
        updateParentProduct(data);
    };

    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: "children",
        keyName: "_id"
    });

    const handleRemoveChild = (childProductId: number) => {
        removeChildFromParent(childProductId);
    };

    useEffect(() => {
        form.reset(product);
    }, [product]);

    return (
        <div>
            <TypographyH3>Product families</TypographyH3>
            <TypographyP className="mt-1">
                Great product families, so stock will be deducted from parent product when sold.
            </TypographyP>

            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className="my-4 gap-y-4 flex flex-col">
                        {fields.map((field, index) => {
                            console.log(field);
                            return (
                                <Flex key={field.id} alignItems="end" className="gap-x-4">
                                    <FormField
                                        key={field.id}
                                        control={form.control}
                                        name={`children.${index}`}
                                        render={({ field }) => (
                                            <FormItem className="grow">
                                                <FormControl>
                                                    <AsyncProductSelect
                                                        field={field}
                                                        label="Select child product"
                                                        form={form}
                                                        products={products?.data || []}
                                                        isLoading={isProductLoading}
                                                        identityId={product.identity_id}
                                                        handleChange={() => form.handleSubmit(onSubmit)()}
                                                        search={query => {
                                                            queryBuilder
                                                                .removeParam("search")
                                                                .setParam("search", query);
                                                        }}
                                                        {...field}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <Button
                                        icon={Remove01Icon}
                                        variant="ghost"
                                        className="hover:bg-transparent hover:text-primary grow-0"
                                        onClick={() => {
                                            remove(index);
                                            handleRemoveChild(field?.id);
                                        }}
                                    />
                                </Flex>
                            );
                        })}
                        <Button
                            icon={PlusSignIcon}
                            className="mt-4"
                            type="button"
                            size="sm"
                            onClick={() => {
                                append({
                                    // @ts-ignore
                                    id: null,
                                    name: "",
                                    product_name: ""
                                });
                                queryBuilder.removeParam("search");
                            }}
                        >
                            Add child product
                        </Button>
                    </div>
                </form>
            </Form>
        </div>
    );
};
