import { DataTable } from "@/components/custom/table";
import { Button } from "@/components/ui/button";
import Flex from "@/components/ui/flex";
import { ColumnDef } from "@tanstack/react-table";
import { IWarehouse } from "../types";

interface IWarehouseTableProps {
    warehouses: IWarehouse[];
    onEditClick: (warehosue: IWarehouse) => void;
}

export const WarehouseTable = ({ warehouses, onEditClick }: IWarehouseTableProps) => {
    const columns: ColumnDef<IWarehouse>[] = [
        {
            id: "id",
            header: "ID",
            accessorFn: warehouse => warehouse.id
        },
        {
            id: "address",
            header: "Address",
            cell: ({ row }) => (
                <Flex className="flex-col" justifyContent="start" alignItems="start">
                    <p>
                        {row.original.address} {row.original.number}{" "}
                    </p>{" "}
                    <p>
                        {row.original.zip_code} {row.original.city}
                    </p>
                </Flex>
            )
        },
        {
            id: "email",
            header: "Email",
            cell: ({ row }) => <div>{row.original.email}</div>
        },
        {
            id: "actions",
            header: "Actions",
            cell: ({ row }) => (
                <div>
                    <Button onClick={() => onEditClick(row.original)}>Edit</Button>
                </div>
            )
        }
    ];

    return <DataTable data={warehouses} columns={columns} />;
};
