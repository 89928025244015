import { config } from "@/app";
import { Tiptap } from "@/components/custom/tiptap/Tiptap";
import { Button } from "@/components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle
} from "@/components/ui/dialog";
import Flex from "@/components/ui/flex";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Textarea } from "@/components/ui/textarea";
import { CheckmarkCircle02Icon, RotateClockwiseIcon } from "@hugeicons/react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { IChannelProduct, IProduct } from "../types";
import { ProductAiLoadingForm } from "./ProductAiLoadingForm";

interface IProductAiDialogFormProps {
    open: boolean;
    channelProduct: IChannelProduct | undefined;
    product: IProduct;
    isLoading: boolean;
    onRegenerate: (channelProductId: IChannelProduct) => void;
    onSubmit: (data: IChannelProduct) => void;
    onClose: () => void;
}

export const ChannelProductDialogForm = ({
    open,
    onClose,
    product,
    channelProduct,
    onRegenerate,
    isLoading,
    onSubmit
}: IProductAiDialogFormProps) => {
    const { control, ...form } = useForm<IChannelProduct>({
        defaultValues: channelProduct
    });
    const channel = product.channels?.find(channel => channel.id === (channelProduct?.channel_id as any));

    useEffect(() => {
        form.reset(channelProduct);
    }, [channelProduct]);

    return (
        <Dialog open={open} onOpenChange={onClose}>
            <DialogContent className="max-w-3xl h-2/3 px-0 pb-0">
                <DialogHeader className="px-8">
                    {!isLoading && (
                        <Flex justifyContent="start" className="gap-x-2.5">
                            <img
                                className="rounded-md w-8 h-8"
                                src={`${config.appUrl}/assets/img/channels/${channel?.icon}`}
                                alt=""
                                width="20"
                                height="20"
                            />
                            <Flex justifyContent="start" flexDirection="col" alignItems="start">
                                <DialogTitle>{`${product.brand?.name} ${product.product_name}`}</DialogTitle>
                                <DialogDescription className="h-auto">{channel?.company_name}</DialogDescription>
                            </Flex>
                        </Flex>
                    )}
                </DialogHeader>
                <ScrollArea className="h-auto overflow-scroll px-8">
                    {isLoading ? (
                        <ProductAiLoadingForm />
                    ) : (
                        <Form control={control} {...form}>
                            <form
                                id="channel-product"
                                onSubmit={form.handleSubmit(onSubmit)}
                                className="px-1 gap-y-2 flex flex-col"
                            >
                                <FormField
                                    control={control}
                                    name="ai_meta_title"
                                    render={({ field }) => (
                                        <FormItem className="col-span-1">
                                            <FormLabel>Meta title</FormLabel>
                                            <FormControl>
                                                <Input placeholder="Brand" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={control}
                                    name="ai_meta_description"
                                    render={({ field }) => (
                                        <FormItem className="col-span-1">
                                            <FormLabel>Meta description</FormLabel>
                                            <FormControl>
                                                <Textarea className="h-20" placeholder="Brand" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={control}
                                    name="ai_content"
                                    render={({ field }) => (
                                        <FormItem className="col-span-1 mt-4">
                                            <FormLabel>Content</FormLabel>
                                            <FormControl>
                                                <Tiptap {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </form>
                        </Form>
                    )}
                </ScrollArea>
                <DialogFooter
                    className="px-8 py-3 items-center h-16"
                    style={{
                        boxShadow:
                            "0px -2px 4px 0px rgba(101, 101, 101, 0.01), 0px -4px 8px 0px rgba(101, 101, 101, 0.04)"
                    }}
                >
                    <Flex className="gap-x-2" justifyContent="end">
                        <Button disabled={isLoading} variant="ghost" onClick={() => onClose()}>
                            Cancel
                        </Button>
                        <Button
                            variant="outline"
                            icon={RotateClockwiseIcon}
                            disabled={isLoading || !channelProduct}
                            onClick={() => onRegenerate(channelProduct as IChannelProduct)}
                        >
                            Re-generated AI
                        </Button>
                        <Button disabled={isLoading} form="channel-product" icon={CheckmarkCircle02Icon} type="submit">
                            Approve
                        </Button>
                    </Flex>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
