import { useAuth, useLogout } from "@/auth/hooks";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import Flex from "@/components/ui/flex";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/utils";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild
} from "@headlessui/react";
import {
    DashboardSquare02Icon,
    DeliveryTruck02Icon,
    Flag02Icon,
    HelpCircleIcon,
    Invoice01Icon,
    Invoice04Icon,
    LiftTruckIcon,
    MailAtSign01Icon,
    ModernTvIcon,
    PlugSocketIcon,
    UserGroupIcon
} from "@hugeicons/react";
import { ChevronLeft, DotIcon, Settings2Icon, XIcon } from "lucide-react";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { AccountSwitcher } from "../components/account-switcher";
import { CommandDialog } from "../components/CommandDialog";
import { ILink, Nav } from "../Nav";

const dashboardLinks: ILink[] = [
    {
        title: "Dashboard",
        icon: DashboardSquare02Icon,
        to: "/",
        variant: "default"
    }
];

const generalLinks: ILink[] = [
    {
        title: "Orders",
        label: "128",
        icon: Invoice01Icon,
        to: "/orders/New",
        variant: "default"
    },
    {
        title: "Purchase orders",
        label: "128",
        icon: Invoice04Icon,
        to: "/purchase-orders/open",
        variant: "default"
    },
    {
        title: "Products",
        icon: ModernTvIcon,
        to: "/products",
        variant: "default"
    }
    // {
    //     title: "Returned products",
    //     label: "3",
    //     icon: TruckReturnIcon,
    //     to: "/return-delivery",
    // },
];

const adminLinks: ILink[] = [
    {
        title: "Warehouse",
        icon: LiftTruckIcon,
        to: "/warehouses",
        variant: "default"
    },
    {
        title: "Users",
        icon: UserGroupIcon,
        to: "/users",
        variant: "default"
    },
    {
        title: "Connections",
        icon: PlugSocketIcon,
        to: "/connections",
        variant: "default"
    },
    {
        title: "Carrier setup",
        icon: DeliveryTruck02Icon,
        to: "/carriers",
        variant: "default"
    },
    {
        title: "Mail setup",
        label: "",
        icon: MailAtSign01Icon,
        to: "/mail-setup",
        variant: "default"
    }
];

const bottomLinks: ILink[] = [
    {
        title: "Help center",
        icon: HelpCircleIcon,
        to: "/support",
        variant: "default"
    }
];

export const accounts = [
    {
        label: "E-win Trading group",
        email: "alicia@example.com",
        icon: (
            <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <title>Vercel</title>
                <path d="M24 22.525H0l12-21.05 12 21.05z" fill="currentColor" />
            </svg>
        )
    },
    {
        label: "Alicia Koch",
        email: "alicia@gmail.com",
        icon: (
            <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <title>Gmail</title>
                <path
                    d="M24 5.457v13.909c0 .904-.732 1.636-1.636 1.636h-3.819V11.73L12 16.64l-6.545-4.91v9.273H1.636A1.636 1.636 0 0 1 0 19.366V5.457c0-2.023 2.309-3.178 3.927-1.964L5.455 4.64 12 9.548l6.545-4.91 1.528-1.145C21.69 2.28 24 3.434 24 5.457z"
                    fill="currentColor"
                />
            </svg>
        )
    },
    {
        label: "Alicia Koch",
        email: "alicia@me.com",
        icon: (
            <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <title>iCloud</title>
                <path
                    d="M13.762 4.29a6.51 6.51 0 0 0-5.669 3.332 3.571 3.571 0 0 0-1.558-.36 3.571 3.571 0 0 0-3.516 3A4.918 4.918 0 0 0 0 14.796a4.918 4.918 0 0 0 4.92 4.914 4.93 4.93 0 0 0 .617-.045h14.42c2.305-.272 4.041-2.258 4.043-4.589v-.009a4.594 4.594 0 0 0-3.727-4.508 6.51 6.51 0 0 0-6.511-6.27z"
                    fill="currentColor"
                />
            </svg>
        )
    }
];

export const LayoutWithSidebar = () => {
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
    const auth = useAuth();
    const { mutateAsync: logout } = useLogout();

    const userNavigation = [
        { name: "Your profile", to: "#" },
        { name: "Sign out", onClick: () => logout() }
    ];

    return (
        <>
            <div className="">
                <Dialog open={sidebarOpen} onClose={setSidebarOpen} className="relative z-50 lg:hidden">
                    <DialogBackdrop
                        transition
                        className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
                    />

                    <div className="fixed inset-0 flex">
                        <DialogPanel
                            transition
                            className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
                        >
                            <TransitionChild>
                                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                                    <button
                                        type="button"
                                        onClick={() => setSidebarOpen(false)}
                                        className="-m-2.5 p-2.5"
                                    >
                                        <span className="sr-only">Close sidebar</span>
                                        <XIcon aria-hidden="true" className="h-6 w-6 text-white" />
                                    </button>
                                </div>
                            </TransitionChild>
                            {/* Sidebar component, swap this element with another sidebar if you like */}
                            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
                                <div className="flex h-16 shrink-0 items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" className="h-6 w-6">
                                        <rect width="256" height="256" fill="none"></rect>
                                        <line
                                            x1="208"
                                            y1="128"
                                            x2="128"
                                            y2="208"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="16"
                                        ></line>
                                        <line
                                            x1="192"
                                            y1="40"
                                            x2="40"
                                            y2="192"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="16"
                                        ></line>
                                    </svg>
                                </div>
                                <nav className="flex flex-1 flex-col">
                                    <Nav isCollapsed={isCollapsed} links={generalLinks} />
                                    <div className="text-xs font-semibold leading-6 text-gray-400">
                                        <div className="hidden">Your teams</div>
                                    </div>
                                    <Nav isCollapsed={isCollapsed} links={bottomLinks} />
                                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                                        <li>
                                            <ul role="list" className="-mx-2 space-y-1">
                                                {/* <Nav isCollapsed={isCollapsed} links={links} /> */}
                                            </ul>
                                        </li>
                                        <li>
                                            <div className="text-xs font-semibold leading-6 text-gray-400">
                                                <div className="text-red-400">Your teams</div>
                                            </div>
                                        </li>
                                        <li className="mt-auto">
                                            <a
                                                href="#"
                                                className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                                            >
                                                <Settings2Icon aria-hidden="true" className="h-6 w-6 shrink-0" />
                                                Settings
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </DialogPanel>
                    </div>
                </Dialog>

                {/* Static sidebar for desktop */}
                <div
                    className={cn(
                        "hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:flex-col border-r bg-zinc-50 border-slate-200",
                        "transition-[width] duration-300 transform bg-white shadow-lg",
                        isCollapsed && "lg:w-16",
                        !isCollapsed && "lg:w-64"
                    )}
                >
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex grow flex-col gap-y-5 overflow-y-auto px-4 pb-4 peer">
                        <div className="flex h-16 shrink-0 items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" className="h-6 w-6">
                                <rect width="256" height="256" fill="none"></rect>
                                <line
                                    x1="208"
                                    y1="128"
                                    x2="128"
                                    y2="208"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="16"
                                ></line>
                                <line
                                    x1="192"
                                    y1="40"
                                    x2="40"
                                    y2="192"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="16"
                                ></line>
                            </svg>
                        </div>

                        <Flex
                            justifyContent="center"
                            alignItems="center"
                            onClick={() => setIsCollapsed(() => !isCollapsed)}
                            className={cn(
                                "border bg-white w-7 h-7 shadow-sm hover:shadow-lg rounded-full absolute -translate-x-1/2 -translate-y-1/2 top-8",
                                "transition-all duration-300 transform",
                                isCollapsed && "left-16",
                                !isCollapsed && "left-64"
                            )}
                        >
                            <div
                                className={cn(
                                    "transition-all delay-300 duration-300 transform",
                                    isCollapsed && "rotate-180",
                                    !isCollapsed && "rotate-0"
                                )}
                            >
                                <ChevronLeft size="18" />
                            </div>
                        </Flex>

                        <AccountSwitcher accounts={accounts} isCollapsed={isCollapsed} />
                        <nav className="flex flex-1 flex-col">
                            <ul role="list" className="flex flex-1 flex-col gap-y-7">
                                <li>
                                    <ul role="list" className="-mx-2 space-y-1">
                                        <Nav isCollapsed={isCollapsed} links={dashboardLinks} />
                                        <div className="text-xs font-semibold leading-6 text-gray-400 ml-3 pt-2.5 h-5">
                                            {!isCollapsed ? <div>Your teams</div> : <Separator />}
                                        </div>
                                        <Nav isCollapsed={isCollapsed} links={generalLinks} />
                                        <div className="text-xs font-semibold leading-6 text-gray-400 ml-3 pt-2.5 h-5">
                                            {!isCollapsed ? <div>Admin</div> : <Separator />}
                                        </div>
                                        <Nav isCollapsed={isCollapsed} links={adminLinks} />
                                    </ul>
                                </li>
                                <li className="mt-auto border-t">
                                    <Nav isCollapsed={isCollapsed} links={bottomLinks} />
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div
                    className={cn(
                        "transition-all duration-300 transform",
                        !isCollapsed && "lg:pl-64",
                        isCollapsed && "lg:pl-16"
                    )}
                >
                    <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4  sm:gap-x-6 sm:px-6 lg:px-8">
                        <button
                            type="button"
                            onClick={() => setSidebarOpen(true)}
                            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                        >
                            <span className="sr-only">Open sidebar</span>
                            <DotIcon aria-hidden="true" className="h-6 w-6" />
                        </button>

                        {/* Separator */}
                        <div aria-hidden="true" className="h-6 w-px bg-gray-900/10 lg:hidden" />

                        <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                            <Flex className="basis-1/4">
                                <h3 className="text-lg font-semibold hidden">Products</h3>
                                {/* <Flex className="h-full">
                                    <div className="h-full bg-primary text-primary-foreground">
                                        primary
                                    </div>
                                    <div className="h-full bg-card-foreground text-card">
                                        Card
                                    </div>
                                    <div className="h-full bg-muted-foreground text-muted">
                                        muted
                                    </div>
                                    <div className="h-full bg-background-foreground text-background">
                                        FG
                                    </div>
                                    <div className="h-full bg-popover-foreground text-popover">
                                        popover
                                    </div>
                                    <div className="h-full bg-accent-foreground text-accent">
                                        Accent
                                    </div>
                                    <div className="h-full bg-secondary-foreground text-seconday">
                                        secondary
                                    </div>
                                </Flex> */}
                            </Flex>
                            <div className="basis-1/4" />
                            <div className="basis-1/4 hidden">
                                <Flex alignItems="center" justifyContent="center" className="h-full">
                                    <Input className="border w-80 bg-zinc-50 rounded-full" placeholder="Search" />
                                </Flex>
                            </div>

                            <Flex justifyContent="end" className="gap-x-4 lg:gap-x-6 basis-2/4 w-32">
                                <Flex alignItems="center" justifyContent="end" className="gap-x-2.5 w-96">
                                    <button
                                        type="button"
                                        className="text-gray-400 hover:text-gray-500 bg-primary/10 rounded-full w-8 h-8 flex items-center justify-center"
                                    >
                                        <span className="sr-only">View notifications</span>
                                        {/* <BellIcon aria-hidden="true" className="h-5 w-5" /> */}
                                        <Flag02Icon className="text-primary w-4 h-4" />
                                    </button>
                                    <div className="text-sm">1/3</div>
                                </Flex>
                                {/* Separator */}
                                <div aria-hidden="true" className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" />
                                {/* Profile dropdown */}
                                <Menu as="div" className="relative">
                                    <MenuButton className="-m-1.5 flex items-center p-1.5">
                                        <span className="sr-only">Open user menu</span>
                                        <Avatar className="h-8 w-8">
                                            <AvatarFallback>{auth.user.full_name}</AvatarFallback>
                                            <AvatarImage src={auth.user.avatar} />
                                        </Avatar>
                                        <Flex
                                            justifyContent="start"
                                            alignItems="start"
                                            flexDirection="col"
                                            className="hidden lg:flex py-3 text-xs text-left ml-4 w-40"
                                        >
                                            <span
                                                aria-hidden="true"
                                                className=" text-sm font-semibold leading-6 text-gray-900"
                                            >
                                                {auth.user.full_name}
                                            </span>
                                            {auth.user.roles[0].name}
                                        </Flex>
                                    </MenuButton>
                                    <MenuItems
                                        transition
                                        className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                    >
                                        {userNavigation.map(item => (
                                            <MenuItem key={item.name}>
                                                <Button
                                                    variant={"ghost"}
                                                    onClick={item.onClick}
                                                    className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                                                >
                                                    {item.name}
                                                </Button>
                                            </MenuItem>
                                        ))}
                                    </MenuItems>
                                </Menu>
                            </Flex>
                        </div>
                    </div>

                    <main className="py-6 bg-slate-50">
                        <div className="sm:px-12 lg:px-12">
                            <Outlet />
                        </div>
                    </main>
                </div>
            </div>
            <CommandDialog />
        </>
    );
};
