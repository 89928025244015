import { useQuery } from "react-query";

import { commonTypes } from "@/common";
import { statisticTypes } from ".";
import { getOrdersByDay } from "./queries";

export const useGetOrdersByDay = (domainId: number, date: string, options?: commonTypes.IUseGetOptions) =>
    useQuery<statisticTypes.IOrdersByDay[], Error>({
        queryKey: ["orders-by-day", domainId, date],
        queryFn: () => getOrdersByDay(domainId, date),
        ...options
    });
