import { queryClient } from "@/app";
import { authHooks } from "@/auth";
import { carrierHooks } from "@/carrier";
import { channelHooks } from "@/channel";
import { DropdownMenu, IDropdownMenuItem } from "@/components/custom/dropdownMenu";
import { Pagination } from "@/components/custom/pagination";
import { SearchField } from "@/components/custom/search/search";
import { DataTableSkeleton } from "@/components/custom/table/DataTableSkeleton";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import Flex from "@/components/ui/flex";
import { cn } from "@/lib/utils";
import { QueueStatus } from "@/queue";
import { HasOneOfRoles, rbacUtils, Roles } from "@/rbac";
import { useQueryBuilder } from "@cgarciagarcia/react-query-builder";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import { CarrierDialog } from "../components/CarrierDialog";
import { ExactDeliveryDialog } from "../components/ExactDeliveryDialog";
import { FailedOrderTable } from "../components/FailedOrderTable";
import { FilterDropDown } from "../components/FilterDropdown";
import { OrderTable } from "../components/OrderTable";
import { TabItems } from "../components/TabItems";
import { OrderStatus } from "../constans/OrderStatus";
import { QueryKeys } from "../constans/QueryKeys";
import {
    useDeleteFailedOrder,
    useForceQueueStatusToSuccess,
    useGetDomainOrders,
    useGetDownloadCsv,
    useGetFailedOrders,
    useGetOrderCountPerStatus,
    useGetShipmentLabels,
    useResetOrderAttachmentFlag,
    useUpdateExactDeliveryDate,
    useUpdateOrderStatus,
    useUpdateOrdersToWarehouse,
    useUpdateQueueStatus
} from "../hooks";
import { IOrder, IQueue } from "../types";
import OrderSheet from "./OrderSheet";
export const OrderOverviewPage = () => {
    const tableApi = useRef<any>();
    const navigate = useNavigate();
    const { orderStatus = OrderStatus.New, orderId = undefined, ...rest } = useParams<{
        orderStatus: keyof typeof OrderStatus;
        orderId?: string;
    }>();
    const { activeDomain, user } = authHooks.useAuth();
    const [isOrderSheetOpen, setIsOrderSheetOpen] = useState<boolean>(orderId !== undefined);
    const [selectedOrders, setSelectedOrders] = useState<number[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [filters, setFilters] = useState<any | null>(null);
    const [newStatus, setNewStatus] = useState<number>(0);
    const [maxOrderItems, sexMaxOrderItems] = useState<number>(100);
    const queryBuilder = useQueryBuilder({
        filters: [{ attribute: "status", value: [OrderStatus[orderStatus]] }],
        params: { limit: [100], page: [1] }
    });
    const { watch } = useForm<{
        per_page: { label: string; value: number };
    }>({
        defaultValues: {
            per_page: {
                label: "100",
                value: maxOrderItems
            }
        }
    });
    const perPage = watch("per_page");
    const [isCarrierDialogOpen, setIsCarrierDialogOpen] = useState<boolean>(false);
    const [isExactDeliveryDateDialogOpen, setIsExactDeliveryDateDialogOpen] = useState<boolean>(false);
    const { data: orders, isLoading: isOrdersLoading } = useGetDomainOrders(activeDomain, queryBuilder);
    const { data: orderCountPerStatus = [] } = useGetOrderCountPerStatus(activeDomain);
    const { data: channels = [] } = channelHooks.useGetChannels(activeDomain);
    const { data: failedOrders = [], isLoading: isFailedOrdersLoading } = useGetFailedOrders(activeDomain.id);
    const { mutateAsync: deleteFailedOrder } = useDeleteFailedOrder();
    const { mutateAsync: updateOrderToWarehouse } = useUpdateOrdersToWarehouse();
    const { mutateAsync: downloadCsv } = useGetDownloadCsv();
    const { mutateAsync: downloadShipmentLabels } = useGetShipmentLabels();
    const { mutateAsync: updateOrderStatus } = useUpdateOrderStatus();
    const { mutateAsync: updateExactDeliveryDate } = useUpdateExactDeliveryDate();
    const { mutateAsync: resetOrderAttachmentFlag } = useResetOrderAttachmentFlag();
    const { mutateAsync: updateQueueStatus } = useUpdateQueueStatus();
    const { mutateAsync: forceQueueStatusToSuccess } = useForceQueueStatusToSuccess();
    const { data: carriers } = carrierHooks.useGetDomainCarriers(activeDomain.id, 1);

    const handleChangeStatus = (orderStatusValue: number) => {
        const orderStatusKey = OrderStatus[Number(orderStatusValue)];

        tableApi.current?.toggleAllRowsSelected(false);
        setSearchQuery("");
        setFilters(null);

        navigate(`/orders/${orderStatusKey}`);

        queryBuilder
            .removeFilter("status")
            .removeParam("page")
            .removeParam("search")
            .setParam("page", [1])
            .filter("status", [orderStatusValue]);
    };

    const handleUpdateOrderStatus = (status: number) => {
        if (
            (status === OrderStatus.FutureShipping || status === OrderStatus.Shipping) &&
            OrderStatus[orderStatus as keyof typeof OrderStatus] === OrderStatus.New
        ) {
            setIsCarrierDialogOpen(true);
            setNewStatus(status);
        } else {
            updateOrderStatus({
                orderIds: selectedOrders,
                newStatus: status,
                currentStatus: OrderStatus[orderStatus as keyof typeof OrderStatus]
            }).then(() => {
                tableApi.current?.toggleAllRowsSelected(false);
                toast.success("Order status updated", {
                    description: `${selectedOrders.length} orders updated`,
                    className: "bg-green-500 text-white"
                });
            });
        }
    };

    const handleOnRowSelectionChange = (selectedRows: IOrder[]) => {
        setSelectedOrders(selectedRows.map(row => row.id));
    };

    const handleProcessToWarehouse = () => {
        const orderIds = selectedOrders;

        if (orderIds) {
            updateOrderToWarehouse({
                orderIds,
                currentStatus: OrderStatus[orderStatus as keyof typeof OrderStatus]
            }).then(() => {
                tableApi.current?.toggleAllRowsSelected(false);

                queryBuilder.removeParam("page").setParam("page", [1]);
                queryClient.invalidateQueries(QueryKeys.OrderItems);
                queryClient.invalidateQueries(QueryKeys.OrderCount);

                toast.success("Orders processed to warehouse", {
                    description: `${orderIds.length} orders updated`,
                    className: "bg-green-500 text-white"
                });
            });
        }
    };

    const handleCancelOrder = (order: IOrder) => {
        updateOrderStatus({
            orderIds: [order.id],
            newStatus: OrderStatus.Cancelled,
            currentStatus: OrderStatus[orderStatus as keyof typeof OrderStatus]
        });
    };

    const handleUpdateExactDeliveryDate = (formValues: any) => {
        const exactDeliveryDate = new Date(formValues.exact_delivery_date_at);
        exactDeliveryDate.setHours(12, 0, 0, 0); // Set time to 12:00:00

        if (selectedOrders.length) {
            updateExactDeliveryDate({
                orderIds: selectedOrders,
                formValues: {
                    exact_delivery_date_at: exactDeliveryDate
                }
            })
                .then(() => {
                    toast.success("Exact delivery date updated", {
                        description: `${selectedOrders.length} orders updated`,
                        className: "bg-green-500 text-white"
                    });
                    setIsExactDeliveryDateDialogOpen(false);
                    tableApi.current?.toggleAllRowsSelected(false);
                })
                .catch(error => {
                    toast.error("Error updating exact delivery date", {
                        description: error.message,
                        className: "bg-red-500 text-white"
                    });
                });
        }
    };

    const handleSelectCarrier = (formValues: { id: number }) => {
        updateOrderStatus({
            orderIds: selectedOrders,
            newStatus,
            carrierId: formValues.id,
            currentStatus: OrderStatus[orderStatus as keyof typeof OrderStatus]
        }).then(() => {
            setIsCarrierDialogOpen(false);
            tableApi.current?.toggleAllRowsSelected(false);
        });
    };

    const handleDownloadCsv = () => {
        downloadCsv({
            domainId: activeDomain.id,
            orderIds: selectedOrders,
            status: OrderStatus[orderStatus as keyof typeof OrderStatus],
            filters
        });

        tableApi.current?.toggleAllRowsSelected(false);
    };

    const handleQueueUpdate = (queue: IQueue) => {
        if (queue.status !== QueueStatus.Success && rbacUtils.hasOneOfRoles(user.roles, Roles.SuperAdmin)) {
            updateQueueStatus(queue);
        }
    };

    const handleResetOrderAttachementFlag = () => {
        resetOrderAttachmentFlag({
            orderIds: selectedOrders
        });
    };

    const handleForceQueueStatusToSuccess = () => {
        forceQueueStatusToSuccess({
            orderIds: selectedOrders
        });
    };

    const handleShipmentLabels = () => {
        downloadShipmentLabels({
            orderIds: selectedOrders
        });
    };

    const handleSearch = (query: string) => {
        setSearchQuery(query);
        queryBuilder
            .removeParam("page")
            .setParam("page", [1])
            .removeParam("search")
            .setParam("search", [query]);
    };

    const handleEditOrder = (order: IOrder) => {
        navigate(`/orders/${orderStatus}/${order.id}`);
        setIsOrderSheetOpen(true);
    };

    const handleCloseOrderSheet = () => {
        navigate(`/orders/${orderStatus}`);
        setIsOrderSheetOpen(false);
    };

    const handleFilter = (filterQuery: any) => {
        setFilters(filterQuery);
        // setPage(1);
    };

    const handleDeleteFailedOrder = (id: number) => {
        deleteFailedOrder(id);
    };

    const filterButtons = (items: IDropdownMenuItem[]) =>
        items.map((item: IDropdownMenuItem) => ({
            ...item,
            disabled:
                item.disabled || !item.show || !item.show.includes(OrderStatus[orderStatus as keyof typeof OrderStatus])
        }));

    const buttonItems: IDropdownMenuItem[] = [
        {
            name: "Download CSV",
            description: "CSV with customer details.",
            onClick: () => handleDownloadCsv(),
            show: [OrderStatus.Shipping],
            disabled: !selectedOrders.length
        },
        {
            name: "Download shipment labels",
            description: "You can only download shipment labels once.",
            onClick: () => handleShipmentLabels(),
            show: [OrderStatus.Warehouse],
            disabled: !selectedOrders.length
        },
        {
            name: "Shipping orders",
            description: "Orders shipped next working day.",
            onClick: () => handleUpdateOrderStatus(OrderStatus.Shipping),
            show: [OrderStatus.New, OrderStatus.FutureShipping],
            disabled: !selectedOrders.length
        },
        {
            name: "Future shipment",
            description: "Orders are not shipped next working day.",
            onClick: () => handleUpdateOrderStatus(OrderStatus.FutureShipping),
            show: [OrderStatus.New, OrderStatus.Shipping],
            disabled: !selectedOrders.length
        },
        {
            name: "To warehouse",
            description: "Download slips and move to warehosue",
            onClick: () => handleProcessToWarehouse(),
            show: [OrderStatus.Shipping, OrderStatus.PickUp],
            disabled: !selectedOrders.length
        },
        {
            name: "Completed",
            description: "Order completed, no further actions needed.",
            onClick: () => handleUpdateOrderStatus(OrderStatus.Completed),
            show: [],
            disabled: !selectedOrders.length
        },
        {
            name: "Reset attachment flag",
            description: "Reset the attachemnt, so it can be downloaded again. Admin only",
            onClick: () => handleResetOrderAttachementFlag(),
            show: [
                OrderStatus.New,
                OrderStatus.FutureShipping,
                OrderStatus.Shipping,
                OrderStatus.Warehouse,
                OrderStatus.Shipped
            ],
            disabled: !(selectedOrders.length && rbacUtils.hasOneOfRoles(user.roles, Roles.SuperAdmin))
        },
        {
            name: "Force queue status",
            description:
                "Force all queue statusses of an order to be successfull, Wrong use can cause delivery of unpaid products!",
            onClick: () => handleForceQueueStatusToSuccess(),
            show: [OrderStatus.FutureShipping, OrderStatus.Shipping],
            disabled: !(selectedOrders.length && rbacUtils.hasOneOfRoles(user.roles, Roles.SuperAdmin))
        },
        {
            name: "Update exact delivery",
            description: "Mass update the exact delivery update",
            onClick: () => setIsExactDeliveryDateDialogOpen(true),
            show: [OrderStatus.FutureShipping, OrderStatus.Shipping],
            disabled: !selectedOrders.length
        }
    ];

    useEffect(() => {
        sexMaxOrderItems(perPage.value);
        // setPage(1);
    }, [perPage]);

    return (
        <React.Fragment>
            <Flex
                alignItems="end"
                justifyContent="between"
                className={cn("mb-0 text-foreground/80 my-8 w-full border-b ")}
            >
                <TabItems
                    failedOrders={failedOrders}
                    orderCountPerStatus={orderCountPerStatus}
                    handleChangeStatus={handleChangeStatus}
                    currentOrderStatus={OrderStatus[orderStatus as keyof typeof OrderStatus]}
                />
            </Flex>

            {OrderStatus[orderStatus] !== 99 &&
                (isOrdersLoading ? (
                    <DataTableSkeleton />
                ) : (
                    <Fragment>
                        <div className="flex justify-between mb-8">
                            <div className="flex items-center gap-16">
                                <h3
                                    className={cn(
                                        "flex items-center text-sm",
                                        selectedOrders.length ? "text-slate-600" : "text-slate-300"
                                    )}
                                >
                                    {`Selected ${selectedOrders.length} from ${orders?.data.length} orders`}
                                </h3>
                            </div>
                            <div className="flex gap-x-4">
                                <HasOneOfRoles role={[Roles.Admin, Roles.SuperAdmin, Roles.Developer]}>
                                    <DropdownMenu name="Actions" items={filterButtons(buttonItems)} />
                                </HasOneOfRoles>
                                <FilterDropDown
                                    formValues={filters}
                                    carriers={carriers?.data || []}
                                    channels={channels}
                                    onSubmit={handleFilter}
                                />
                                <HasOneOfRoles role={Roles.Admin}>
                                    <Button
                                        //   icon={ArrowDownTrayIcon}
                                        onClick={() => handleDownloadCsv()}
                                        variant="outline"
                                        disabled={true}
                                    >
                                        <span className="flex items-center h-full text-sm">
                                            Download CSV
                                            {selectedOrders.length > 0 && (
                                                <div className="ml-2">
                                                    <Badge>{selectedOrders.length}</Badge>
                                                </div>
                                            )}
                                        </span>
                                    </Button>
                                </HasOneOfRoles>
                            </div>
                        </div>
                        <Card className="overflow-hidden rounded-md p-0 shadow-3xl">
                            <CardHeader>
                                <Flex justifyContent="end">
                                    <div className="w-64">
                                        <SearchField onChange={handleSearch} defaultValue={searchQuery} />
                                    </div>
                                </Flex>
                            </CardHeader>
                            <CardContent className="p-0">
                                <OrderTable
                                    activeStatus={OrderStatus[orderStatus as keyof typeof OrderStatus]}
                                    orders={orders}
                                    onClickQueueStatus={handleQueueUpdate}
                                    onClickCancelOrder={handleCancelOrder}
                                    onRowSelectionChange={handleOnRowSelectionChange}
                                    onEditOrder={handleEditOrder}
                                    ref={tableApi}
                                />
                            </CardContent>
                        </Card>

                        {orders?.data && (
                            <Pagination
                                className="mt-4"
                                meta={orders?.meta}
                                onPageChange={page => queryBuilder.removeParam("page").setParam("page", page)}
                            />
                        )}
                    </Fragment>
                ))}

            {OrderStatus[orderStatus] === 99 && (
                <Fragment>
                    {isFailedOrdersLoading ? (
                        <DataTableSkeleton />
                    ) : (
                        <Card className="overflow-hidden rounded-md p-0 shadow-3xl border-t-none">
                            <CardHeader>
                                <h3 className="text-sm font-medium">Failed {failedOrders?.length} orders to import</h3>
                            </CardHeader>
                            <CardContent className="p-0 border-none">
                                <FailedOrderTable
                                    failedOrders={failedOrders || []}
                                    onClickFixedOrder={handleDeleteFailedOrder}
                                />
                            </CardContent>
                        </Card>
                    )}
                </Fragment>
            )}

            <CarrierDialog
                carriers={carriers?.data || []}
                isOpen={isCarrierDialogOpen}
                onSubmit={(value: any) => handleSelectCarrier(value)}
                onClickClose={() => setIsCarrierDialogOpen(prevState => !prevState)}
            />

            <ExactDeliveryDialog
                isOpen={isExactDeliveryDateDialogOpen}
                onSubmit={(exactDeliveryDate: Date) => handleUpdateExactDeliveryDate(exactDeliveryDate)}
                onClickClose={() => setIsExactDeliveryDateDialogOpen(prevState => !prevState)}
            />

            <OrderSheet
                open={isOrderSheetOpen}
                onClose={handleCloseOrderSheet}
                orderId={orderId ? Number(orderId) : undefined}
            />
        </React.Fragment>
    );
};
