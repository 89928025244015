import Flex from "@/components/ui/flex";
import { Tabs, TabsContent, TabsList } from "@/components/ui/tabs";

import { authHooks } from "@/auth";
import { channelHooks } from "@/channel";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Sheet, SheetContent, SheetDescription, SheetFooter, SheetTitle } from "@/components/ui/sheet";
import { Skeleton } from "@/components/ui/skeleton";
import { TooltipProvider } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { EanInputForm } from "@/order/components/EanInputForm";
import { useGetProductOrders } from "@/order/hooks";
import { useQueryBuilder } from "@cgarciagarcia/react-query-builder";
import {
    AppleStocksIcon,
    BarCode02Icon,
    ChartRelationshipIcon,
    DrawingModeIcon,
    Image02Icon,
    InformationCircleIcon,
    Invoice01Icon,
    Pdf02Icon,
    WorkHistoryIcon
} from "@hugeicons/react";
import { TabsTrigger } from "@radix-ui/react-tabs";
import { Fragment, useEffect, useState } from "react";
import { ActivityLog } from "../../components/custom/ActivityLog/ActivityLog";
import { ChannelProductDialogForm } from "../components/ChannelProductDialogForm";
import { ChildProductForm } from "../components/ChildProducForm";
import { EanForm } from "../components/EanForm";
import { ProductAiLoadingForm } from "../components/ProductAiLoadingForm";
import { ProductChannelTable } from "../components/ProductChannelTable";
import { ProductDashboard } from "../components/ProductDashboard";
import { ProductFeatureForm } from "../components/ProductFeatureForm";
import { ProductForm } from "../components/ProductForm";
import { ProductMediaForm } from "../components/ProductMediaForm";
import { ProductOrders } from "../components/ProductOrders";
import { WarehouseStockForm } from "../components/WarehouseStockForm";
import {
    useCreateChannelProductAiText,
    useGetProduct,
    useGetProductStockInformation,
    useImportProductIcecat,
    useRegenerateChannelProductAiText,
    useUpdateChannelProduct,
    useUpdateProductWarehouses
} from "../hooks";
import { IChannelProduct, IProductWarehouse } from "../types";

interface IProductSheetProps {
    productId?: number | null;
    open: boolean;
    handleClose: () => void;
    orderSheetOpen: boolean;
    handleShowOrder: (orderId: number) => void;
}

export const ProductSheet = ({
    open,
    productId: externalProductId,
    handleClose,
    orderSheetOpen,
    handleShowOrder
}: IProductSheetProps) => {
    const queryBuilder = useQueryBuilder({
        filters: [
            {
                attribute: "is_active",
                value: [1]
            }
        ]
    });
    const [isExternalProduct, setIsExternalProduct] = useState<boolean>(true);
    const [channelProduct, setChannelProduct] = useState<IChannelProduct | undefined>(undefined);
    const [showChannelProductDialogForm, setShowChannelProductDialogForm] = useState<boolean>(false);
    const [productId, setProductId] = useState<number | null>(null);
    const { activeDomain } = authHooks.useAuth();
    const { data: product, isLoading } = useGetProduct(productId || 0, { enabled: !!productId });
    const { data: orders = [] } = useGetProductOrders(productId || 0, {
        enabled: !!productId
    });
    const { data: channels = [] } = channelHooks.useGetChannels(activeDomain, queryBuilder, {
        enabled: !!activeDomain
    });
    const { data: productStockInformation } = useGetProductStockInformation(productId || 0, {
        enabled: !!productId
    });
    const { mutateAsync: updateChannelProduct } = useUpdateChannelProduct();
    const { mutateAsync: importProductIcecat, isLoading: isImportProductIcecatLoading } = useImportProductIcecat();
    const {
        mutateAsync: regenrateChannelProductAiText,
        isLoading: isLoadingRegenrateChannelProductAiText
    } = useRegenerateChannelProductAiText();
    const {
        mutateAsync: createChannelProductAiText,
        isLoading: isCreateChannelProductAiTextLoading
    } = useCreateChannelProductAiText(productId);
    const { mutateAsync: updateProductWarehouses } = useUpdateProductWarehouses(productId || 0);

    const handleCreateChannelProductAiText = async (channelId: number) => {
        setShowChannelProductDialogForm(true);

        if (productId && channelId) {
            createChannelProductAiText(channelId).then(res => {
                setChannelProduct(res);
            });
        }
    };

    const handleRegenerateChannelProductAiText = (channelProduct: IChannelProduct) => {
        regenrateChannelProductAiText(channelProduct);
    };

    const handleSuccesFullEanSubmit = (productId: number) => {
        setProductId(productId);
        setIsExternalProduct(true);
    };

    const handleWarehouseStockSubmit = (productWarehouses: IProductWarehouse[], reason: string) => {
        updateProductWarehouses({
            productWarehouses,
            reason
        });
    };

    const handleImportProductIcecat = (productId: number) => {
        importProductIcecat(productId);
    };

    const handleChannelProductClick = (channelProduct: IChannelProduct) => {
        setChannelProduct(channelProduct);
        setShowChannelProductDialogForm(true);
    };

    const handleChannelProductUpdateSubmit = (channelProduct: IChannelProduct) => {
        setShowChannelProductDialogForm(false);
        updateChannelProduct(channelProduct);
    };

    useEffect(() => {
        setIsExternalProduct(!!externalProductId);

        if (externalProductId) {
            setProductId(externalProductId);
        }
    }, [externalProductId]);

    return (
        <Fragment>
            <Sheet
                open={open}
                onOpenChange={() => {
                    handleClose();
                }}
            >
                <SheetTitle />
                <SheetDescription />
                <SheetContent className={cn("sm:max-w-3xl p-0", orderSheetOpen && "-translate-x-[49rem]")}>
                    <TooltipProvider>
                        <div className="h-full bg-muted">
                            {!isExternalProduct && (
                                <Flex className="h-full" alignItems="center" justifyContent="center">
                                    <EanInputForm onSuccess={handleSuccesFullEanSubmit} />
                                </Flex>
                            )}
                            {isLoading && (
                                <Flex className="h-full" alignItems="start">
                                    <Skeleton className="w-16 h-full bg-slate-200 p-1" />

                                    <div className="w-full mx-8 my-16">
                                        <ProductAiLoadingForm />
                                    </div>
                                </Flex>
                            )}

                            {isExternalProduct && (
                                <Tabs defaultValue="dashboard" className="h-full" orientation="vertical">
                                    <Flex alignItems="start" className="h-full">
                                        <div
                                            className={cn(
                                                "h-full transition-all duration-300 transform",
                                                !!product && "w-16 p-1",
                                                !product && "w-0 p-0 overflow-hidden"
                                            )}
                                        >
                                            <TabsList className="h-full flex flex-col justify-start py-4 gap-y-4">
                                                <TabsTrigger
                                                    value="dashboard"
                                                    className="data-[state=active]:text-primary"
                                                >
                                                    <Flex
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        className="bg-white w-10 h-10 rounded-full"
                                                    >
                                                        <InformationCircleIcon className="w-5 h-5" />
                                                    </Flex>
                                                </TabsTrigger>
                                                <TabsTrigger
                                                    value="channels"
                                                    className="data-[state=active]:text-primary"
                                                >
                                                    <Flex
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        className="bg-white w-10 h-10 rounded-full"
                                                    >
                                                        <ChartRelationshipIcon className="w-5 h-5" />
                                                    </Flex>
                                                </TabsTrigger>
                                                <TabsTrigger
                                                    value="features"
                                                    className="data-[state=active]:text-primary"
                                                >
                                                    <Flex
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        className="bg-white w-10 h-10 rounded-full"
                                                    >
                                                        <DrawingModeIcon className="w-5 h-5" />
                                                    </Flex>
                                                </TabsTrigger>
                                                <TabsTrigger value="eans" className="data-[state=active]:text-primary">
                                                    <Flex
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        className="bg-white w-10 h-10 rounded-full"
                                                    >
                                                        <BarCode02Icon className="w-5 h-5" />
                                                    </Flex>
                                                </TabsTrigger>
                                                <TabsTrigger
                                                    value="images"
                                                    className="data-[state=active]:text-primary"
                                                >
                                                    <Flex
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        className="bg-white w-10 h-10 rounded-full"
                                                    >
                                                        <Image02Icon className="w-5 h-5" />
                                                    </Flex>
                                                </TabsTrigger>
                                                <TabsTrigger value="pdf" className="data-[state=active]:text-primary">
                                                    <Flex
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        className="bg-white w-10 h-10 rounded-full"
                                                    >
                                                        <Pdf02Icon />
                                                    </Flex>
                                                </TabsTrigger>
                                                <TabsTrigger value="stock" className="data-[state=active]:text-primary">
                                                    <Flex
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        className="bg-white w-10 h-10 rounded-full"
                                                    >
                                                        <AppleStocksIcon />
                                                    </Flex>
                                                </TabsTrigger>
                                                <TabsTrigger
                                                    value="orders"
                                                    className="data-[state=active]:text-primary"
                                                >
                                                    <Flex
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        className="bg-white w-10 h-10 rounded-full"
                                                    >
                                                        <Invoice01Icon />
                                                    </Flex>
                                                </TabsTrigger>
                                                <TabsTrigger
                                                    value="activity"
                                                    className="data-[state=active]:text-primary"
                                                >
                                                    <Flex
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        className="bg-white w-10 h-10 rounded-full"
                                                    >
                                                        <WorkHistoryIcon />
                                                    </Flex>
                                                </TabsTrigger>
                                            </TabsList>
                                        </div>

                                        <Flex
                                            flexDirection="col"
                                            justifyContent="between"
                                            alignItems="start"
                                            className="h-full w-full bg-white"
                                        >
                                            <Flex
                                                className="h-20 px-6"
                                                justifyContent="center"
                                                flexDirection="col"
                                                alignItems="start"
                                            >
                                                <h3>
                                                    <span className="text-muted-foreground text-s">#{product?.id}</span>{" "}
                                                    - {product?.name}
                                                </h3>
                                                <p className="text-xs text-muted-foreground">
                                                    {product?.eans.map(eanObject => eanObject.ean).join(",")}
                                                </p>
                                            </Flex>

                                            <div className="grow h-full w-full overflow-scroll px-6">
                                                <TabsContent value="dashboard" className="w-full pb-8">
                                                    {product && productStockInformation && (
                                                        <ProductDashboard
                                                            product={product}
                                                            orders={orders}
                                                            stockInformation={productStockInformation}
                                                        />
                                                    )}
                                                </TabsContent>
                                                <TabsContent value="features" className="w-full">
                                                    <ScrollArea className="px-0.5">
                                                        {product && !isImportProductIcecatLoading && (
                                                            <Fragment>
                                                                <Button
                                                                    variant="outline"
                                                                    // disabled
                                                                    onClick={() =>
                                                                        handleImportProductIcecat(product.id)
                                                                    }
                                                                >
                                                                    Don't push in production Refetch Icecat data{" "}
                                                                    {product.external_icecat_id || "No external ID"}
                                                                </Button>
                                                                <ProductForm item={product} />

                                                                <ProductFeatureForm
                                                                    product={product}
                                                                    onSubmit={() => console.log("sfd")}
                                                                />
                                                            </Fragment>
                                                        )}
                                                    </ScrollArea>
                                                </TabsContent>
                                                <TabsContent value="eans" className="w-full">
                                                    <ScrollArea className="h-full gap-y-4 flex">
                                                        <div className="flex flex-col gap-y-8">
                                                            {product && <EanForm product={product} />}

                                                            {!!product?.children && !product.parent_id && (
                                                                <ChildProductForm product={product} />
                                                            )}
                                                        </div>
                                                    </ScrollArea>
                                                </TabsContent>
                                                <TabsContent value="images" className="w-full h-full">
                                                    <Flex
                                                        justifyContent="center"
                                                        alignItems="start"
                                                        className="h-full w-full"
                                                    >
                                                        {product && <ProductMediaForm product={product} />}
                                                    </Flex>
                                                </TabsContent>
                                                <TabsContent value="channels" className="w-full h-full">
                                                    {product && (
                                                        <ProductChannelTable
                                                            onClickCreateChannelProductAi={
                                                                handleCreateChannelProductAiText
                                                            }
                                                            onClickChannelProduct={handleChannelProductClick}
                                                            channels={channels}
                                                            product={product}
                                                        />
                                                    )}
                                                </TabsContent>
                                                <TabsContent value="stock" className="w-full h-full">
                                                    <ScrollArea>
                                                        {product && (
                                                            <WarehouseStockForm
                                                                product={product}
                                                                onSubmit={values =>
                                                                    handleWarehouseStockSubmit(
                                                                        values.productWarehouses || [],
                                                                        values.reason
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    </ScrollArea>
                                                </TabsContent>
                                                <TabsContent value="orders" className="w-full h-full">
                                                    {product && (
                                                        <ProductOrders
                                                            product={product}
                                                            onClickShowOrder={handleShowOrder}
                                                        />
                                                    )}
                                                </TabsContent>
                                                <TabsContent value="activity" className="w-full h-full">
                                                    <ActivityLog activities={product?.activities || []} />
                                                </TabsContent>
                                            </div>
                                            <SheetFooter
                                                className="justify-end flex w-full m-0 py-3 px-6 z-50 flex-none"
                                                style={{
                                                    boxShadow:
                                                        "0px -2px 4px 0px rgba(101, 101, 101, 0.01), 0px -4px 8px 0px rgba(101, 101, 101, 0.04)"
                                                }}
                                            >
                                                <Button onClick={handleClose} variant="outline">
                                                    Close
                                                </Button>
                                            </SheetFooter>
                                        </Flex>
                                    </Flex>
                                </Tabs>
                            )}
                        </div>
                    </TooltipProvider>
                </SheetContent>
            </Sheet>
            {product && (
                <ChannelProductDialogForm
                    channelProduct={channelProduct}
                    isLoading={isCreateChannelProductAiTextLoading || isLoadingRegenrateChannelProductAiText}
                    product={product}
                    open={showChannelProductDialogForm}
                    onRegenerate={handleRegenerateChannelProductAiText}
                    onClose={() => setShowChannelProductDialogForm(false)}
                    onSubmit={handleChannelProductUpdateSubmit}
                />
            )}
        </Fragment>
    );
};
