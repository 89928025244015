import { queryClient } from "@/app";
import { commonTypes } from "@/common";
import { QueryBuilder } from "@cgarciagarcia/react-query-builder";
import { useMutation, useQuery } from "react-query";
import { toast } from "sonner";
import QueryKeys from "./constans/QueryKeys";
import {
    createPurchaseOrder,
    deletePurchaseOrder,
    getDomainPurchaseOrders,
    getPurchaseOrder,
    setPurchaseOrderStatusCompleted,
    updatePurchaseOrder
} from "./queries";
import { IPurchaseOrder, IPurchaseOrderResponse } from "./types";

export const useGetDomainPurchaseOrders = (
    domainId: number,
    queryBuilder: QueryBuilder,
    options?: commonTypes.IUseGetOptions
) => {
    return useQuery<IPurchaseOrderResponse, Error>(
        [QueryKeys.PurhaseOrderItems, ...queryBuilder.toArray()],
        () => getDomainPurchaseOrders(domainId, queryBuilder),
        options
    );
};

export const useGetPurchaseOrder = (purchaseOrderId: number, options?: commonTypes.IUseGetOptions) => {
    return useQuery<IPurchaseOrder, Error>(
        [QueryKeys.PurhaseOrderItem, { id: purchaseOrderId }],
        () => getPurchaseOrder(purchaseOrderId),
        options
    );
};

export const useCreateDomainPurchaseOrder = (domainId: number) => {
    return useMutation((data: IPurchaseOrder) =>
        createPurchaseOrder(domainId, data).then(res => {
            queryClient.invalidateQueries(QueryKeys.PurhaseOrderItems);

            return res;
        })
    );
};

export const useUpdatePurchaseOrder = (purchaseOrderId: number) => {
    return useMutation((data: IPurchaseOrder) =>
        updatePurchaseOrder(purchaseOrderId, data).then(purchaseOrderId => {
            queryClient.invalidateQueries([QueryKeys.PurhaseOrderItem, { id: purchaseOrderId }]);
        })
    );
};

export const useSetPurchaseOrderStatusCompleted = (purchaseOrderId: number) => {
    return useMutation(() =>
        setPurchaseOrderStatusCompleted(purchaseOrderId)
            .then(() => {
                toast.success("Purchase order status updated to completed", {
                    className: "bg-green-500 text-white"
                });
                queryClient.invalidateQueries([QueryKeys.PurhaseOrderItem, { id: purchaseOrderId }]);
            })
            .catch(error => {
                toast.error(error.message, {
                    className: "bg-red-500 text-white"
                });
            })
    );
};

export const useDeletePurchaseOrder = () => {
    return useMutation((purchaseOrderId: number) =>
        deletePurchaseOrder(purchaseOrderId).then(() => {
            queryClient.invalidateQueries([QueryKeys.PurhaseOrderItem, { id: purchaseOrderId }]),
                queryClient.invalidateQueries([QueryKeys.PurhaseOrderItems, { id: purchaseOrderId }]);
        })
    );
};
