import { channelTypes } from "@/channel";
import { MoneyFormatter } from "@/common/utils/MoneyFormatter";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import Flex from "@/components/ui/flex";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { AiSearchIcon, InformationCircleIcon } from "@hugeicons/react";
import { TooltipArrow } from "@radix-ui/react-tooltip";
import { IChannelProduct, IProduct } from "../types";

interface IProductChannelTableProps {
    onClickCreateChannelProductAi: (channelId: number) => void;
    product: IProduct;
    channels: channelTypes.IChannel[];
    onClickChannelProduct: (channelProduct: IChannelProduct) => void;
}

export const ProductChannelTable = ({
    onClickCreateChannelProductAi,
    onClickChannelProduct,
    product,
    channels
}: IProductChannelTableProps) => {
    return (
        <Card className="mt-8">
            <CardHeader className="border-b p-4">
                <CardTitle>Channels</CardTitle>
            </CardHeader>
            <CardContent className="divide-y divide-dashed p-4">
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead className="w-[100px]">Channel name</TableHead>
                            <TableHead>
                                <Flex justifyContent="start" className="gap-x-2">
                                    AI
                                    <Tooltip delayDuration={0}>
                                        <TooltipTrigger>
                                            <InformationCircleIcon className="w-4" />
                                        </TooltipTrigger>
                                        <TooltipContent
                                            side="top"
                                            className="flex items-center gap-4 bg-white border shadow-sm"
                                        >
                                            <TooltipArrow className="fill-white" />
                                            <p className="text-muted-foreground w-56">
                                                SEO optimized product text is not generated yet. Click on the AI icon to
                                                generate it.
                                            </p>
                                        </TooltipContent>
                                    </Tooltip>
                                </Flex>
                            </TableHead>
                            <TableHead className="text-right">Sales price + margin</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {channels?.map((channel, index) => {
                            const channelProduct = product?.channelProducts.find(
                                cp => (cp.channel_id as any) === channel.id
                            );

                            return (
                                <TableRow>
                                    <TableCell className="font-medium">
                                        <div className="text-sm text-muted-foreground w-48">
                                            {channel.key} - {channel.company_name}
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div>
                                            {channelProduct ? (
                                                <Button
                                                    onClick={() => {
                                                        onClickChannelProduct(channelProduct);
                                                    }}
                                                    variant="outline"
                                                    className="border-primary"
                                                >
                                                    <AiSearchIcon className="w-4 text-primary" />
                                                </Button>
                                            ) : (
                                                <Button
                                                    onClick={() => {
                                                        onClickCreateChannelProductAi(channel.id);
                                                    }}
                                                    variant="outline"
                                                >
                                                    <AiSearchIcon className="w-4 text-muted-foreground" />
                                                </Button>
                                            )}
                                        </div>
                                    </TableCell>

                                    <TableCell className="text-right">
                                        <div className="text-muted-foreground">
                                            {MoneyFormatter(100000 + index * 9999)}{" "}
                                            <Badge variant="secondary" className="ml-4">
                                                +30
                                            </Badge>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
};
