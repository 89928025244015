import { queryClient } from "@/app";
import { commonTypes } from "@/common";
import { domainTypes } from "@/domain";
import { warehouseTypes } from "@/warehouse";
import { QueryBuilder } from "@cgarciagarcia/react-query-builder";
import { useMutation, useQuery } from "react-query";
import { toast } from "sonner";
import QueryKeys from "./constans/QueryKeys";
import {
    createChannelProductAiText,
    createEan,
    createProduct,
    deleteEan,
    getDownloadCsv,
    getProduct,
    getProducts,
    getProductStockInformation,
    getProductWarehouses,
    getTotalStockValue,
    importProductIcecat,
    regenerateChannelProductAiText,
    removeChildFromParent,
    resyncShopify,
    syncProductChannel,
    updateChannelProduct,
    updateParentProduct,
    updateProduct,
    updateProductWarehouses
} from "./queries";
import {
    IChannelProduct,
    IProduct,
    IProductResponse,
    IProductStockInformation,
    IProductWarehouse,
    ITotalStockValue
} from "./types";

export const useGetProducts = (
    domain: domainTypes.IDomain,
    queryBuilder: QueryBuilder,
    options?: commonTypes.IUseGetOptions
) =>
    useQuery<IProductResponse, Error>(
        ["product/items", { domainId: domain.id, ...queryBuilder.toArray() }],
        () => getProducts(domain, queryBuilder),
        options
    );

export const useGetProduct = (id: number, options?: commonTypes.IUseGetOptions) =>
    useQuery<IProduct, Error>([QueryKeys.ProductItem, id], () => getProduct(id), options);

export const useGetProductStockInformation = (productId: number, options?: commonTypes.IUseGetOptions) =>
    useQuery<IProductStockInformation, Error>(
        [QueryKeys.ProductStockInformation, productId],
        () => getProductStockInformation(productId),
        options
    );

export const useGetProductWarehouses = (productId: number, options?: commonTypes.IUseGetOptions) =>
    useQuery<warehouseTypes.IWarehouse[], Error>(
        ["product/item/warehouses", productId],
        () => getProductWarehouses(productId),
        options
    );

export const useCreateProduct = (domainId: number) =>
    useMutation((ean: string) => createProduct(domainId, ean), {
        onSuccess: (product: IProduct) => {
            queryClient.invalidateQueries([QueryKeys.ProductItem, product.id]);
        }
    });

export const useUpdateParentProduct = () =>
    useMutation((product: IProduct) => updateParentProduct(product), {
        onSuccess: (productId: number) => {
            queryClient.invalidateQueries([QueryKeys.ProductItem, productId]);
            toast.success("Product updated successfully", {
                className: "bg-green-500 text-white"
            });
        }
    });

export const useUpdateProduct = () =>
    useMutation({
        mutationFn: updateProduct,
        onSuccess: (productId: number) => {
            queryClient.invalidateQueries([QueryKeys.ProductItem, productId]);
            toast.success("Product updated successfully", {
                className: "bg-green-500 text-white"
            });
        },
        onError: (error: any) => {
            toast.error(error.message, {
                className: "bg-red-500 text-white"
            });
        }
    });

export const useCreateChannelProductAiText = (productId: number | null) =>
    useMutation((channelId: number) => createChannelProductAiText(channelId, productId), {
        onSuccess: (channelProduct: IChannelProduct) => {
            queryClient.invalidateQueries([QueryKeys.ProductItem, channelProduct.product_id]);
        }
    });

export const useUpdateChannelProduct = () =>
    useMutation(updateChannelProduct, {
        onSuccess: (productId: string) => {
            queryClient.invalidateQueries([QueryKeys.ProductItem, productId]);
        }
    });

export const useRegenerateChannelProductAiText = () =>
    useMutation(regenerateChannelProductAiText, {
        onSuccess: (channelProduct: IChannelProduct) => {
            queryClient.invalidateQueries([QueryKeys.ProductItem, channelProduct.product_id]);
        }
    });

export const useSyncProductChannel = () =>
    useMutation(syncProductChannel, {
        onSuccess: (product: IProduct) => {
            queryClient.invalidateQueries([QueryKeys.ProductItem, product.id]);
        }
    });

export const useUpdateProductWarehouses = (productId: number) =>
    useMutation(
        (values: { reason: string; productWarehouses: IProductWarehouse[] }) =>
            updateProductWarehouses(productId, values),
        {
            onSuccess: (productId: number) => {
                queryClient.invalidateQueries([QueryKeys.ProductItem, productId]);
            }
        }
    );

export const useImportProductIcecat = () =>
    useMutation((productId: number) => importProductIcecat(productId), {
        onSuccess: (productId: number) => {
            queryClient.invalidateQueries([QueryKeys.ProductItem, productId]);
        }
    });

export const useDeleteEan = (productId: number) =>
    useMutation((eanId: number) => deleteEan(productId, eanId), {
        onSuccess: (productId: number) => {
            queryClient.invalidateQueries([QueryKeys.ProductItem, productId]);
        }
    });

export const useCreateEan = (productId: number) =>
    useMutation((ean: string) => createEan(productId, ean), {
        onSuccess: (productId: number) => {
            queryClient.invalidateQueries([QueryKeys.ProductItem, productId]);
        }
    });

export const useGetTotalStockValue = (domain: domainTypes.IDomain, options?: commonTypes.IUseGetOptions) =>
    useQuery<ITotalStockValue, Error>(
        [QueryKeys.TotalStockValue, domain.id],
        () => getTotalStockValue(domain),
        options
    );

export const useGetDownloadCsv = (domainId: number) =>
    useMutation((queryBuilder: QueryBuilder) => getDownloadCsv(domainId, queryBuilder));

export const useResyncShopify = (domainId: number) =>
    useMutation(() => resyncShopify(domainId), {
        onSuccess: (domainId: number) => {
            queryClient.invalidateQueries([QueryKeys.ResyncShopify, domainId]);
            toast.success("Successfully started resyncing process for Shopify");
        },
        onError: (error: any) => {
            toast.error(error.message, {
                className: "bg-red-500 text-white"
            });
        }
    });

export const useRemoveChildFromParent = (ParentProductId: number) =>
    useMutation((childProductId: number) => removeChildFromParent(ParentProductId, childProductId), {
        onSuccess: (productId: number) => {
            queryClient.invalidateQueries([QueryKeys.ProductItem, productId]);
            toast.success("Successfully removed child product from parent product");
        }
    });
