import { utilsQuery } from "@/common";

const define = utilsQuery.defineQueryKeyCreator("orders");

export const QueryKeys = {
    OrderItems: define("orderItems"),
    OrderItem: define("orderItem"),
    OrderCount: define("orderCount"),
    OrderProductItems: define("orderProductItems"),
    FailedOrders: define("failedOrders"),
    OrderTransportStatus: define("orderTransportStatus")
} as const;
